import React from 'react'
import { Link } from 'react-router-dom'

function HeroSection() {
  return (
    <section className='featured-section'>
      <div className="container">
        <div className="featured-blog-wrapper">
          <div className="featured-blog-thumb">
            <img src="https://www.giosg.com/hubfs/Guides%202020/Listing%20pages/0221-guide_listing-how_to_build_chatbot.jpg" alt="blog thumbnail" />
          </div>
          <div className="featured-blog-content">
            <Link className='category-badge' to="/category">Chatbot</Link>
              <h2>
                <Link to="/blog/:slug">
                  10 Simple Steps to Create a Chatbot For Your Website
                </Link>
              </h2>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium, assumenda! Laudantium, sint. Magnam expedita eaque vitae repellendus nostrum dolorem temporibus assumenda, dicta necessitatibus minima facilis autem recusandae voluptatem ad asperiores.</p>
            <div className="featured-excerpt">
              <Link className='author-badge' to="/author">Suja Krishnan</Link>
              <span>Date: 29/12/2024</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSection