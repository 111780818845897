import { CheckOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import React, { useState } from 'react';
import PriceCompareSection from './PriceCompareSection';
import { Check, ChevronDown, ChevronUp, X, Zap } from 'lucide-react'


const PricingTier = ({ title, price, period, features, buttonText, isPopular = false, customPrice = false }) => (
  <div className={`bg-white p-6 rounded-lg shadow-md ${isPopular ? 'border-[2px] border-brand-color' : ''}`}>
    {isPopular && <div className="bg-brand-color text-white text-xs font-bold py-1 px-2 rounded-full absolute -top-3 left-1/2 transform -translate-x-1/2">Most popular</div>}
    <h3 className="text-xl font-bold mb-4">{title}</h3>
    <p className="text-gray-600 mb-4">For {period} Traffic Sites</p>
    <div className="text-3xl font-bold mb-4">
      {customPrice ? price : <>${price}<span className="text-lg font-normal">/{period}</span></>}
    </div>
    <button className={`w-full py-2 px-4 rounded-md mb-6 ${isPopular ? 'bg-bg-brand-color-bold text-white' : 'bg-gray-200 text-gray-700'}`}>
      {buttonText}
    </button>
    <ul className="space-y-2">
      {features.map((feature, index) => (
        <li key={index} className="flex items-start">
          <Check className="inline-block text-green-500 mr-2 mt-1 flex-shrink-0 bg-bg-color rounded p-1" />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
  </div>
);

export default function PricingSection() {
  const [isYearly, setIsYearly] = useState(false);

  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-base font-semibold text-brand-color tracking-wide uppercase">
          PRICING
          </h2>
          <h1 className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Pays for itself by increasing<br />your revenue
          </h1>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            Get started for free. We offer non-branded solutions for Enterprises and Agencies.
          </p>
        </div>

        <div className="mt-12 flex !justify-center items-center w-[100%] m-auto">
          <span className={`mr-3 ${isYearly ? 'text-gray-500' : 'font-bold'}`}>Monthly</span>
          <Switch checked={isYearly} onChange={setIsYearly} />
          <span className={`ml-3 ${isYearly ? 'font-bold' : 'text-gray-500'}`}>Yearly</span>
          {isYearly && <span className="ml-2 bg-green-100 text-green-800 text-xs font-semibold px-2 py-1 rounded-full">save 20%</span>}
        </div>

        <div className="mt-12 grid gap-8 lg:grid-cols-4">
          <PricingTier
            title="Free"
            price="0"
            period="month"
            features={[
              "Responses: 50/mo",
              "10 Training Materials",
              "1 user included",
              "1 AI Agent",
              "Model: Llama 3.1 40SB",
              "AI Nav & Widget"
            ]}
            buttonText="Try for free"
          />
          <PricingTier
            title="PRO"
            price={isYearly ? "79" : "99"}
            period="month"
            features={[
              "AI Responses: 6,000/mo",
              "500 Training Materials",
              "3 users included",
              "3 AI Agents",
              "Channel Integration (FB, IG, WhatsApp etc.)",
              "Basic CRM Integration",
              "AI Meeting Scheduling",
              "Models: GPT-4 Turbo, GPT-4o mini, GPT - 4o"
            ]}
            buttonText="Try for free"
          />
          <PricingTier
            title="Business"
            price={isYearly ? "319" : "399"}
            period="month"
            features={[
              "AI Responses: 40,000/mo",
              "750 Training Materials",
              "10 users included",
              "10 AI Agents",
              "Customer Success Manager",
              "Purchase Intent & Triggers (SMS or Email)",
              "Conversational Insights",
              "Salesforce & HubSpot Integration"
            ]}
            buttonText="Try for free"
            isPopular={true}
          />
          <PricingTier
            title="Enterprise"
            price="Custom"
            period="Enterprise and Agencies"
            features={[
              "Responses: Unlimited",
              "Unlimited Training Materials",
              "Unlimited users included",
              "AI Agents",
              "Fully customizable plan",
              "Enterprise onboarding - dedicated CSM, Engineer etc.",
              "Custom Integration",
              "SLA's",
              "API Integrations",
              "SSO",
              "Custom Data Retention Policy",
              "AI Native Webpages"
            ]}
            buttonText="Request a free demo"
            customPrice={true}
          />
        </div>
      </div>
      <PriceCompareSection />
    </div>
  );
}