import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { BsX } from 'react-icons/bs';
import { IoCopy } from 'react-icons/io5';
import { MdCheckCircle, MdContentCopy, MdOutlinePlayCircleOutline } from 'react-icons/md';
import 'react-loading-skeleton/dist/skeleton.css';
import IframeGuideVideoModel from './IframeGuideVideoModel';
import CopyModalTabs from './CopyModalTabs';

function BotCopyModel({uid, bid, link, disabled, link1, type, fbVarifyToken, fbCallbackUrl, customClass}) {
  const [tutModel, setTutModel] = useState(false);
  const [videoModel, setVideoModel] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copySuccess1, setCopySuccess1] = useState(false);
  const [copySuccess2, setCopySuccess2] = useState(false);
  const [copySuccess3, setCopySuccess3] = useState(false);
 
  const handleCopyToClipboard = () => {
    // toast.success('Chatbot link copied');
    toast.success('Chatbot link copied', {
      iconTheme: {
        primary: '#009688',
        secondary: '#ffffff',
      },
    });
    setCopySuccess(true);
 
    // You can set a timeout to reset the copySuccess state after a certain duration
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };
  const handleCopyToClipboard1 = () => {
    toast.success('Chatbot embed link copied', {
      iconTheme: {
        primary: '#009688',
        secondary: '#ffffff',
      },
    });
    setCopySuccess1(true);
 
    // You can set a timeout to reset the copySuccess state after a certain duration
    setTimeout(() => {
      setCopySuccess1(false);
    }, 3000);
  };
  const handleCopyToClipboard2 = () => {
    toast.success('Content copied', {
      iconTheme: {
        primary: '#009688',
        secondary: '#ffffff',
      },
    });
    setCopySuccess2(true);
 
    // You can set a timeout to reset the copySuccess state after a certain duration
    setTimeout(() => {
      setCopySuccess2(false);
    }, 3000);
  };
  const handleCopyToClipboard3 = () => {
    toast.success('Content copied', {
      iconTheme: {
        primary: '#009688',
        secondary: '#ffffff',
      },
    });
    setCopySuccess3(true);
 
    // You can set a timeout to reset the copySuccess state after a certain duration
    setTimeout(() => {
      setCopySuccess3(false);
    }, 3000);
  };

  const handleVideoModal = () => {
    setVideoModel(!videoModel)
  }

  console.log(link, disabled, link1, type, fbVarifyToken, fbCallbackUrl)

  return (
    <>
      {
        disabled ? (
          <div>
            <div className={`form-button all-bot-action-btn ${customClass}`}>
              <IoCopy />
              <span>Copy Link</span>
            </div>
          </div>
        ) : (
          <div onClick={() => setTutModel(!tutModel)}>
            <div className={`form-button all-bot-action-btn ${customClass}`}>
              <IoCopy />
              <span>Copy Link</span>
            </div>
          </div>
        )
      }
      

      <div className={tutModel ? 'tut-demo-model-container active' : 'tut-demo-model-container'}>
        <div className="demo-video-modal-wrapper code-modal-wrapper whatsapp-bot">
          <BsX className="cancel" onClick={() => setTutModel(!tutModel)} />

          <div className="code-container">
            <div className="code-content">
              <h2>Copy Embedded Codes</h2>
              <p>To integrate the chatbot into your website, add the following iframe code to the Header or Body Tag of your website:</p>
            </div>

            {/* callback url */}
            {
              type === 'whatsapp-bot' &&  <div className="copy-link-wrap">
              <label htmlFor="">Callback URL link</label>
              
              <div className="form-group copy-link-group">
                <input type="text" value={link1} />
                <CopyToClipboard
                      onCopy={handleCopyToClipboard}
                      text={link1}
                    >
                      <button className={`form-button copy-site-link-btn ${copySuccess ? 'success' : ''}`}>
                        {copySuccess ? <MdCheckCircle /> : <MdContentCopy />}
                        <span>{copySuccess ? 'Copied!' : 'Copy Link'}</span>
                      </button>
                    </CopyToClipboard>
                  </div>
            </div>
            }
             
              {/* Test bot link */}
              {
                 type !== 'whatsapp-bot' && <div className="copy-link-wrap">
                 <label htmlFor="">Test bot link</label>
                 
                 <div className="form-group copy-link-group">
                   <input type="text" value={`https://dev.aceva.com.my/botIframe/${uid}/${bid}`} />
                   <CopyToClipboard
                         onCopy={handleCopyToClipboard}
                         text={`https://dev.aceva.com.my/botIframe/${uid}/${bid}`}
                       >
                         <button className={`form-button copy-site-link-btn ${copySuccess ? 'success' : ''}`}>
                           {copySuccess ? <MdCheckCircle /> : <MdContentCopy />}
                           <span>{copySuccess ? 'Copied!' : 'Test Link'}</span>
                         </button>
                       </CopyToClipboard>
                     </div>
               </div>
              }

              {/* tabs */}
              {
                 type !== 'whatsapp-bot' && <CopyModalTabs 
                   type={type}
                   link={link}
                   copySuccess1={copySuccess1}
                   handleVideoModal={handleVideoModal}
                   handleCopyToClipboard1={handleCopyToClipboard1}
                   fbVarifyToken={fbVarifyToken}
                   fbCallbackUrl={fbCallbackUrl}
                   handleCopyToClipboard2={handleCopyToClipboard2}
                   copySuccess2={copySuccess2}
                   handleCopyToClipboard3={handleCopyToClipboard3}
                   copySuccess3={copySuccess3}
                   uid={uid} 
                   bid={bid}
                 />
              }
       
          </div>
        </div>
      </div>

      {/* Video guide modal */}
      <IframeGuideVideoModel videoModel={videoModel} setVideoModel={setVideoModel} />
    </>
  );
}

export default BotCopyModel;
