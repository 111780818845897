import { Modal } from 'antd';
import React, { useState } from 'react';
import { MdOutlinePlayCircle } from "react-icons/md";
import messenger from '../images/support/messenger.png';
import whatsapp from '../images/support/whatsapp.png';
import website from '../images/support/website.png';
import calendar from '../images/support/calendar.png';

import Layout from '../components/Layout';

// Video data
const videos = [
  {
    id: '1',
    title: 'How to create WhatsApp Chatbot',
    embedUrl: 'https://www.youtube.com/embed/rSAtsu2D_rs?si=xVbyhD9azg4GQ2bh',
    img: whatsapp,
  },
  {
    id: '2',
    title: 'How to do Messenger Integration',
    embedUrl: 'https://www.youtube.com/embed/zIKR2J1AZC4?si=iqaO2GpKTeY3LJpj',
    img: messenger,
  },
  {
    id: '3',
    title: 'How to do Google Calendar Integration',
    embedUrl: 'https://www.youtube.com/embed/oCkv7AxcjaU?si=G0mems1IscPk0JAo',
    img: calendar,
  },
  {
    id: '4',
    title: 'How to create a Website Chatbot',
    embedUrl: 'https://www.youtube.com/embed/6T14pRBNhLA?si=eUNZDWyKaNDq3jQL',
    img: website,
  },
];

export default function HelpCenterScreen() {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const showVideoModal = (video) => {
    setSelectedVideo(video);
  };

  const handleCloseModal = () => {
    setSelectedVideo(null);
  };

  return (
    <Layout>
      <main className="py-12">
        <h1 className="text-3xl font-bold mb-6">Help Center</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {videos.map((video) => (
            <div onClick={() => showVideoModal(video)} key={video.id} className="bg-white rounded-lg shadow overflow-hidden cursor-pointer">
              <div 
                className="h-48 flex items-center justify-center bg-gray-100 relative">
                <MdOutlinePlayCircle className="w-12 h-12 absolute text-gray-400 hover:text-brand-color" />
              <img className='h-48 object-cover' src={video.img} alt="adfsdf" />
              </div>
              <div className="p-4">
                <h3 className="text-lg font-semibold">{video.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </main>
      <Modal
        title={selectedVideo?.title}
        open={!!selectedVideo}
        onCancel={handleCloseModal}
        footer={null}
        width="90%"
        style={{ maxWidth: '800px', maxHeight: '80vh' }}
        className=''
      >
        {selectedVideo && (
          <div className="relative pb-[50.25%] h-full">
            <iframe
              src={selectedVideo.embedUrl}
              title={selectedVideo.title}
              className="absolute top-0 left-0 w-full h-full border-0 rounded"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        )}
      </Modal>
    </Layout>
  );
}