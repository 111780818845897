import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast, { Toaster } from 'react-hot-toast';
import { BiSolidPencil } from 'react-icons/bi';
import { FiPlus } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Skeleton from 'react-loading-skeleton'; // Import the Skeleton component
import 'react-loading-skeleton/dist/skeleton.css';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { apiUrl } from '../Variables';
import Layout from '../components/Layout';
import BotupgrateAlart from '../components/alart/BotupgrateAlart';
import BotCopyModel from '../components/bot-screen/BotCopyModel';
import BotGuideCarousel from '../components/bot-screen/BotGuideCarousel';
import logo from '../images/error-img.png';
import whatsapp from '../images/icons/whatsapp.png';

function ListOfBotScreen({toggleLoggedInState}) {

  const [createUser, setCreateUser] = useState(false);
  const [tutModel, setTutModel] = useState(false); 
  const [editUser, setEditUser] = useState(false)
  const [exportModel, setExportModel] = useState(false)
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [chatbots, setChatbots] = useState([])
  const [result ,setResult] = useState(false)
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const [editUserInfo, setEditUserInfo] = useState({})
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [filterRoleValue, setFilterRoleValue] = useState('all')
  const [singleData, setSingleData] = useState({})
  const [botUpgrade, setBotUpgrade] = useState(false);
  const botUpgradeToggle = () => {
    setBotUpgrade(false)
  }
  const [copy, setCopy] = useState(false)
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')))

  const navigate = useNavigate();

  useEffect(() => {
    if(deleteSuccess) {
      navigate('/chatbot-list')
    }

    if (!userInfo || !userInfo.token) {
      console.error('User token not found.');
      return;
    }

    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    const featchData = async () => {
      try {
        setLoading(true)
        const {data} = await axios.get(`${apiUrl}/chatbot`, {headers})
        setChatbots(data)
        // localStorage.setItem('selectedLinks', JSON.stringify(data?.map((item) => item?._id)));
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log('scrap list fetching fail:', error)
        toast.error(`Error: ${error?.message || error?.response?.data}`)
      }
    }
    featchData()
    
  },[deleteSuccess, navigate])

  // chatbot delete handler 
  const chatbotDeleteHandler = async (id) => {

    // Show the confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this item!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {

        if (!userInfo || !userInfo.token) {
          console.error('User token not found.');
          return;
        }

        // Add headers to include JWT token
        const headers = {
          Authorization: `Bearer ${userInfo.token}`,
        };
        
        setDeleteSuccess(false)
        const deleteRes = await axios.delete(`${apiUrl}/chatbot/${id}`, {headers})
        toast.success('Chatbot deleted.', {
          iconTheme: {
            primary: '#009688',
            secondary: '#ffffff',
          },
        });
        // setDeleteSuccess(true)
        setChatbots(chatbots.filter(item => item._id !== id))
        console.log('delete response:', deleteRes)
      } catch (error) {
        setDeleteSuccess(false)
        console.log('chatbot delete error:', error)
      }
    }
  }

  console.log('chatbots', chatbots)
  console.log('copy', copy)

  const retrain = async (itm) => {
    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    setLoading(true)

    await axios.get(`${apiUrl}/scrap/scrappedList`, {headers}).then(async res => {
      const dataArr = []
      for (const item of res.data) {
        if (itm?.scrappedOrUpDataId.includes(item._id) && item.sourceURL) {
          dataArr.push(item.sourceURL)
        }
      }
      await axios.post(`${apiUrl}/scrap/scrapPages`, {page_urls: dataArr}, { headers }).then(res => {
        console.log(res)
        setLoading(false)
        // toast.success('Retraining completed');
        toast.success('Retraining completed', {
          iconTheme: {
            primary: '#009688',
            secondary: '#ffffff',
          },
        });
      }).catch((error) => {
        setLoading(false)
        console.error('Error:', error);
        toast.error(`Failed: ${error?.response?.data?.message}`);
      })
    }).catch ((error) =>  {
      setLoading(false)
      console.log('scrap list fetching fail:', error)
      // toast.error(`Error: ${error?.message || error?.response?.data}`)
      toast.error(`Retraining failed due to server error. Try again later.`)
    })
  }


  return (
    <>
      <Helmet>
        <title>Chatbots | ACEVA Dashboard</title>
      </Helmet>
      <Layout toggleLoggedInState={toggleLoggedInState}>
      <Toaster />
        <div className="list-of-the-bot-wrapper">

          {/* tut modal */}
          <BotGuideCarousel tutModel={tutModel} setTutModel={setTutModel} />


          {/* table header */}
          <div className="table-header">
            <div className="table-title">
              <h3>All Chatbot(s)</h3>

              {/* tut action */}
              <button onClick={() => setTutModel(!tutModel)} className="demo-video-tut"> 
              {/* <BsFillPlayCircleFill className="demo-video-tut-icon" />  */}
              How to create bot?
              </button>
            </div>

            {
            loading ? (
              Array.from({ length: 1 }).map((_, index) => (
                <div key={index} className="dashboard-header-right" style={{marginTop: '-7px'}}>
                  <Skeleton width={185} height={42} />
                </div>
              ))
            ) : (
              <div className={chatbots?.length >= parseInt(userInfo?.meta_data?.planData?.meta_data?.bot) ? "download-btn-wrap disabled" : "download-btn-wrap"} onClick={() => {
                if (chatbots?.length >= parseInt(userInfo?.meta_data?.planData?.meta_data?.bot)) {
                  // setBotUpgrade(true)
                  toast.error("You are currently limited to only 1 bot. Upgrade your plan now for more bots!")
                } else {
                   // Cleaning up local storage after successful updates
                  localStorage.removeItem("botConfig");
                  localStorage.removeItem("botInfo");
                  localStorage.removeItem("menuList");
                  localStorage.removeItem("selectedLinks");
                  navigate('/setup-business-goal')
                }
              }}>
                <FiPlus />
                {/* <BsAndroid2 /> */}
                <span>Create chatbot</span>
                {/* <FiDownload className="dwn-btn" /> */}
              </div>
            )
            }

          </div>

          {botUpgrade && <BotupgrateAlart toggle={botUpgradeToggle}/>}

          {/* list of the bots */}
          <div className="list-of-the-bot-wrap">

            {/* item disabled */}


            {
              loading ? (
                  // Show loading skeleton while data is being fetched
                  Array.from({ length: 5 }).map((_, index) => (
                  <div className="list-of-the-bot-item" key={index}>
                    <div className="list-bot-profile">
                      <Skeleton width={40} height={40} />
                      <div className="list-bot-content">
                        <Skeleton height={20} width={200} />
                        <Skeleton width={150} />
                      </div>
                    </div>
                    <div className="list-bot-option-wrap">
                      <div className="list-span-flex">
                        <Skeleton width={20} height={20} />
                        <Skeleton width={80} />
                      </div>
                      <div className="list-span-flex">
                        <Skeleton width={20} height={20} />
                        <Skeleton width={120} />
                      </div>
                      <div className="list-span-flex bot-delete-icon">
                        <Skeleton width={20} height={20} />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                chatbots?.length > 0 ? (
                  chatbots?.map((item, index) => (
                    (item?.configData?.type === 'whatsapp-bot' && !item?.configData?.whatsappConnected) ? <div className="list-of-the-bot-item disabled-bot">
              <div className="list-bot-profile">
                <div className="all-bot-list">
                  {
                      item?.configData?.logo ? (
                      <div className="list-bot-logo">
                        <img src={item?.configData?.logo} alt="bot icon" />
                      </div>
                      ) : (
                      <div className="list-bot-logo no-bot">
                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
                      </div>
                      )
                    }
                </div>
                <div className="list-bot-content">
                  <Link to="#">
                    <h3>{item?.name}</h3>
                    <div className="bot-type-img-wrap">
                      {
                        item?.configData?.type === 'whatsapp-bot' && <img className="bot-type-img" src={whatsapp} alt="bot type" />
                      }
                      {/* <img className="bot-type-img" src={messenger} alt="bot type" /> */}
                    </div>
                  </Link>
                </div>
              </div>

              <div className="list-bot-option-wrap">

                <div className='bot-copy-wrap'>
                  <BotCopyModel uid={item?.userId} bid={item?._id} link={item?.embeddedLink} link1={item?.whatsappCallbackLink} type={item?.configData?.type} fbVarifyToken={item?.configData?.fbVarifyToken} fbCallbackUrl={item?.configData?.fbCallbackUrl}/>
                </div>

                <Link to="#">
                  <div className="form-button all-bot-action-btn button-outline">
                    <BiSolidPencil />
                    <span>View and Edit</span>
                  </div>
                </Link>
                
                <div className='bot-action-wrap'>
                  
                  <div className="list-span-flex" title='Retrain'>
                      <span className='retrain-badge'>Retrain</span>
                  </div>
                  <div onClick={() => chatbotDeleteHandler(item?._id)} className="list-span-flex bot-delete-icon">
                    <RiDeleteBin6Line />
                  </div>
                </div>
              </div>
            </div> : <div className="list-of-the-bot-item" key={index}>
                      {/* profile */}
                      <div className="list-bot-profile">
                        <div className="all-bot-list">
                          {
                            item?.configData?.logo ? (
                            <div className="list-bot-logo">
                              <img src={item?.configData?.logo} alt="bot icon" />
                            </div>
                            ) : (
                              <div className="list-bot-logo no-bot">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
                            </div>
                            )
                          }
                        </div>
                        {/* <div className="list-bot-logo">
                          <FaRobot />
                        </div> */}
                        <div className="list-bot-content">
                          <Link to={`/file-list?ids=${item?.scrappedOrUpDataId?.toString()}&botId=${item._id}`}>
                            <h3>{item?.name}</h3>
                            <div className="bot-type-img-wrap">
                              {
                                item?.configData?.type === 'whatsapp-bot' && <img className="bot-type-img" src={whatsapp} alt="bot type" />
                              }
                              {/* <img className="bot-type-img" src={messenger} alt="bot type" /> */}
                            </div>
                          </Link>
                          {/* {
                            index === 0 ? (
                              <span className='currently-selected-badge'>Currently selected</span>
                            ) : ""
                          } */}
                        </div>
                      </div>
  
                      {/* right options */}
                      <div className="list-bot-option-wrap">

                        {/* iframe copy popup model */}
                        <div className='bot-copy-wrap'>
                          <BotCopyModel uid={item?.userId} bid={item?._id} link={item?.embeddedLink} link1={item?.whatsappCallbackLink} type={item?.configData?.type} fbVarifyToken={item?.configData?.fbVarifyToken} fbCallbackUrl={item?.configData?.fbCallbackUrl}/>
                        </div>

                        <Link to={`/edit-chatbot/${item?._id}`}>
                          <div className="form-button all-bot-action-btn button-outline">
                            <BiSolidPencil />
                            <span>View and Edit</span>
                          </div>
                        </Link>
                        
                        <div className='bot-action-wrap'>
                          
                          <div className="list-span-flex" title='Retrain'>
                              {/* <BiRefresh className='edit-icon' onClick={() => retrain(item)}/> */}
                              <span className='retrain-badge' onClick={() => retrain(item)}>Retrain</span>
                          </div>
                          {/* <Link to={`/demo?botId=${item?._id}`}>
                            <div className="list-span-flex">
                              <AiOutlineEye />
                            </div>
                          </Link> */}
                          <div onClick={() => chatbotDeleteHandler(item?._id)} className="list-span-flex bot-delete-icon">
                            <RiDeleteBin6Line />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="not-found-image">
                    <img className='error-img' src={logo} alt="error image" />
                    <span>Beep.. beep.. no data found.</span>
                  </div>
                )
              )
            }
          </div>
          
        </div>
      </Layout>
    </>
  )
}

export default ListOfBotScreen