import React, { useEffect, useState } from 'react';
import { BsChevronLeft, BsChevronRight, BsFillFileEarmarkSpreadsheetFill } from 'react-icons/bs';
import { CgSpinner } from 'react-icons/cg';
import { FaChevronRight, FaUserFriends } from 'react-icons/fa';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdAddLink, MdBarChart, MdOutlineShowChart } from 'react-icons/md';
import { RxTimer } from "react-icons/rx";
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import LineChartComponent from '../../components/charts/LineChartComponent';
import LineChartComponent1 from '../../components/charts/LineChartComponent1';
import LineChartComponent2 from '../../components/charts/LineChartComponent2';
import logo from '../../images/error-img.png';
import SelectRange from './SelectRange';

function KPIChartSection({loading, kpis, filterDate, setFilterDate, exportToExcel, selectedBot, billingData}) {
  const [sIndex, setIndex] = useState(1)
  const [usersBarData, setUsersBarData] = useState(null)
  useEffect(() => {
   if (kpis.exportData) {
    const uniqueDate = []
    const finalArr = []
    for (const item of kpis.exportData) {
      if (!uniqueDate.includes(item.Date)) {
        uniqueDate.push(item.Date)
      }
    }
    for (const item1 of uniqueDate) {
      const filtered = kpis.exportData.filter(item => item.Date === item1)
      finalArr.push({date: item1, count: filtered.length || 0})
    }
    setUsersBarData(finalArr)
   }
  }, [kpis])
  const navigate = useNavigate()

  const restChatVolume = billingData?.subscriptionBilling[0]?.restChatVolume;

  const formatNumberWithCommas = (number) => {
    if (number !== null && number !== undefined) {
      return number.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    }
    return number;
  };

  return (
    <>
      <div>
        {/* kpi's */}
        <div className="chart-items">
          {/* statics wrapper */}
          <div className="dashboard-statics-wrapper">
            <Slider
              prevArrow={<BsChevronLeft className="slick-prev" />}
              nextArrow={<BsChevronRight className="slick-next" />}
              slidesToShow={4}
              slidesToScroll={1} 
              className="slick-slider-with-gap" 
              responsive= {[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
                }
              ]}
            >
              <div className="dashboard-kpi-item da-kpi-one" onClick={() => setIndex(1)}>
                <div className="thumb-and-content-kpi">
                  <div className="kpi-item-icon">
                  <IoMdCheckmarkCircleOutline />
                  </div>
                  <div className="kpi-item-header">
                    <h4>Total Conversation</h4>
                    <div className="kpi-item-body">
                      <h2>
                        {loading ? (
                          <CgSpinner />
                        ) : kpis.total_chats || '0'}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard-kpi-item da-kpi-one" onClick={() => setIndex(2)}>
                <div className="thumb-and-content-kpi">
                  <div className="kpi-item-icon">
                  <MdBarChart />
                  </div>
                  <div className="kpi-item-header">
                    <h4>Average session</h4>
                    <div className="kpi-item-body">
                      <h2>
                        {loading ? (
                          <CgSpinner />
                        ) : kpis.avg_session &&
                          kpis.avg_session !== 'NaN' ? (
                          `${kpis.avg_session} m`
                        ) : (
                          '0'
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard-kpi-item da-kpi-one" onClick={() => setIndex(3)}>
                <div className="thumb-and-content-kpi">
                  <div className="kpi-item-icon">
                  <RxTimer />
                  </div>
                  <div className="kpi-item-header">
                    <h4>Avg Response Time </h4>
                    <div className="kpi-item-body">
                      <h2>
                        {loading ? (
                          <CgSpinner />
                        ) : kpis.avg_res_time &&
                          kpis.avg_res_time !== 'NaN' ? (
                          `${kpis.avg_res_time} s`
                        ) : (
                          '0'
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>    
              <div className="dashboard-kpi-item da-kpi-one" onClick={() => setIndex(4)}>
                <div className="thumb-and-content-kpi">
                  <div className="kpi-item-icon">
                    <FaUserFriends />
                  </div>
                  <div className="kpi-item-header">
                    <h4>No. Of Users</h4>
                    <div className="kpi-item-body">
                      <h2>
                        {loading ? (
                          <CgSpinner />
                        ) : kpis.exportData?.length &&
                          kpis.exportData.length !== 'NaN' ? (
                          `${kpis.exportData?.length}`
                        ) : (
                          '0'
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard-kpi-item-not-selectable da-kpi-one">
                <div className="thumb-and-content-kpi">
                  <div className="kpi-item-icon">
                    <MdOutlineShowChart />
                  </div>
                  <div className="kpi-item-header">
                    <h4>Engagement Rate</h4>
                    <div className="kpi-item-body">
                      <h2>
                        {loading ? (
                          <CgSpinner />
                        ) : kpis.engagement_rate &&
                          kpis.engagement_rate !== 'NaN' ? (
                          `${kpis.engagement_rate} %`
                        ) : (
                          '0'
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard-kpi-item da-kpi-one" onClick={() => navigate('/file-list')}>
                <div className="thumb-and-content-kpi">
                  <div className="kpi-item-icon">
                    <MdAddLink />
                  </div>
                  <div className="kpi-item-header">
                    <h4>Total Links</h4>
                    <div className="kpi-item-body">
                      <h2>
                        {loading ? (
                          <CgSpinner />
                        ) : selectedBot?.value?.scrappedOrUpDataId ? (
                          `${selectedBot?.value?.scrappedOrUpDataId?.length}`
                        ) : (
                          '0'
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard-kpi-item da-kpi-one" onClick={() => navigate('/billing')} >
                <div className="thumb-and-content-kpi">
                  <div className="kpi-item-icon">
                    <MdAddLink />
                  </div>
                  <div className="kpi-item-header">
                    <h4>Msg Remaining</h4>
                    <div className="kpi-item-body">
                      <h2>
                        {loading ? (
                          <CgSpinner />
                        ) : restChatVolume ? (
                          formatNumberWithCommas(restChatVolume)
                        ) : (
                          '0'
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>

        {/* No. of users */}
        {
          sIndex === 3 &&   <div className="chart-item">
          <div>
            {/* total conversation */}
            <div className="dashboard-kpi-item new-kpi-item">
              <div className="kpi-item-wrapper chart-header-wrapper">
                {/* Kpi details */}
                <div className="thumb-and-content-kpi details-thumb-and-content">
                  <div className="kpi-item-icon">
                    <FaUserFriends />
                  </div>
                  <div className="kpi-item-header">
                    <h4>Average Response Time (s)</h4>
                    <div className="kpi-item-body">
                      <h2>
                          {loading ? (
                            <CgSpinner />
                          ) : kpis.avg_res_time &&
                            kpis.avg_res_time !== 'NaN' ? (
                            `${kpis.avg_res_time} s`
                          ) : (
                            '0'
                          )}
                        </h2>
                    </div>
                  </div>
                </div>
                {/* right side selectbox and export button */}
                <div className="kpi-right-item-wrap">
                  <div className="dashboard-dropdown">
                    <SelectRange
                      filterDate={filterDate}
                      setFilterDate={setFilterDate}
                    />
                    </div>
                    {
                    filterDate?.startDate && (
                      <p className='custom-date-filter-result'>
                        Start Date: {filterDate.startDate} - {filterDate.endDate ? `End Date: ${filterDate.endDate}` : ''}
                      </p>
                    )
                    }
                    <div
                      className="form-button export-xlsx-btn"
                      onClick={() => {
                        exportToExcel();
                      }}
                    >
                      <BsFillFileEarmarkSpreadsheetFill style={{ color: 'var(--brand-color)' }} />
                      <p>Export as XLSX</p>
                    </div>
                  </div>
                  
              </div>
            </div>

            {kpis.responseTimeVsTime?.length > 0 ? (
              <>
                <LineChartComponent2 data={kpis.responseTimeVsTime} />
                <div className="see-all">
                  <Link to="/chatlogs">
                    <span>View all chats</span>
                    <FaChevronRight />
                  </Link>
                </div>
              </>
            ) : (
              <div className="not-found-image">
                <img className="error-img" src={logo} alt="error" />
                <span>Beep.. beep.. no data found.</span>
              </div>
            )}
          </div>

          <div className="see-all">
            <Link to="/chatlogs">
              <span>View all chats</span>
              <FaChevronRight />
            </Link>
          </div>
        </div>
        }
        {/* Engagement rate */}
        {
          sIndex === 2 && <div className="chart-item">
          <div>
            {/* total conversation */}
            <div className="dashboard-kpi-item new-kpi-item">
              <div className="kpi-item-wrapper chart-header-wrapper">
                {/* Kpi details */}
                <div className="thumb-and-content-kpi details-thumb-and-content">
                  <div className="kpi-item-icon">
                  <MdOutlineShowChart />
                  </div>
                  <div className="kpi-item-header">
                    <h4>Average Session Time (m)</h4>
                    <div className="kpi-item-body">
                      <h2>
                          {loading ? (
                            <CgSpinner />
                          ) : kpis.avg_session &&
                            kpis.avg_session !== 'NaN' ? (
                            `${kpis.avg_session} m`
                          ) : (
                            '0'
                          )}
                        </h2>
                    </div>
                  </div>
                </div>
                {/* right side selectbox and export button */}
                <div className="kpi-right-item-wrap">
                  <div className="dashboard-dropdown">
                    <SelectRange
                      filterDate={filterDate}
                      setFilterDate={setFilterDate}
                    />
                    </div>
                    {
                    filterDate?.startDate && (
                      <p className='custom-date-filter-result'>
                        Start Date: {filterDate.startDate} - {filterDate.endDate ? `End Date: ${filterDate.endDate}` : ''}
                      </p>
                    )
                    }
                    <div
                      className="form-button export-xlsx-btn"
                      onClick={() => {
                        exportToExcel();
                      }}
                    >
                      <BsFillFileEarmarkSpreadsheetFill style={{ color: 'var(--brand-color)' }} />
                      <p>Export as XLSX</p>
                    </div>
                  </div>
                  
              </div>
            </div>

            {kpis.sessionVsTime?.length > 0 ? (
              <>
                <LineChartComponent1 data={kpis.sessionVsTime} />
                <div className="see-all">
                  <Link to="/chatlogs">
                    <span>View all chats</span>
                    <FaChevronRight />
                  </Link>
                </div>
              </>
            ) : (
              <div className="not-found-image">
                <img className="error-img" src={logo} alt="error" />
                <span>Beep.. beep.. no data found.</span>
              </div>
            )}
          </div>

          <div className="see-all">
            <Link to="/chatlogs">
              <span>View all chats</span>
              <FaChevronRight />
            </Link>
          </div>
        </div>
        }
        {/* Credit Remaining */}
        {
          sIndex === 1 && <div className="chart-item">
            <div>
              {/* total conversation */}
              <div className="dashboard-kpi-item new-kpi-item">
                <div className="kpi-item-wrapper chart-header-wrapper">
                  {/* Kpi details */}
                  <div className="thumb-and-content-kpi details-thumb-and-content">
                    <div className="kpi-item-icon">
                    <IoMdCheckmarkCircleOutline />
                    </div>
                    <div className="kpi-item-header">
                      <h4>Total Conversation</h4>
                      <div className="kpi-item-body">
                        <h2>
                          {loading ? (
                            <CgSpinner />
                          ) : (
                            kpis.total_chats || '0'
                          )}
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* right side selectbox and export button */}
                  <div className="kpi-right-item-wrap">
                    <div className="dashboard-dropdown">
                      <SelectRange
                        filterDate={filterDate}
                        setFilterDate={setFilterDate}
                      />
                      </div>
                      {
                      filterDate?.startDate && (
                        <p className='custom-date-filter-result'>
                          Start Date: {filterDate.startDate} - {filterDate.endDate ? `End Date: ${filterDate.endDate}` : ''}
                        </p>
                      )
                      }
                      <div
                        className="form-button export-xlsx-btn"
                        onClick={() => {
                          exportToExcel();
                        }}
                      >
                        <BsFillFileEarmarkSpreadsheetFill style={{ color: 'var(--brand-color)' }} />
                        <p>Export as XLSX</p>
                      </div>
                    </div>
                    
                </div>
              </div>

              {kpis.chatVsTime?.length > 0 ? (
                <>
                  <LineChartComponent data={kpis.chatVsTime} />
                  <div className="see-all">
                    <Link to="/chatlogs">
                      <span>View all chats</span>
                      <FaChevronRight />
                    </Link>
                  </div>
                </>
              ) : (
                <div className="not-found-image">
                  <img className="error-img" src={logo} alt="error" />
                  <span>Beep.. beep.. no data found.</span>
                </div>
              )}
            </div>

            <div className="see-all">
              <Link to="/chatlogs">
                <span>View all chats</span>
                <FaChevronRight />
              </Link>
            </div>
          </div>
        }
        {
          sIndex === 4 && <div className="chart-item">
            <div>
              {/* total conversation */}
              <div className="dashboard-kpi-item new-kpi-item">
                <div className="kpi-item-wrapper chart-header-wrapper">
                  {/* Kpi details */}
                  <div className="thumb-and-content-kpi details-thumb-and-content">
                    <div className="kpi-item-icon">
                      <FaUserFriends />
                    </div>
                    <div className="kpi-item-header">
                    <h4>No. Of Users</h4>
                    <div className="kpi-item-body">
                      <h2>
                        {loading ? (
                          <CgSpinner />
                        ) : kpis.exportData?.length &&
                          kpis.exportData.length !== 'NaN' ? (
                          `${kpis.exportData?.length}`
                        ) : (
                          '0'
                        )}
                      </h2>
                    </div>
                    </div>
                  </div>
                  {/* right side selectbox and export button */}
                  <div className="kpi-right-item-wrap">
                    <div className="dashboard-dropdown">
                      <SelectRange
                        filterDate={filterDate}
                        setFilterDate={setFilterDate}
                      />
                      </div>
                      {
                      filterDate?.startDate && (
                        <p className='custom-date-filter-result'>
                          Start Date: {filterDate.startDate} - {filterDate.endDate ? `End Date: ${filterDate.endDate}` : ''}
                        </p>
                      )
                      }
                      <div
                        className="form-button export-xlsx-btn"
                        onClick={() => {
                          exportToExcel();
                        }}
                      >
                        <BsFillFileEarmarkSpreadsheetFill style={{ color: 'var(--brand-color)' }} />
                        <p>Export as XLSX</p>
                      </div>
                    </div>
                    
                </div>
              </div>

              {kpis.exportData?.length > 0 ? (
                <>
                  <LineChartComponent data={usersBarData} />
                  <div className="see-all">
                    <Link to="/chatlogs">
                      <span>View all chats</span>
                      <FaChevronRight />
                    </Link>
                  </div>
                </>
              ) : (
                <div className="not-found-image">
                  <img className="error-img" src={logo} alt="error" />
                  <span>Beep.. beep.. no data found.</span>
                </div>
              )}
            </div>

            <div className="see-all">
              <Link to="/chatlogs">
                <span>View all chats</span>
                <FaChevronRight />
              </Link>
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default KPIChartSection