import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import HeaderMenu from '../components/landing-page/HeaderMenu'

import ChatbotCreateSteps from '../components/landing-page/ChatbotCreateSteps'
import CTASection from '../components/landing-page/CTASection'
import FAQSection from '../components/landing-page/FAQSection'
import FooterV2 from '../components/landing-page/FooterV2'
import HeroSectionV3 from '../components/landing-page/HeroSectionV3'
import IntegrateSectionV2 from '../components/landing-page/IntegrateSectionV2'
import Landing from '../components/landing-page/Landing'
import LeftSideFlexSection from '../components/landing-page/LeftSideFlexSection'
import LeftSideFlexSectionV2 from '../components/landing-page/LeftSideFlexSectionV2'
import MarqueeSection from '../components/landing-page/MarqueeSection'
import NewHeroSection from '../components/landing-page/NewHeroSection'
import PerformanceStats from '../components/landing-page/PerformanceStats'
import PricingSection from '../components/landing-page/PricingSection'
import StatSection from '../components/landing-page/StatSection'
import TestimonialSection from '../components/landing-page/TestimonialSection'
import { useLanguage } from '../context/LanguageContext'
import translations from '../context/Translations'

function LandingPageScreen() {
  const {lanCode} = useParams()
  const { language, setLanguage } = useLanguage();
  if (lanCode) {
    if (lanCode === 'en') {
      setLanguage('english');
    } else if (lanCode === 'ms') {
      setLanguage('malay');
    } else if (lanCode === "ind") {
      setLanguage('indonesia');
    }

  }
  const t = translations[language];

  const [openItemIndex, setOpenItemIndex] = useState(null);

  const handleAccordionClick = (index) => {
    if (index === openItemIndex) {
      // Clicking on the open item should close it
      setOpenItemIndex(null);
    } else {
      // Clicking on a closed item should open it and close others
      setOpenItemIndex(index);
    }
  };

  return (
    <>
    
      <Helmet>
        <title>Home | Aceva GPT</title>
      </Helmet>
      <div className="landing-page-canvas">
        
        <HeaderMenu />

        {/* sections */}
        <HeroSectionV3 t={t} />
        {/* <HeroSectionV2 t={t} /> */}
        <NewHeroSection t={t} />

        {/* marquee */}
        <PerformanceStats t={t} />
        <MarqueeSection t={t} />

        {/* state section */}
        <ChatbotCreateSteps />
        <StatSection t={t} />

        {/* process grid */}
        {/* <ProcessSection /> */}

        {/* left side flex section */}
        <LeftSideFlexSectionV2 t={t} />
        <LeftSideFlexSection t={t} />

        {/* integration section */}
        {/* <IntegrationSection /> */}
        <IntegrateSectionV2 t={t} />
        <Landing t={t} />
        <PricingSection t={t} />

        {/* testimonial */}
        <TestimonialSection t={t} />

        <FAQSection t={t} />
        <CTASection t={t} />

        {/* new foolter */}
        <FooterV2 t={t} />

        {/* <div className="landing-container hero-container">
          <div className="landing-hero-wrap">
            <div className="landing-hero-content hero-content">
              <h1>{t?.heroSection?.title?.custom} <span className="brand-color">{t?.heroSection?.title?.chatGPT}</span> {t?.heroSection?.title?.forYour} <span className="brand-color">{t?.heroSection?.title?.business}</span></h1>
              <p>{t?.heroSection?.subTitle}</p>

              <div className="landing-hero-action">
                <Link to="/register">
                  <button className='landing-hero-action-btn'>{t?.heroSection?.button}</button>
                </Link>
                <span>{t?.heroSection?.noCardRequired}</span>
              </div>
            </div>
            <div className="landing-hero-thumb">
              <img src={heroThumb} alt="hero thumb" />
            </div>
          </div>
        </div> */}

        {/* <LandingStepSection t={t} /> */}

        {/* video demo */}
        {/* <section className="full-width-container video-section">
          <div className="landing-container">
            <div className="video-demo-wrapper">
              <div className="landing-title">
                <h2>{t?.videoSection?.title}</h2>
                <p>{t?.videoSection?.subTitle}</p>
              </div>
              <iframe width="100%" height="415" src="https://www.youtube.com/embed/8KIUI1yUwg8?si=DCedC52o4_672UIE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
            </div>
          </div>
        </section> */}

        {/* live demo */}
        {/* <LiveDemoSection t={t} /> */}
        {/* <LandingLiveDemoSection /> */}

        {/* call to action */}
        {/* <section className="call-to-action-section">
          <div className="call-to-action-wrapper landing-container">
              <div className="landing-hero-content">
                <h1>{t?.ctaSection?.title}</h1>
                <p>{t?.ctaSection?.subTitle}</p>

                <div className="landing-hero-action">
                  <Link to="/pricing">
                    <button className='landing-hero-action-btn'>{t?.ctaSection?.button}</button>
                  </Link>
                </div>
              </div>
          </div>
        </section> */}

        {/* accordion */}
        {/* <section className="faq-section">
        <div className="landing-container accordion-container">
          <div className="landing-title-center">
            <div className="landing-hero-content">
              <h2>{t?.faqSection?.title}</h2>
            </div>
          </div>
          <div className="landing-accordion-wrapper">
            {t?.faqSection?.faqs?.map((item, index) => (
                <Accordion
                  key={index}
                  title={item.question}
                  isOpen={index === openItemIndex}
                  onClick={() => handleAccordionClick(index)}
                >
                  {item.answer}
                </Accordion>
              ))}
          </div>
        </div>
        </section> */}

        {/* footer */}
        {/* <FooterMenu t={t} /> */}
      </div>
    </>
  )
}

export default LandingPageScreen