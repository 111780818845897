import { Alert, Button, Spin } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AntdTable from './AntdTable';
import TimeSlot from './TimeSlot';
import { apiUrl } from '../../Variables';
import { formatDateToHumanReadable } from '../../utils/helper';


  //   'startTime': '[dynamic selected date][dynamic startTime]', // Formate must be like that: 2015-05-28T09:00:00-07:00
  //   'endTime': '[dynamic selected date][dynamic startTime]', // Formate must be like that: 2015-05-28T17:00:00-07:00

function BotCalendarWidget({setShowCalendar, setChat, chat, token, gcCalendar, botConfigInfo}) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  // const [eventData, setEventData] = useState(null);
  const [userInput, setUserInput] = useState({})

  const handleDateSelect = date => {
    setSelectedDate(date);
    setSuccessMessage(null);
    setErrorMessage(null);
  };

  const handleTimeSlotSelect = timeSlot => {
    setSelectedTimeSlot(timeSlot);
    setSuccessMessage(null);
    setErrorMessage(null);
  };

  console.log('selectedTimeSlot', selectedTimeSlot)
  console.log('selectedDate', selectedDate)

  const handleConfirmBooking = async () => {
    if (!userInput.summary || !userInput.name || !userInput.phone) {
      setShowErrMsg(true)
      return 0
    }
    setShowErrMsg(false)
    let eventData = {}
    if (selectedDate && selectedTimeSlot) {
      const startDateTime = `${selectedDate}T${selectedTimeSlot.startTime}`;
      const endDateTime = `${selectedDate}T${selectedTimeSlot.endTime}`;
   
     eventData = {
        summary: userInput?.summary,
        location: 'Office',
        description: `Name: ${userInput?.name}\n Phone: ${userInput?.phone}\n Email: ${userInput?.email || 'N/A'}`,
        start: {
          dateTime: startDateTime,
          timeZone: 'Asia/Kuala_Lumpur',
        },
        end: {
          dateTime: endDateTime,
          timeZone: 'Asia/Kuala_Lumpur',
        },
      }
    }
    if (eventData) {
      try {
        setLoading(true);
        const res = await axios.post(`${apiUrl}/gCalender/create`, {...eventData, googleRefreshToken: token, gcCalendar});
        setSuccessMessage('Meeting created successfully');
        setShowCalendar(false)
        setChat([...chat || [], {time: Date.now(), user: 'Get appointment', bot: {text: `Appointment created successfully. Here is your appointment details:\nSummary: ${res.data.event.summary}\n${res.data.event.description}\nDate: ${formatDateToHumanReadable(res.data.event.start.dateTime)}`}}])
      } catch (error) {
        console.error('Error creating meeting:', error);
        setErrorMessage('An error occurred while creating the meeting');
      } finally {
        setLoading(false);
      }
    } else {
      console.error('Please select a date and a time slot');
      setErrorMessage('Please select a date and a time slot');
    }
  };

  return (
    <>
      <form>
        <div className="form-group">
          <label htmlFor="">Appointment Summary<span style={{color:'red'}}>*</span></label>
          <input 
            onChange={(e) => {
              setUserInput({...userInput, [e.target.name]: e.target.value})
            }} 
            name='summary'
            value={userInput?.summary}
            type="text" 
            placeholder='Appointment Summary' 
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Name<span style={{color:'red'}}>*</span></label>
          <input 
            onChange={(e) => {
              setUserInput({...userInput, [e.target.name]: e.target.value})
            }} 
            name='name'
            value={userInput?.name}
            type="text" 
            placeholder='Your Name' 
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Phone Number<span style={{color:'red'}}>*</span></label>
          <input 
            onChange={(e) => {
              setUserInput({...userInput, [e.target.name]: e.target.value})
            }} 
            name='phone'
            value={userInput?.phone}
            type="text" 
            placeholder='+60187869262' 
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Email <small>(optional)</small></label>
          <input 
            onChange={(e) => {
              setUserInput({...userInput, [e.target.name]: e.target.value})
            }} 
            name='email'
            value={userInput?.email}
            type="text" 
            placeholder='example@gmail.com' 
          />
        </div>
        <AntdTable onDateSelect={handleDateSelect} />
        {
          selectedDate && <TimeSlot 
          startHour={9} 
          endHour={17} 
          selectedDate={selectedDate}
          onSelectSlot={handleTimeSlotSelect} 
          token={token}
          gcCalendar={gcCalendar}
          botConfigInfo={botConfigInfo}
        />
        }
        {showErrMsg && <small style={{color:'red'}}>Please fill above mendatory field.</small>}
        {successMessage && <Alert message={successMessage} type="success" />}
        {errorMessage && <Alert message={errorMessage} type="error" />}
        {selectedDate && selectedTimeSlot && !successMessage && (
          <Button
            // type="primary"
            className="submit-calendar-btn"
            style={{background: botConfigInfo.brandColor, color: 'white'}}
            onClick={handleConfirmBooking}
            disabled={loading}
          >
            {loading ? <Spin /> : 'Confirm Booking'}
          </Button>
        )}
      </form>
    </>
  );
}

export default BotCalendarWidget;
