import React from 'react'
import { Helmet } from 'react-helmet'
import CTASection from '../../components/CTASection'
import FooterMenu from '../../components/landing-page/FooterMenu'
import HeaderMenu from '../../components/landing-page/HeaderMenu'

import { useLanguage } from '../../context/LanguageContext'
import translations from '../../context/Translations'

function ContactUsScreen() {

  const { language } = useLanguage();
  const t = translations[language];

  return (
    <>
    
    <Helmet>
        <title>Contact Us | Aceva GPT</title>
      </Helmet>
      <HeaderMenu />

        <div className="landing-container page-details-container">
          <div className="page-details-meta">
            <h1>{t?.contactUs?.title}</h1>
            <p>{t?.contactUs?.subTitle}</p>
          </div>

            <div className="page-details-content-block contact-us-content">
              <h2>Information</h2>
              <a href={`mailto:info@wipdata.com`}>info@wipdata.com</a>
              <p>
                Kelana Square, B607, 47301 Petaling Jaya, Selangor, Malaysia
              </p>
              
            </div>
            
          <div className="page-details-content ">

            {/* call to action */}
            <CTASection />

          </div>
        </div>

      <FooterMenu />
    </>
  )
}

export default ContactUsScreen