import React from 'react';
import { Helmet } from 'react-helmet';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import CTASection from '../../components/CTASection';
import FooterMenu from '../../components/landing-page/FooterMenu';
import HeaderMenu from '../../components/landing-page/HeaderMenu';
import { useLanguage } from '../../context/LanguageContext';
import translations from '../../context/Translations';

function PrivacyPolicyScreen() {
  const { language } = useLanguage();
  const t = translations[language];
  const content = translations[language]?.privacyPolicy?.description; 

  return (
    <>
    
    <Helmet>
        <title>Privacy policy | Aceva GPT</title>
      </Helmet>
      <HeaderMenu t={t} />

      <div className="landing-container page-details-container">
        <div className="page-details-meta">
          <h1>{t?.privacyPolicy?.title}</h1>
          <p> {t?.privacyPolicy?.subTitle}</p>
        </div>

        {/* Section 1: Data Controller */}
        <div className="page-details-content-block">
            <h2>{content.introduction.title}</h2>
            <p>{content.introduction.content}</p>
        </div>

        {/* Section 2: Definitions */}
        <div className="page-details-content-block">
            <h2>{content.definitions.title}</h2>
            <p>{content.definitions.content.API}</p>
            <p>{content.definitions.content.Cookies}</p>
            <p>{content.definitions.content.Device}</p>
            <p>{content.definitions.content.DataController}</p>
            <p>{content.definitions.content.DataProcessors}</p>
            <p>{content.definitions.content.DataSubject}</p>
            <p>{content.definitions.content.PersonalData}</p>
            <p>{content.definitions.content.Service}</p>
            <p>{content.definitions.content.User}</p>
        </div>

        {/* Section 3: Data Controller */}
        <div className="page-details-content-block">
            <h2>{content.dataController.title}</h2>
            <p>{content.dataController.content}</p>
        </div>

        {/* Section 4: Information Collection and Use */}
        <div className="page-details-content-block">
            <h2>{content.informationCollectionUse.title}</h2>
            <p>{content.informationCollectionUse.content}</p>
        </div>

        {/* Section 5: Types of Data Collected */}
        <div className="page-details-content-block">
            <h2>{content.typesOfDataCollected.title}</h2>
            <p>{content.typesOfDataCollected.personalData.title}:</p>
            <ul>
                {content.typesOfDataCollected.personalData.content.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ul>
            <h3>{content.typesOfDataCollected.usageData.title}:</h3>
            <p>{content.typesOfDataCollected.usageData.content}</p>
        </div>

        {/* Section 6: Use of Data */}
        <div className="page-details-content-block">
            <h2>{content.useOfData.title}</h2>
            <p>{content.useOfData.content}</p>
        </div>

        {/* Section 7: Retention of Data */}
        <div className="page-details-content-block">
            <h2>{content.retentionOfData.title}</h2>
            <p>{content.retentionOfData.content}</p>
        </div>

        {/* Section 8: Transfer of Data */}
        <div className="page-details-content-block">
            <h2>{content.transferOfData.title}</h2>
            <p>{content.transferOfData.content}</p>
        </div>

        {/* Section 9: Disclosure of Data */}
        <div className="page-details-content-block">
            <h2>{content.disclosureOfData.title}</h2>
            <p>{content.disclosureOfData.content}</p>
        </div>

        {/* Section 10: Security of Data */}
        <div className="page-details-content-block">
            <h2>{content.securityOfData.title}</h2>
            <p>{content.securityOfData.content}</p>
        </div>

        {/* Section 11: Your Data Protection Rights */}
        <div className="page-details-content-block">
            <h2>{content.yourDataProtectionRights.title}</h2>
            <p>{content.yourDataProtectionRights.content}</p>
        </div>

        {/* Section 13: Service Providers */}
        <div className="page-details-content-block">
            <h2>{content.serviceProviders.title}</h2>
            <p>{content.serviceProviders.content}</p>
        </div>

        {/* Section 14: Analytics */}
        <div className="page-details-content-block">
            <h2>{content.analytics.title}</h2>
            <p>{content.analytics.content}</p>
        </div>

        {/* Section 15: Payments */}
        <div className="page-details-content-block">
            <h2>{content.payments.title}</h2>
            <p>{content.payments.content}</p>
        </div>



        {/* Section 16: Links to Other Sites */}
        <div className="page-details-content-block">
            <h2>{content.linksToOtherSites.title}</h2>
            <p>{content.linksToOtherSites.content}</p>
        </div>

        {/* Section 17: Children's Privacy */}
        <div className="page-details-content-block">
            <h2>{content.childrensPrivacy.title}</h2>
            <p>{content.childrensPrivacy.content}</p>
        </div>

        {/* Section 17: Limited Use Requirements */}
        <div className="page-details-content-block">
          <div>
            <h2>{content.limitedUseRequirements.title}</h2>
            <p>
              {content.limitedUseRequirements.content} <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">{content.limitedUseRequirements.label}</a>, {content.limitedUseRequirements.content2}
            </p>
          </div>

          <ul>
            {content?.limitedUseRequirementsDetails?.map((item) => (
              <li key={item.key}>
                <strong>{item.title}:</strong> {item.content}
              </li>
            ))}
          </ul>

        </div>

        {/* Section 18: Changes to This Privacy Policy */}
        <div className="page-details-content-block">
            <h2>{content.changesToPrivacyPolicy.title}</h2>
            <p>{content.changesToPrivacyPolicy.content}</p>
        </div>

        {/* Section 19: Contact Us */}
        <div className="page-details-content-block">
            <h2>{content.contactUs.title}</h2>
            <p>{content.contactUs.content} <Link to={`mailto:${content.contactUs.email}`}>{content.contactUs.email}</Link> </p>
            
        </div>


        {/* Add a link back to the Terms and Conditions screen */}
        <div className="page-details-content-block suggest-link">
          <Link to="/terms-and-conditions">
          {content.termsAndConditionsLink.text} <BsArrowRight />
          </Link>
        </div>

        {/* Call to action */}
        <CTASection />
      </div>

      <FooterMenu t={t} />
    </>
  );
}

export default PrivacyPolicyScreen;
