import { MessageSquare } from "lucide-react";
import React from 'react';
import video from '../../videos/personalized-responses.mp4';

function LeftSideFlexSection({ t }) {
  return (
    <>
      <section className="left-side-flex-section">
        <div className="container">
          <div className="left-side-flex-wrapper">
            <div className="left-side-flex-thumb">
              {/* Video added here */}
              <video width="100%" height="100%" className="left-side-flex-video" autoPlay loop muted>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="left-side-content flex flex-col !gap-4 text-left !items-start justify-start">
              <p className="mt-2 text-base text-brand-color font-semibold pb-3">
                {t?.leftSideFlexSection?.satisfiedCustomer || 'SATISFIED CUSTOMER = HIGHER CONVERSION'}
              </p>
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-gray-800 capitalize !leading-[60px]">
                {t?.leftSideFlexSection?.transformWebsite || 'Transform your website into an intelligent sales agent'}
              </h2>
              <div className="mt-12 text-center">
                <a
                  href="#"
                  className="inline-flex items-center px-6 py-3 text-lg font-medium text-white bg-brand-color rounded-full hover:bg-brand-color-bold transition-colors duration-300"
                >
                  <MessageSquare className="text-white mr-2" />
                  <span>{t?.leftSideFlexSection?.createChatbot || 'Create New Chatbot'}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LeftSideFlexSection;
