import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { FiUpload } from 'react-icons/fi'
import { MdCloudUpload } from 'react-icons/md'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { apiUrl } from '../Variables'
import ConfigBot from '../components/ConfigBot'
import Layout from '../components/Layout'
import ConfigColor from '../components/botConfig/ConfigColor'
import { BsX, BsXLg } from 'react-icons/bs'


function AppearanceScreen({toggleLoggedInState}) {

  const [uploadLoading, setUploadLoading] = useState(false);
  const [removeLogoLoading, setRemoveLogoLoading] = useState(false); 
  const [createLoading, setCreateLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate(); 
  const botId = searchParams.get('id');

  const [config, setConfig] = useState({})

    // Function to remove the logo
    const removeLogoHandler = async () => {
      try {
        setRemoveLogoLoading(true);
        // Clear the logo in your config
        setConfig({ ...config, logo: null });
        // Add code to remove the logo from the server if necessary
        // ...
  
        setRemoveLogoLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

  // fetch existing configarations
    // fetch group data []
    useEffect(() => {
      const fetchGroup = async () => {
        try {
          // Retrieve userInfo from localStorage
          const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  
          if (!userInfo || !userInfo.token) {
            console.error('User token not found.');
            return;
          }

          const ifNotFoundId = '64f0dd2b2dd6f161f468f4dd'
  
          // Add headers to include JWT token
          const headers = {
            Authorization: `Bearer ${userInfo.token}`,
          };
  
          setLoading(true)
          const { data } = await axios.get(`${apiUrl}/botConfig/${botId || ifNotFoundId}`, { headers });
          setLoading(false)
          setConfig(data[0]?.configData);
        } catch (error) {
          setLoading(false)
          console.log('Bot config fetch error:', error);
        }
      };
      fetchGroup();
    }, [botId]);

  
  // file upload handler
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append("upload_preset", "pewhglsn")
    formData.append("cloud_name", "dzo2vmjdy")

    try {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      // const { data } = await axios.post(`${fileUrl}`, formData, headers)
      setUploadLoading(true)
      const {data} = await axios.post('https://api.cloudinary.com/v1_1/dzo2vmjdy/image/upload', formData, headers)
      setConfig({ ...config, logo: data?.url });
      setUploadLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  // config handler
  const botConfigHandler = async () => {
    try {
      const {data} = await axios.post(`${apiUrl}/botConfig/`)
    } catch (error) {
      console.log('Error:', error?.message || error?.response?.data)
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault();

    // Retrieve userInfo from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (!userInfo || !userInfo.token) {
      console.error('User token not found.');
      return;
    }

    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    try {
      setCreateLoading(true)
      const { data } = await axios.post(`${apiUrl}/botConfig`, {configData: config, chatbotId: '64ece127cca65c0c6f84e282'}, { headers });
      setCreateLoading(false)
      console.log('response data:', data)
      toast.success('Configuration saved successfully!')
      navigate('/chatbot-list')
    } catch (error) {
      setCreateLoading(false)
      console.error('Error to save configuration:', error);
      toast.error(`Failed: ${error?.message || error?.response?.data}`)
    }
  };

  console.log('config', config)

  return (
    <>
      <Layout toggleLoggedInState={toggleLoggedInState}>

        <div className="chatbot-config-wrapper">

          {/* configaration fields */}
          <div className="chatbot-config-fields">
            <div className="form-title">
              <h3>Appearance</h3>
              <p>Change your chabot yourself</p>
            </div>
            <form onSubmit={submitHandler}>
              {/* chatbot name */}
              <div className="form-group">
                <label htmlFor="">Chatbot name</label>
                <input 
                  onChange={(e) => {
                    config.botName = e.target.value
                    setConfig({...config})
                  }} 
                  value={config?.botName}
                  type="text" 
                  placeholder='Chatbot name' 
                />
              </div>
              {/* welcome message */}
              <div className="form-group">
                <label htmlFor="">Welcome message</label>
                <textarea
                  onChange={(e) => {
                    config.welcomeMessage = e.target.value
                    setConfig({...config})
                  }} 
                  value={config?.welcomeMessage}
                placeholder='Hi, How can I help you?' />
              </div>
              {/* placeholder name */}
              <div className="form-group">
                <label htmlFor="">Input placeholder name</label>
                <input 
                  onChange={(e) => {
                    config.placeholder = e.target.value
                    setConfig({...config})
                  }} 
                  value={config?.placeholder}
                  type="text" placeholder='Ask me anything' />
              </div>

              {/* upload photo */}
              <div className="config-upload-logo">
                <p className='uploaded-label'>Upload new file</p>
                <div className="chatbot-upload-image-wrap">
                  <label>
                    {uploadLoading && (
                      // Show loading indicator while uploading
                      <div className="loading-indicator">Loading...</div>
                    )}
                    {!config?.logo && !uploadLoading && (
                      <div className="chatbot-upload-content-wrap">
                        <FiUpload className='user-update-icon' />
                        <h3>Upload Logo</h3>
                        <p>Please upload only JPG, PNG, or SVG file</p>
                        <input type="file" id='image-file' name="profilePic" custom="true" onChange={uploadFileHandler} hidden />
                      </div>
                    )}
                    {config?.logo && !uploadLoading && (
                      <div className="user-profile-header-thumb create-group-logo">
                        <img src={config?.logo && config?.logo} alt="profile photo" />
                        <div className="user-update-profile create-user-profile-update-icon-wrapper">
                          <input type="file" id='image-file' name="profilePic" custom="true" onChange={uploadFileHandler} hidden />
                          <div className="user-update-profile">
                            {!removeLogoLoading && (
                              // Show the remove logo button when not removing
                              <BsX className='user-update-icon' onClick={removeLogoHandler} />
                            )}
                            {removeLogoLoading && (
                              // Show loading indicator while removing
                              <div className="loading-indicator">Removing...</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </label>
                </div>
              </div>

              {/* color picker */}
              <ConfigColor config={config} setConfig={setConfig} />
            </form>
            
            {/* action button */}
            <div className="chatbot-action-wrap">
              <Link onClick={submitHandler} to='/chatbot-list'>
                <li className='chatbot-action-item active'> <span>Save</span>  </li>
              </Link>
            </div>
          </div>

          {/* chatbot */}
          <div className="chatbot-config-bot">
            <ConfigBot config={config} setConfig={setConfig} />
          </div>
        </div>

      </Layout>
    </>
  )
}

export default AppearanceScreen