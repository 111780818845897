import React, { useEffect, useRef, useState } from 'react';
import { GoPaperAirplane } from "react-icons/go";


export default function LeftSideFlexSectionV2() {
  const [messages, setMessages] = useState([
    { text: 'Hi there! How can I help you today?', isUser: false },
    { text: 'What is AI chatbot?', isUser: true },
    {
      text: 'Thank you for your message! Our AI is processing your request and will respond shortly.',
      isUser: false,
    },
    { text: 'How does it work?', isUser: true },
    {
      text: 'Thank you for your message! Our AI is processing your request and will respond shortly.',
      isUser: false,
    },
  ]);
  const [inputMessage, setInputMessage] = useState('');
  const messagesEndRef = useRef(null);
  const chatBodyRef = useRef(null);

  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  useEffect(scrollToBottom, [messages]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (inputMessage.trim()) {
      setMessages([...messages, { text: inputMessage, isUser: true }]);
      setInputMessage('');

      // Simulate AI response with a timeout
      setTimeout(() => {
        setMessages((prev) => [
          ...prev,
          {
            text: 'Thank you for your message! Our AI is processing your request and will respond shortly.',
            isUser: false,
          },
        ]);
      }, 1000);
    }
  };

  return (
    <div className='py-20 bg-gradient-to-b from-green-50 to-gray-50'>
      <div className='container p-0 bg-white rounded-2xl shadow-xl overflow-hidden'>
        <div className='grid md:grid-cols-2'>
          {/* Chat Column */}
          <div className='p-6 md:p-8 bg-white'>
            <div className='bg-white rounded-lg shadow-lg overflow-hidden border border-gray-100'>
              <div className='border-b px-4 py-3 flex items-center justify-between'>
                <h2 className='text-lg font-semibold'>ACEVA GPT CHATBOT</h2>
                <div className='flex space-x-2'>
                  <div className='w-3 h-3 rounded-full bg-red-500'></div>
                  <div className='w-3 h-3 rounded-full bg-yellow-500'></div>
                  <div className='w-3 h-3 rounded-full bg-green-500'></div>
                </div>
              </div>
              <div ref={chatBodyRef} className='h-[400px] overflow-y-auto p-4'>
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`mb-4 ${message.isUser ? 'text-right' : 'text-left'}`}
                  >
                    <div
                      className={`inline-block p-3 rounded-lg ${
                        message.isUser
                          ? 'bg-brand-color text-white'
                          : 'bg-gray-100 text-gray-800'
                      }`}
                    >
                      {message.text}
                    </div>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
              <div className='border-t p-4'>
                <form
                  onSubmit={handleSendMessage}
                  className='flex items-center space-x-2'
                >
                  <input
                    type='text'
                    placeholder='Type your message...'
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    className='flex-grow p-2 border rounded-md focus:outline-0 focus:ring-1 focus:ring-brand-color'
                  />
                  <button
                    type='submit'
                    className='bg-brand-color text-white p-2 rounded-md hover:bg-brand-color-bold focus:outline-none focus:ring-2 focus:ring-brand-color focus:ring-offset-2'
                  >
                    <GoPaperAirplane className='h-6 w-6 text-white' />
                    <span className='sr-only'>Send message</span>
                  </button>
                </form>
              </div>
            </div>
          </div>

          {/* Marketing Column */}
          <div className='p-6 md:p-8 bg-gradient-to-br from-brand-color to-brand-color-bold text-white w-full'>
            <div className='h-full flex flex-col justify-between'>
              <div className='space-y-6'>
                <h2 className='text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl capitalize !leading-[60px] text-white'>
                  Transform Your Website With AI-Powered Chat
                </h2>
                <p className='text-lg text-white'>
                  Enhance customer interactions and boost conversions with our cutting-edge chatbot solution.
                </p>
                <ul className='space-y-4'>
                  <li className='flex items-center gap-3'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 text-teal-200'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z'
                      />
                    </svg>
                    <span>24/7 Intelligent Customer Support</span>
                  </li>
                  <li className='flex items-center gap-3'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 text-teal-200'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M13 10V3L4 14h7v7l9-11h-7z'
                      />
                    </svg>
                    <span>Lightning-Fast Response Times</span>
                  </li>
                  <li className='flex items-center gap-3'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 text-teal-200'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M13 7h8m0 0v8m0-8l-8 8-4-4-6 6'
                      />
                    </svg>
                    <span>Increased Conversion Rates</span>
                  </li>
                </ul>
              </div>
              <button className='w-full bg-white text-brand-color-bold hover:bg-teal-50 mt-8 font-semibold py-4 px-6 rounded-xl transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-brand-color'>
                Get Started Free
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
