import React from 'react'
import FooterMenu from '../components/landing-page/FooterMenu'
import HeaderMenu from '../components/landing-page/HeaderMenu'
import LandingStepSection from '../components/landing-page/LandingStepSection'
import { useLanguage } from '../context/LanguageContext'
import translations from '../context/Translations'

function HowIsWorkScreen() {

  const { language } = useLanguage();
  const t = translations[language];

  return (
    <>
      <HeaderMenu t={t} />
        <LandingStepSection t={t} />
      <FooterMenu t={t} />
    </>
  )
}

export default HowIsWorkScreen