import React from 'react';
import Select from 'react-select';
import ConfigBot from '../../components/ConfigBot';

function LanguageStep({config, setConfig}) {

  const language = [
    {value: 'en-GB', label: 'English (UK)'},
    {value: 'hi', label: 'Hindi'},
    {value: 'id', label: 'Indonesian'},
    {value: 'ms', label: 'Malay'},
    {value: 'zh-CN', label: 'Chinese (PRC)'}
  ]

  return (
    <div className="create-chatbot-container create-chatbot-file-wrapper">
      <div className="create-chatbot-file-wrapper">
        <div className="create-chatbot-file-body remove-scroll">
          <div className="create-title">
            <h2>Language</h2>
          </div>
          <form>
            <div className="chatbot-config-wrapper prompt-body-wrapper">
              <div className="chatbot-config-fields">
                  <div className="form-group prompt-group">
                    <label htmlFor={`Primary language`}>
                      Select the <strong className="bold-lan">Primary language</strong> for your bot.
                    </label>
                    <div className="language-input-wrapper">
                    <Select
                      options={language}
                      placeholder="None"
                      className='clear-default-format-selectbox'
                      isSearchable
                      value={config?.language?.[0] || ''}
                      onChange={(selected) => {
                        if (!config.language) {
                          config.language = []; // Ensure the language array exists
                        }
                        config.language[0] = selected;
                        setConfig({...config});
                      }}
                    />

                    </div>
                  </div>
                  <div className="form-group prompt-group">
                    <label htmlFor={`Primary language`}>
                      Select the <strong className="bold-lan">Secondary language</strong> for your bot.
                    </label>
                    <div className="language-input-wrapper">
                      <Select
                        options={[{value: null, label: 'None'}, ...language.filter(item => !config?.language?.map(item => item.value).includes(item.value))]}
                        placeholder="None"
                        isSearchable
                        value={config?.language[1]}
                        onChange={(selected) => {
                          config.language[1] = selected
                          setConfig({...config})
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group prompt-group">
                    <label htmlFor={`Primary language`}>
                      Select the <strong className="bold-lan">Tertiary language</strong> for your bot.
                    </label>
                    <div className="language-input-wrapper">
                      <Select
                        options={[{value: null, label: 'None'}, ...language.filter(item => !config?.language?.map(item => item.value).includes(item.value))]}
                        placeholder="None"
                        isSearchable
                        value={config?.language[2]}
                        onChange={(selected) => {
                          config.language[2] = selected
                          setConfig({...config})
                        }}
                      />
                    </div>
                  </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="create-chatbot-preview-wrapper">
        <ConfigBot mask={true} config={config} setConfig={setConfig} /> 
      </div>
    </div>
  );
}

export default LanguageStep;
