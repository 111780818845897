import { Form, InputNumber, Input } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';

function IntegrationScreen({toggleLoggedInState}) {
  const navigate = useNavigate()
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  const onFinish = (values) => {
    console.log('Success:', values);
    localStorage.setItem('whatsapp-b-info', JSON.stringify(values))
    navigate('/create-whatsapp-chatbot')
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Layout toggleLoggedInState={toggleLoggedInState}>
        <section className="wa-integration-section">
          <div className="container">
            <div className="wa-integration-wrapper">
              <div className="chatbot-type-content">
                <h2>WhatsApp Integration</h2>
                <p>Please enter your details below for the next step. Need assistance? Click on the "I don't have a WABA ID" button, and our ACEVA support team will be glad to help!</p>
              </div>

              <div className="wa-form">
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  autoComplete="off"
                >
                  <Form.Item
                    label="Whatsapp App Name"
                    name="waAppName"
                    rules={[{ required: true, message: 'Please input the app name!' }]}
                  >
                    <Input placeholder="Wipdata AI" />
                  </Form.Item>

                  <Form.Item
                    label="Whatsapp App Id"
                    name="waAppId"
                    rules={[{ required: true, message: 'Please input the app id!' }]}
                  >
                    <Input placeholder="1b73dad6-xxxx-xxxx-xxxx-60981da19f70" />
                  </Form.Item>

                  <Form.Item
                    label="Phone Number"
                    name="phoneNumber"
                    rules={[{ required: true, message: 'Please input the phone number!' }]}
                  >
                    <InputNumber style={{ width: '100%' }} placeholder='01111234515' minLength={9}/>
                  </Form.Item>

                  <Form.Item
                    label="WABA ID"
                    name="wabaId"
                    rules={[{ required: true, message: 'Please input the WABA ID!'}]}
                  >
                    <InputNumber style={{ width: '100%' }} placeholder='229693900231951' minLength={15} maxLength={15} />
                  </Form.Item>

                  <Form.Item
                    label="Business Solution Provider (BSP)"
                    name="bsp"
                    rules={[{ required: true, message: 'Please input the Business Solution Provider!' }]}
                  >
                    <Input placeholder='Gupshup' />
                  </Form.Item>
                  <div className="chatbot-type-action" style={{marginTop:'20px'}}>
                    {/* <Link to="/create-whatsapp-chatbot"> */}
                      <button className="default-button chatbot-type-btn" htmlType="submit">Connect</button>  
                    {/* </Link> */}
                    <Link to="/whatsapp-details">
                      <button className="default-button waba-id-btn">I don’t have a WABA ID</button>
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default IntegrationScreen