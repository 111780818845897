import React from 'react'
import { Link } from 'react-router-dom'
import video from '../../videos/hero.mp4'

function NewHeroSection({t}) {
  return (
    <>
      <div className="landing-page-canvas new-hero-section">
        <div className="landing-container hero-container">
          <div className="landing-hero-wrap new-hero-wrap">
            <div className="landing-hero-content hero-content">
              <h1 className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl leading-[70px] text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-gray-800 capitalize">
                {t?.heroSection?.title?.custom} <span className="brand-color">{t?.heroSection?.title?.chatGPT}</span> {t?.heroSection?.title?.forYour} <span className="brand-color">{t?.heroSection?.title?.business}</span>
              </h1>

              <p>{t?.heroSection?.subTitle}</p>
            </div>
            <div className="landing-hero-thumb">
              {/* Auto-playing, looping video */}
              <video className="rounded" width="850" height="550" autoPlay loop muted playsInline>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              <div className="landing-hero-action">
                <Link to="/register">
                  <button className='landing-hero-action-btn'>{t?.heroSection?.button}</button>
                </Link>
                <span>{t?.heroSection?.noCardRequired}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewHeroSection
