import React from 'react';
import Marquee from 'react-fast-marquee';
import { FaStar } from 'react-icons/fa';
import logoOne from '../../images/avatars/1.png';
import logoTwo from '../../images/avatars/2.png';
import logoThree from '../../images/avatars/3.png';
import logoFour from '../../images/avatars/4.png';

function TestimonialSection({ t }) {
  const testimonials = [
    {
      name: 'Dainal Mathew',
      position: t?.testimonialSection?.testimonials?.dainalMathew?.position || 'Apple Business Manager',
      logo: logoOne,
      review: t?.testimonialSection?.testimonials?.dainalMathew?.review || 'ACEVA GPT has transformed the way we handle customer service. It’s like having a virtual team working around the clock!',
    },
    {
      name: 'Tristan Eka',
      position: t?.testimonialSection?.testimonials?.tristanEka?.position || 'Sales Manager at TechPlus',
      logo: logoTwo,
      review: t?.testimonialSection?.testimonials?.tristanEka?.review || 'The accuracy and speed of ACEVA GPT are remarkable. It has helped us streamline our sales processes significantly.',
    },
    {
      name: 'Johan Mular',
      position: t?.testimonialSection?.testimonials?.johanMular?.position || 'Head of Operations at InnovateNow',
      logo: logoThree,
      review: t?.testimonialSection?.testimonials?.johanMular?.review || 'ACEVA GPT’s chatbot has not only improved our response times but also enhanced customer satisfaction across the board.',
    },
    {
      name: 'Linda Brooks',
      position: t?.testimonialSection?.testimonials?.lindaBrooks?.position || 'Marketing Director at SkyCorp',
      logo: logoFour,
      review: t?.testimonialSection?.testimonials?.lindaBrooks?.review || 'We’ve seen a significant increase in conversions after integrating ACEVA GPT into our website. It’s a game-changer!',
    },
  ];

  return (
    <section className="testimonial-section">
      <div className="container">
        <div className="mb-3">
          <div className="flex flex-col items-center justify-center space-y-4 text-center mb-9">
            <h2 className="!text-base font-semibold text-brand-color tracking-wide uppercase">
              {t?.testimonialSection?.header?.whatTheyAreSaying || 'WHAT THEY\'RE SAYING'}
            </h2>
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-gray-800 capitalize text-center mb-4 mt-10">
              {t?.testimonialSection?.header?.title || 'Hear From Our Happy Clients'}
            </h2>
            <p className="max-w-[700px] text-gray-600 text-xl">
              {t?.testimonialSection?.header?.description || 'Discover what our clients love about ACEVA GPT.'}
            </p>
          </div>
        </div>
        
        {/* First Marquee */}
        <Marquee gradient={true} speed={50} pauseOnHover={true}>
          {testimonials.map((testimonial, index) => (
            <div className="testimonial-box" key={index}>
              <div className="tes-profile">
                <img src={testimonial.logo} alt={testimonial.name} />
                <div className="tes-profile-content">
                  <h3>{testimonial.name}</h3>
                  <p>{testimonial.position}</p>
                  <div className="tes-review">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </div>
                </div>
              </div>
              <div className="tes-content">
                <p>{testimonial.review}</p>
              </div>
            </div>
          ))}
        </Marquee>

        {/* Second Marquee */}
        <Marquee gradient={true} speed={50} pauseOnHover={true} direction="right">
          {testimonials.map((testimonial, index) => (
            <div className="testimonial-box" key={index}>
              <div className="tes-profile">
                <img src={testimonial.logo} alt={testimonial.name} />
                <div className="tes-profile-content">
                  <h3>{testimonial.name}</h3>
                  <p>{testimonial.position}</p>
                  <div className="tes-review">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </div>
                </div>
              </div>
              <div className="tes-content">
                <p>{testimonial.review}</p>
              </div>
            </div>
          ))}
        </Marquee>
      </div>
    </section>
  );
}

export default TestimonialSection;
