import React from 'react';
import Marquee from 'react-fast-marquee';
import logoOne from '../../images/logos/logo1.svg';
import logoThree from '../../images/logos/logo2.svg';
import logoTwo from '../../images/logos/logo3.svg';
import logoFour from '../../images/logos/logo4.webp';
import logoFive from '../../images/logos/logo5.svg';
import logoSix from '../../images/logos/logo7.svg';

function MarqueeSection({t}) {

  const logos = [
    logoOne,
    logoTwo,
    logoThree,
    logoFour,
    logoFive,
    logoSix,
    logoOne,
    logoTwo,
    logoThree,
    logoFour,
    logoFive,
    logoSix,
    // Add more logos here
  ];

  return (
    <>
      <section className="marquee-section">
        <div className="container">
          <div className="landing-title">
            <h2 className="text-4xl font-bold tracking-tighter sm:text-5xl text-black">
              {t?.marqueeSection?.trustedBusinesses || 'Trusted by +25,000 businesses'}
            </h2>
          </div>
          <Marquee speed={60} gradient={true} gradientColor="white">
            {logos.map((logo, index) => (
              <img
                className="marquee-img"
                key={index}
                src={logo}
                alt={`${t?.marqueeSection?.logoAlt || 'Logo'} ${index + 1}`}
                style={{ margin: '0 20px' }}
              />
            ))}
          </Marquee>
        </div>
      </section>
    </>
  );
}

export default MarqueeSection;
