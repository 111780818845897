import React from 'react'
import HeroSection from '../../components/blog/HeroSection'
import LatestPostSection from '../../components/blog/LatestPostSection'
import FooterMenu from '../../components/landing-page/FooterMenu'
import HeaderMenu from '../../components/landing-page/HeaderMenu'

function BlogLandingScreen() {
  return (
    <>
      <HeaderMenu />

      <section className='blog-landing-page'>
        <HeroSection />
        <LatestPostSection />
      </section>

      <FooterMenu />
    </>
  )
}

export default BlogLandingScreen