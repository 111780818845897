import { Select, Modal, DatePicker } from 'antd'
import { ChevronDown } from 'lucide-react'
import React, { useState } from 'react'
import { BsFileEarmarkSpreadsheet } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment' // Import moment for date formatting

const DownloadLeadSection = () => {
  const [selectedDateRange, setSelectedDateRange] = useState('select-date')
  const [customDateRangeText, setCustomDateRangeText] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isCustomDateModalVisible, setIsCustomDateModalVisible] = useState(false)

  const formatDate = (date) => {
    return date ? moment(date).format('DD/MM/YYYY') : ''
  }

  const handleDateRangeChange = (value) => {
    setSelectedDateRange(value);
    if (value === 'custom-date') {
      setIsCustomDateModalVisible(true);
    } else {
      setCustomDateRangeText('');
      // Handle other date range options here
      switch(value) {
        case 'today':
          setCustomDateRangeText(formatDate(moment()));
          break;
        case 'yesterday':
          setCustomDateRangeText(formatDate(moment().subtract(1, 'days')));
          break;
        case 'last-7-days':
          setCustomDateRangeText(`${formatDate(moment().subtract(6, 'days'))} - ${formatDate(moment())}`);
          break;
        case 'last-30-days':
          setCustomDateRangeText(`${formatDate(moment().subtract(29, 'days'))} - ${formatDate(moment())}`);
          break;
        default:
          setCustomDateRangeText('');
      }
    }
  };

  const handleCustomDateSubmit = (e) => {
    e.preventDefault();
    if (startDate && endDate) {
      setIsCustomDateModalVisible(false);
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
      setCustomDateRangeText(`${formattedStartDate} - ${formattedEndDate}`);
      setSelectedDateRange('select-date'); // Reset the select box
      console.log('Custom date range:', formattedStartDate, 'to', formattedEndDate);
    } else {
      toast.error('Please select both start and end dates');
    }
  };

  return (
    <>
      <div className="mb-8 bg-white border border-gray-200 rounded-lg shadow-sm mt-6">
        <div className="p-4 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-800">Download Leads</h2>
          <div className="flex items-center space-x-2">
            {customDateRangeText && (
              <>
                <span className="text-sm text-gray-500 font-medium">Filtered date: </span>
                <span className="text-sm font-medium text-brand-color bg-brand-color bg-opacity-10 px-3 py-1 rounded-full">
                  {customDateRangeText}
                </span>
              </>
            )}
            <Select
              className='font-poppins !text-sm min-w-[150px] ml-4'
              value={selectedDateRange}
              onChange={handleDateRangeChange}
              suffixIcon={<ChevronDown size={16} className='text-gray-500' />}
            >
              <Select.Option className="font-poppins !text-sm" value="select-date">Select Date</Select.Option>
              <Select.Option className="font-poppins !text-sm" value="today">Today</Select.Option>
              <Select.Option className="font-poppins !text-sm" value="yesterday">Yesterday</Select.Option>
              <Select.Option className="font-poppins !text-sm" value="last-7-days">Last 7 days</Select.Option>
              <Select.Option className="font-poppins !text-sm" value="last-30-days">Last 30 days</Select.Option>
              <Select.Option className="font-poppins !text-sm" value="custom-date">Custom Date Range</Select.Option>
            </Select>
          </div>
        </div>
        <div className="p-4">
          <p className="text-gray-600 mb-4">
            📊 Download valuable customer insights in CSV format to capture essential data and contact information. 
            Get your insights here.
          </p>
          <div className="flex justify-start items-center overflow-hidden rounded-md w-fit">
            <Link to="/" style={{ borderRadius: '8px' }}>
              <button className='flex justify-start items-center gap-2 bg-brand-color hover:bg-brand-color-bold text-white px-5 py-2 font-medium' style={{ borderRadius: '8px' }}>
                <BsFileEarmarkSpreadsheet className="text-white" size={16} />
                <span>Download Leads</span>
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* Custom Date Modal */}
      <Modal
        className='font-poppins'
        title={<h2 className="text-xl font-semibold text-gray-800 mb-2">Select Custom Date Range</h2>}
        visible={isCustomDateModalVisible}
        onCancel={() => setIsCustomDateModalVisible(false)}
        footer={null}
      >
        <form onSubmit={handleCustomDateSubmit}>
          <input
            type="date"
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
            placeholder="Start Date"
            style={{ marginBottom: '10px', width: '100%' }}
          />
          <input
            type="date"
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate}
            placeholder="End Date"
            style={{ marginBottom: '10px', width: '100%' }}
          />
          <button type="submit" className="bg-brand-color text-white px-4 py-2 rounded font-medium mt-3-">
            Submit
          </button>
        </form>
      </Modal>
    </>
  )
}

export default DownloadLeadSection
