import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import FooterMenu from '../components/landing-page/FooterMenu';
import HeaderMenu from '../components/landing-page/HeaderMenu';
import Addons from '../components/pricing/Addons';
import PricingCardSection from '../components/pricing/PricingCardSection';
import PricingCardSectionYearly from '../components/pricing/PricingCardSectionYearly';
import { useLanguage } from '../context/LanguageContext';
import translations from '../context/Translations';

function PricingScreen() {
  const { language } = useLanguage();
  const t = translations[language];

  const [isYearly, setIsYearly] = useState(false);
  const [activeTab, setActiveTab] = useState('monthly'); // Custom tab state

  return (
    <>
      <Helmet>
        <title>Pricing | Aceva GPT</title>
      </Helmet>
      <HeaderMenu t={t} />

      {/* <PricingPlanV2 /> */}

      <section className="pricing-section">
        <div className="landing-container pricing-container">
          <div className="landing-title">
            <h1>{t?.pricing?.title}</h1>
            <p>{t?.pricing?.subTitle}</p>
          </div>

          {/* Custom Tabs */}
          <div className="custom-tabs">
          {/* Toggle between Monthly and Yearly */}
          <div className="flex justify-center mb-6">
            <div className="bg-brand-color p-1 rounded-full flex items-center">
              <button
                className={`px-4 py-2 rounded-full transition-all duration-300 ${!isYearly ? 'bg-white text-brand-color-bold' : 'text-gray-100'}`}
                onClick={() => {
                  setIsYearly(false);
                  setActiveTab('monthly'); // Switch to Monthly tab
                }}
              >
                Monthly
              </button>
              <button
                className={`px-4 py-2 rounded-full transition-all duration-300 ${isYearly ? 'bg-white text-brand-color-bold' : 'text-gray-100'}`}
                onClick={() => {
                  setIsYearly(true);
                  setActiveTab('yearly'); // Switch to Yearly tab
                }}
              >
                Yearly
              </button>
            </div>
          </div>

            {/* Content based on active tab */}
            <div className="tab-content mt-6">
              {activeTab === 'monthly' && <PricingCardSection t={t} />}
              {activeTab === 'yearly' && <PricingCardSectionYearly t={t} />}
            </div>
          </div>
        </div>

        <Addons />
      </section>

      <FooterMenu t={t} />
    </>
  );
}

export default PricingScreen;
