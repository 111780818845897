import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { AiOutlineDelete } from 'react-icons/ai';
import { BsExclamationCircle, BsX } from 'react-icons/bs';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton'; // Import the Skeleton component
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { apiUrl } from '../Variables';
import Layout from '../components/Layout';
import AdminMessageBody from '../components/chatlogs/AdminMessageBody';
import { useAppContext } from '../context/AppContext';

const imageNotFound =
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtWkiWDS6J0sln0jthemckUqQxaA6dmbP9WFM0EtS7iOHmoDy1MsVonWIPyPOPYfZb3kc&usqp=CAU';

function ChatLogsScreen({ toggleLoggedInState }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [showChat, setShowChat] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [createGroupToggle, setCreateGroupToggle] = useState(false);

  const [loading, setLoading] = useState(false);
  const [CSVLoading, setCSVLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [chatBot, setChatBot] = useState(null);
  const [chatDetailsId, setChatDetailsId] = useState(null);
  const [chatUniqueId, setChatUniqueId] = useState(null);
  const [sChatbotId, setSChatbotId] = useState('');
  const [selectToggle, setSelectToggle] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterShow, setFilterShow] = useState(false);
  const [filterDate, setFilterDate] = useState({
    startDate: '',
    endDate: '',
    // startDate: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
    // endDate: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]
  });
  console.log(filterDate);
  const [searchInput, setSearchInput] = useState('');
  const [filteredChats, setFilteredChats] = useState([]);

  const location = useLocation();
  const pathname = location.pathname;

  const { sidebarToggle } = useAppContext();

  const [resetData, setResetData] = useState(false);
  const toggleReset = () => {
    setResetData(!resetData);
  };
  const [selectedBot, setSelectedBot] = useState({ value: {id: null}, label: 'All' });
  const [chatbots, setChatbots] = useState(null);
  // const [ids, setIds] = useState(searchParams.get('ids'));
  // const [botId, setBotId] = useState(searchParams.get('botId'));
  // const [searchParams, setSearchParams] = useSearchParams();

  console.log('pathname', sidebarToggle);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  // create group model
  const handleCreateGroup = () => {
    setCreateGroupToggle(!createGroupToggle);
  };

  // fetch group data []
  useEffect(() => {
    const fetchGroup = async () => {
      try {
        // Retrieve userInfo from localStorage
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (!userInfo || !userInfo.token) {
          console.error('User token not found.');
          return;
        }

        // Add headers to include JWT token
        const headers = {
          Authorization: `Bearer ${userInfo.token}`,
        };

        setLoading(true);

        const res = await axios.get(`${apiUrl}/chatbot`, { headers });
        setChatbots(res.data);

        const { data } = await axios.get(
          `${apiUrl}/chats?bot_id=${selectedBot?.value?.id}`,
          { headers }
        );
        setChats(data);

        console.log(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Chats fetch error:', error);
      }
    };
    fetchGroup();
  }, [resetData, selectedBot]);

  useEffect(() => {
    // Retrieve userInfo from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    if (sChatbotId) {
      axios.get(`${apiUrl}/botConfig/${sChatbotId}`, { headers }).then(res => {
        console.log(res)
        setChatBot(res.data[0]?.configData || {})
      }).catch(err => {
        console.log(err)
      })
    }
  }, [sChatbotId]);

  const getSenderIdHandler = (id, senderId, chatbotId) => {
    setChatDetailsId(senderId);
    setSChatbotId(chatbotId);
    setChatUniqueId(id);
  };

  // Function to handle search input changes
  const handleSearchInputChange = (e) => {
    e.preventDefault();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    // Filter chats based on the search term
    setLoading(true);
    axios
      .post(
        `${apiUrl}/chats/search`,
        { keyword: searchInput.toLowerCase() },
        { headers }
      )
      .then((res) => {
        console.log(res);
        setFilteredChats(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    // const filtered = chats.filter((item) => item.senderId.toLowerCase().includes(searchInput.toLowerCase()));
  };

  // Function to handle delete chat
  const handleDelete = (chatData) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    // Filter chats based on the search term
    setLoading(true);
    axios
      .delete(
        `${apiUrl}/chats/${chatData.chatbotId}/${chatData.senderId}/${chatData._id}`,
        { headers }
      )
      .then((res) => {
        console.log(res);
        toggleReset();
        toast.success('Successfully deleted.');
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    // const filtered = chats.filter((item) => item.senderId.toLowerCase().includes(searchInput.toLowerCase()));
  };

  const handleItemSelection = (item) => {
    if (filterShow) {
      setFilterShow(false);
    }
    if (selectedItems.includes(item._id)) {
      // Deselect the item
      setSelectedItems([...selectedItems.filter((i) => i !== item._id)]);
    } else {
      // Select the item
      setSelectedItems([...selectedItems, item._id]);
    }
  };

  // const handleExport = async (chatId) => {
  //   setLoading(true)
  //   window.location.href = `${apiUrl}/chats/exportChatlogCSV/${selectedItems.toString()}?startdate=${filterDate.startDate}&enddate=${filterDate.endDate}`
  //   setLoading(false)
  // }

  const handleExport = async () => {
    try {
      setCSVLoading(true);

      // Use Fetch API to get the file content
      const response = await fetch(
        `${apiUrl}/chats/exportChatlogCSV/${selectedItems.toString()}?startdate=${
          filterDate.startDate
        }&enddate=${filterDate.endDate}`
      );

      // Check if the request was successful
      if (response.ok) {
        // Get the blob (binary data) from the response
        const blob = await response.blob();

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Chatlog_summary.csv';

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger a click on the link to initiate the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
      } else {
        console.error(
          'Failed to fetch file:',
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setCSVLoading(false);
    }
  };

  console.log('filterShow', filterDate);

  console.log('searchInput', searchInput);

  const botOptions =
    chatbots?.map((item, index) => {
      return {
        value: {
          id: item?._id,
          configData: item?.configData,
        },
        label: item.name,
      };
    }) || [];

  const onClose = (e) => {
    console.log(e, 'I was closed.');
  };

  return (
    <>
      <Helmet>
        <title>Chatlogs | ACEVA Dashboard</title>
      </Helmet>
      <Layout toggleLoggedInState={toggleLoggedInState}>
        {/* chat body */}
        <div
          className={
            fullScreen ? 'chat-body-wrapper active' : 'chat-body-wrapper'
          }
        >
          {/* sidebar */}
          <div className="chat-body-sidebar">
            <div className="tab-content-wrapper">
              <div className="chatbox-sidebar-wrap">
                {/* AllChatbot Lists */}
                <div className="all-bot-list">
          {
            selectedBot?.label === 'All' ? (
            <div className="list-bot-logo no-bot">
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
            </div>
            ) : selectedBot?.value.configData?.logo ? (
              <div className="list-bot-logo">
                <img src={selectedBot?.value.configData?.logo} alt="bot icon" />
              </div>
              ) : (
              <div className="list-bot-logo no-bot">
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
              </div>
              )
          }
          <Select
            className='bot-dropdown'
            onChange={selected => setSelectedBot(selected)}
            value={selectedBot}
            options={[{ value: null, label: 'All' }, ...botOptions]}
            placeholder="Select a bot..."
          />
        </div>
                <div className="chat-title chat-title-big title-and-filter-result-wrap">
                  <h2>Chatlogs</h2>
                  <p className='custom-date-filter-result'>
                      {filterDate.startDate ? `Start date: ${filterDate.startDate}` : ""} {filterDate.endDate ? `- End date: ${filterDate.endDate}` : ''}
                      </p>
                  
                  {/* <MdImportExport title='export' onClick={handleExport} className="add-new-group-icon" /> */}
                </div>
                {/* search */}
                <div className="search-box-wrap">
                  <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => {
                      if (
                        selectedItems.length ===
                        (searchInput ||
                        (filterDate.startDate && filterDate.endDate)
                          ? filteredChats
                          : chats
                        ).length
                      ) {
                        setSelectedItems([]);
                      } else {
                        setSelectedItems(
                          (searchInput ||
                          (filterDate.startDate && filterDate.endDate)
                            ? filteredChats
                            : chats
                          ).map((item) => item._id)
                        );
                      }
                    }}
                  >
                    {/* select all option */}
                    {/* if search result is not found the hide select all box */}

                    <div className="select-all-wrap">
                      {selectedItems.length === chats.length ? (
                        <MdCheckBox className="selected-icon select-all-icon" />
                      ) : (
                        <MdCheckBoxOutlineBlank className="select-icon" />
                      )}
                      <p>All</p>
                    </div>
                  </div>

                  {/* search input */}
                  <div className="chat-body-search">
                    <form onSubmit={handleSearchInputChange}>
                      <input
                        placeholder="Search chat"
                        className="chat-search-input"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                      {/* <FiSearch className="selected-icon chatlog-search-icon" /> */}

                      {/* Conditionally render the cancel icon */}
                      {searchInput && (
                        <>
                          {!loading && (
                            <BsX
                              className="cancel-icon"
                              onClick={() => {
                                // Clear the search input and reset filtered chats when cancel is clicked
                                setSearchInput('');
                                setFilteredChats([]);
                              }}
                            />
                          )}

                          {/* <p className='search-btn' onClick={() => handleSearchInputChange()}>{loading ? 'Searching...' : 'Search'}</p> */}
                          <button className="search-btn" type="submit">
                            {loading ? 'Searching...' : 'Search'}
                          </button>
                        </>
                      )}
                    </form>
                  </div>
                </div>

                {/* filter options */}
                {/* if selected then show export and filter options */}
                <div className="chat-body-search chat-filter-wrap">
                  <div className="chat-filter-opt">
                    {selectedItems.length > 0 && (
                      <div>
                        {CSVLoading ? (
                          <p className="selected-icon export-btn export-chat-btn">
                            Exporting...
                          </p>
                        ) : (
                          <p
                            className="selected-icon export-btn export-chat-btn"
                            onClick={() => handleExport(selectedItems)}
                          >
                            Export Chat
                          </p>
                        )}
                      </div>
                    )}
                    {/* export button */}
                    {/* date filter */}
                    <div className="chat-date-filter">
                      <p
                        className="selected-icon export-btn"
                        onClick={() => setFilterShow(!filterShow)}
                      >
                        Filter
                      </p>

                      {/* filter model */}
                      <div
                        className={
                          filterShow
                            ? 'date-filter-model-wrapper active'
                            : 'date-filter-model-wrapper'
                        }
                      >
                        <div className="date-filter-model">
                          <BsX
                            className="cancel-icon chat-model-cancel-icon"
                            onClick={() => setFilterShow(!filterShow)}
                          />
                          <div className="analytics-choose-amount-filed choose-item">
                            <label htmlFor="">Start date</label>
                            <input
                              type="date"
                              value={filterDate.startDate}
                              onChange={(e) =>
                                setFilterDate({
                                  ...filterDate,
                                  startDate: e.target.value,
                                })
                              }
                            />
                          </div>

                          {/* end date filter */}
                          <div className="analytics-choose-amount-filed choose-item">
                            <label htmlFor="">End date</label>
                            <input
                              type="date"
                              value={filterDate.endDate}
                              onChange={(e) =>
                                setFilterDate({
                                  ...filterDate,
                                  endDate: e.target.value,
                                })
                              }
                            />
                          </div>
                          <button
                            type="button"
                            onClick={async () => {
                              setFilteredChats(
                                chats.filter((item) => {
                                  console.log(
                                    item.updatedAt,
                                    filterDate.startDate,
                                    filterDate.endDate
                                  );
                                  return (
                                    new Date(item.updatedAt) >=
                                      new Date(filterDate.startDate) &&
                                    new Date(item.updatedAt) <=
                                      new Date(filterDate.endDate)
                                  );
                                })
                              );
                              setFilterShow(false);
                            }}
                            className="form-button"
                            style={{ marginTop: '10px' }}
                          >
                            {' '}
                            <span>Filter</span>{' '}
                          </button>
                        </div>
                      </div>
                        {/* filter model end */}
                    </div>
                    <p
                      className="selected-icon export-btn"
                      onClick={() => setFilterDate({
                        startDate: "",
                        endDate: ""
                      })}
                    >
                      Reset Filter
                    </p>
                  </div>
                  
                </div>

                  
                {/* All Group list */}
                <div className="recent-chat">
                  {/* Show loading skeletons while chats are loading */}
                  {loading ? (
                    Array.from({ length: 5 }).map((_, index) => (
                      <div className="recent-chat-item" key={index}>
                        <div className="recent-chat-item-inner skeleton">
                          <div className="recent-chat-item-content skeleton">
                            <div className="recent-chatbox-content">
                              <h2>
                                <Skeleton
                                  style={{ width: '100%' }}
                                  height={20}
                                />{' '}
                                {/* Placeholder for senderId */}
                              </h2>
                              <div className="recent-chatbox-user-chat">
                                <span>
                                  <Skeleton height={10} width={200} count={1} />{' '}
                                  {/* Placeholder for user chats */}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (searchInput ||
                    (filterDate.startDate && filterDate.endDate)
                      ? filteredChats
                      : chats
                    )?.length === 0 ? (
                    <div className="chat-not-found">
                      <BsExclamationCircle />
                      <span>Sorry chat not found!</span>
                    </div>
                  ) : (
                    // list of chats
                    (searchInput || (filterDate.startDate && filterDate.endDate)
                      ? filteredChats
                      : chats
                    )?.map((item, index) => (
                      <li
                        onClick={() =>
                          getSenderIdHandler(
                            item?._id,
                            item?.senderId,
                            item?.chatbotId
                          )
                        }
                        className={
                          item?._id === chatUniqueId
                            ? 'recent-chat-item active'
                            : 'recent-chat-item'
                        }
                        key={index}
                      >
                        <div className="recent-chat-item-inner">
                          <div className="recent-chat-item-content">
                            {/* thumb */}
                            {/* <div className="featured-chat-thumb">
                              <img
                                src={logo}
                                alt="chat one"
                              />
                            </div> */}
                            <div onClick={() => handleItemSelection(item)}>
                              {selectedItems.includes(item._id) ? (
                                <MdCheckBox className="selected-icon select-icon" />
                              ) : (
                                <MdCheckBoxOutlineBlank className="select-icon" />
                              )}
                            </div>

                            {/* content */}
                            <div className="recent-chatbox-content">
                              <h2>{item?.senderId}</h2>
                              <div className="recent-chatbox-user-chat">
                                <div className="chat-list-sub-content">
                                  {/* last message */}
                                  <span>
                                    {item?.chats[0]?.user?.length > 50
                                      ? `${item?.chats[0]?.user?.substring(
                                          0,
                                          50
                                        )}...`
                                      : item?.chats[0]?.user}
                                  </span>
                                  <span className="dot"></span>
                                  {/* chat date */}
                                  <span className="chat-list-date-badge">
                                    {moment(item?.updatedAt).fromNow()}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="channel-right">
                            <AiOutlineDelete
                              onClick={() => handleDelete(item)}
                            />
                          </div>
                        </div>
                      </li>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* message body */}
          <div className="chat-message-body chatbox-message-body active">
            <AdminMessageBody
              loading={loading}
              setLoading={setLoading}
              showChat={showChat}
              setShowChat={setShowChat}
              chats={chats?.find((i) => i.senderId === chatDetailsId)?.chats}
              bot={chatBot}
            />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default ChatLogsScreen;
