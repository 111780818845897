import React from 'react'
import { Link } from 'react-router-dom'
import { useLanguage } from '../../context/LanguageContext'
import translations from '../../context/Translations'
import { Facebook, Twitter, Linkedin, Mail } from 'lucide-react'
import logo from '../../images/aceva.png'

export default function FooterV2({ bg = "transparent" }) {
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <footer className={`py-8 pt-9 ${bg === "gray" ? "bg-gray-100" : "bg-transparent"}`}>
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="flex flex-col items-center md:items-start">
            <Link to="/" className="mb-4">
              <img src={logo} alt="ACEVA" className="h-12 w-auto" />
            </Link>
            <p className="text-sm text-gray-600 text-center md:text-left">
              Revolutionizing data management with AI-powered solutions.
            </p>
          </div>
          <div className="col-span-2">
            <nav className="grid grid-cols-2 gap-4">
              {[
                { to: "/pricing", label: t?.footer?.menu?.pricing },
                { to: "/contact-us", label: t?.footer?.menu?.contactUs },
                { to: "/terms-and-conditions", label: t?.footer?.menu?.termsConditions },
                { to: "/privacy-policy", label: t?.footer?.menu?.privacyPolicy },
                { to: "/blog", label: t?.footer?.menu?.blog },
              ].map((item) => (
                <Link
                  key={item.to}
                  to={item.to}
                  className="text-gray-600 hover:text-brand-color transition-colors duration-300"
                >
                  {item.label}
                </Link>
              ))}
            </nav>
          </div>
          <div className="flex flex-col items-center md:items-end">
            <h3 className="font-semibold text-lg mb-4">Connect with us</h3>
            <div className="flex space-x-4">
              {[Facebook, Twitter, Linkedin].map((Icon, index) => (
                <a
                  key={index}
                  href="#"
                  className="text-gray-400 hover:text-brand-color transition-colors duration-300"
                >
                  <Icon size={24} />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-200 flex flex-col md:flex-row justify-between items-center">
          <p className="text-sm text-gray-600 mb-2 md:mb-0">
            © {new Date().getFullYear()} ACEVA. All rights reserved.
          </p>
          <a
            href="mailto:info@wipdata.com"
            className="flex items-center text-sm text-gray-600 hover:text-brand-color transition-colors duration-300"
          >
            <Mail size={16} className="mr-2" />
            info@wipdata.com
          </a>
        </div>
      </div>
    </footer>
  )
}