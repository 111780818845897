import { Select as AntdSelect, Switch } from 'antd'
import axios from 'axios'
import { ChevronDown } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { BsFileEarmarkSpreadsheet } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import Layout from '../../components/Layout'
import LeadGenModal from '../../components/lead-generation/LeadGenModal'
import { apiUrl } from '../../Variables'

export default function LeadGenerationScreen() {
  const [modalOpen, setModalOpen] = useState(false)
  const [leadOptions, setLeadOptions] = useState([
    { id: 'name', text: 'Name', enabled: true, isDefault: true },
    { id: 'contact', text: 'Contact', enabled: true, isDefault: true },
    { id: 'email', text: 'Email', enabled: true, isDefault: true },
    { id: 'company', text: 'Company', enabled: true, isDefault: true },
    { id: 'product', text: 'Product/Service Interests', enabled: true, isDefault: true },
  ])
  const [activeTab, setActiveTab] = useState('email')
  const [emailInput, setEmailInput] = useState('test@example.com')
  const [whatsappInput, setWhatsappInput] = useState('011-11234515')
  const [timeOfDay, setTimeOfDay] = useState('8:00 AM')
  const [twiceDailyNotifications, setTwiceDailyNotifications] = useState(false)
  const [isCustomDateModalVisible, setIsCustomDateModalVisible] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState('select-date');
  const [customDateRangeText, setCustomDateRangeText] = useState('');

  const toggleOption = (id) => {
    setLeadOptions(leadOptions.map(option => 
      option.id === id ? { ...option, enabled: !option.enabled } : option
    ))
  }

  const [chatbots, setChatbots] = useState(null);
  const [selectedBot, setSelectedBot] = useState(null);

  useEffect(() => {
    const featchBots = async () => {
      // Retrieve userInfo from localStorage
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  
      // Add headers to include JWT token
      const headers = {
        Authorization: `Bearer ${userInfo.token}`,
      };
      try {
        const { data } = await axios.get(`${apiUrl}/chatbot`, { headers });
        setSelectedBot({
            value: {
              id: data[0]?._id,
              scrapIds: data[0]?.scrappedOrUpDataId?.toString(),
              configData: data[0]?.configData
            },
            label: data[0]?.name,
          });
        setChatbots(data);
      } catch (error) {
        console.log('scrap list fetching fail:', error);
        toast.error(`Error: ${error?.message || error?.response?.data}`);
        return {};
      }
    };
      // setLoading(true);
      featchBots();
    }, []);
  

  const botOptions = (chatbots || []).map((item) => ({
    value: {
      id: item?._id,
      scrapIds: item?.scrappedOrUpDataId?.toString(),
      configData: item?.configData,
    },
    label: item.name,
  }));

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  };

  const handleDateRangeChange = (value) => {
    setSelectedDateRange(value);
    if (value === 'custom-date') {
      setIsCustomDateModalVisible(true);
    } else {
      setCustomDateRangeText('');
    }
  };

  const handleCustomDateSubmit = (e) => {
    e.preventDefault();
    if (startDate && endDate) {
      setIsCustomDateModalVisible(false);
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
      setCustomDateRangeText(`${formattedStartDate} to ${formattedEndDate}`);
      setSelectedDateRange('select-date'); // Reset the select box
      // Here you can use the startDate and endDate for further processing
      console.log('Custom date range:', startDate, 'to', endDate);
    } else {
      toast.error('Please select both start and end dates');
    }
  };

  return (
    <Layout>
      {
        modalOpen && <LeadGenModal setModalOpen={setModalOpen} />
      }
      <div className="min-h-screen">
        {/* AllChatbot Lists */}
        <div className="all-bot-list flex justify-start items-center gap-3">
          {
            selectedBot?.label === 'All' ? (
            <div className="list-bot-logo no-bot">
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
            </div>
            ) : selectedBot?.value?.configData?.logo ? (
              <div className="list-bot-logo">
                <img src={selectedBot?.value?.configData?.logo} alt="bot icon" />
              </div>
              ) : (
              <div className="list-bot-logo no-bot">
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
              </div>
              )
          }
          <Select
            onChange={(selected) => {
              setSelectedBot(selected);
            }}
            value={selectedBot}
            options={[...botOptions]}
            placeholder="Select a bot..."
            className='bot-dropdown font-poppins'
          />
        </div>
        {/* Lead Generation */}
        <div className="bg-white rounded-lg shadow overflow-hidden border border-gray-100 my-5">
          <div className="p-6">
            <div className="flex justify-start items-center gap-3 mb-6">
              <h1 className="text-2xl font-semibold text-gray-800">Lead Generation</h1>
              <button
                style={{border: '1px solid lightgray', borderRadius: '4px'}}
                onClick={() => setModalOpen(true)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-800 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-color"
              >
                Examples & Tips
              </button>
            </div>

            <p className="mb-6 text-gray-600">
             <span className="font-semibold">📈 Our Lead Generation feature</span> allows you to seamlessly capture valuable customer insights and contact details directly through your AI chatbot. By integrating customizable lead generation questions into the bot’s conversational flow, you can effortlessly gather information such as:
            </p>

            <div className="mb-8 bg-white border border-gray-200 rounded-lg shadow-sm">
              <div className="p-4 border-b border-gray-200">
                <h2 className="text-xl font-semibold text-gray-800">Lead Generation Options</h2>
              </div>
              <div className="p-4">
                <ul className="space-y-4">
                  {leadOptions.map((option) => (
                    <li
                      key={option.id}
                      className="flex items-center justify-between bg-gray-50 p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200"
                    >
                      <div className="flex items-center">
                        <span className="text-gray-700 font-medium text-base">{option.text}</span>
                      </div>
                      <div className="flex items-center space-x-3">
                        <Switch
                          checked={option.enabled}
                          onChange={() => toggleOption(option.id)}
                          className="bg-gray-300"
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Ex download leads */}

            <div className="mb-8 bg-white border border-gray-200 rounded-lg shadow-sm">
              <div className="p-4 border-b border-gray-200">
                <h2 className="text-xl font-semibold text-gray-800">Settings</h2>
              </div>
              <div className="p-4 space-y-6">
                <div>
                  <div className='flex justify-start gap-3 items-center mb-2'>
                    <h4 className="font-medium text-gray-700 text-base">Preferred Channel for Lead Notifications:</h4>
                    <div className="flex space-x-2">
                      {['email', 'whatsapp', 'both'].map((tab) => (
                        <button
                          key={tab}
                          onClick={() => setActiveTab(tab)}
                          className={`px-4 py-2 text-sm font-medium rounded-full ${
                            activeTab === tab
                              ? 'bg-brand-color text-white'
                              : 'bg-gray-100 text-gray-700 border border-gray-300 hover:bg-gray-50'
                          }`}
                        >
                          {tab.charAt(0).toUpperCase() + tab.slice(1)}
                        </button>
                      ))}
                    </div>
                  </div>
                  {(activeTab === 'email' || activeTab === 'both') && (
                    <div className="mb-4">
                      <label htmlFor="email" className="block text-sm font-normal text-gray-500 mb-1">
                        Email address:
                      </label>
                      <input
                        type="email"
                        id="email"
                        value={emailInput}
                        onChange={(e) => setEmailInput(e.target.value)}
                        className="mt-2 block w-full border border-gray-100 rounded-md shadow-sm py-2 px-3 focus:outline-0 bg-gray-50 sm:text-sm"
                      />
                    </div>
                  )}
                  {(activeTab === 'whatsapp' || activeTab === 'both') && (
                    <div className="mb-4">
                      <label htmlFor="whatsapp" className="block text-sm font-normal text-gray-500 mb-1">
                        WhatsApp number:
                      </label>
                      <input
                        type="tel"
                        id="whatsapp"
                        value={whatsappInput}
                        onChange={(e) => setWhatsappInput(e.target.value)}
                        className="mt-2 block w-full border border-gray-100 rounded-md shadow-sm py-2 px-3 focus:outline-0 bg-gray-50 sm:text-sm"
                      />
                    </div>
                  )}
                </div>
                <div className="flex items-center justify-start gap-3 border-t-2 border-gray-100 pt-6">
                  <h4 className="font-medium text-gray-700 text-base">Time of Day to Receive Lead Notifications:</h4>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => setTimeOfDay('8:00 AM')}
                      className={`px-4 py-2 text-sm font-medium rounded-full ${
                        timeOfDay === '8:00 AM'
                          ? 'bg-brand-color text-white rounded-full'
                          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                      }`}
                    >
                      8:00 AM
                    </button>
                    <button
                      onClick={() => setTimeOfDay('8:00 PM')}
                      className={`px-4 py-2 text-sm font-medium rounded-full ${
                        timeOfDay === '8:00 PM'
                          ? 'bg-brand-color text-white rounded-full'
                          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                      }`}
                    >
                      8:00 PM
                    </button>
                  </div>
                </div>
                <div className="flex items-center justify-start gap-3 border-t-2 border-gray-100 pt-4">
                  <span className="font-medium text-gray-700 text-base">Receive lead notifications twice daily?</span>
                  <Switch
                    checked={twiceDailyNotifications}
                    onChange={() => setTwiceDailyNotifications(!twiceDailyNotifications)}
                    className="bg-gray-300"
                  />
                </div>
              </div>
            </div>

            <div className="flex space-x-4">
              <button className="px-4 py-2 border border-transparent text-sm font-medium rounded-full text-white bg-brand-color hover:bg-brand-color-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-color">
                Save Changes
              </button>
              <button className="px-4 py-2 border border-transparent text-sm font-medium rounded-full text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Custom Date Range Modal */}
      {isCustomDateModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96 transform transition-all duration-300 ease-in-out">
            <h2 className="text-xl font-semibold mb-4">Select Custom Date Range</h2>
            <form onSubmit={handleCustomDateSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Start Date</label>
                <input
                  type="date"
                  className="w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-1 focus:ring-brand-color focus:border-brand-color"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">End Date</label>
                <input
                  type="date"
                  className="w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-1 focus:ring-brand-color focus:border-brand-color"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  min={startDate}
                />
              </div>
              <div className="flex justify-end space-x-2 mt-4">
                <button
                  type="button"
                  onClick={() => setIsCustomDateModalVisible(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-brand-color rounded-md hover:bg-brand-color-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-color"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Layout>
  )
}