import { Mail, MessageCircle, Pencil, ShoppingBag, Slack, Twitter } from 'lucide-react';
import React from 'react';

const appIntegrations = [
  { icon: Mail, name: 'Gmail', descriptionKey: 'gmailDescription' },
  { icon: Slack, name: 'Slack', descriptionKey: 'slackDescription' },
  { icon: ShoppingBag, name: 'Shopify', descriptionKey: 'shopifyDescription' },
  { icon: MessageCircle, name: 'Intercom', descriptionKey: 'intercomDescription' },
  { icon: Twitter, name: 'Twitter', descriptionKey: 'twitterDescription' },
  { icon: Pencil, name: 'Sketch', descriptionKey: 'sketchDescription' },
];

const AppIntegration = ({ Icon, name, description }) => (
  <div className="group relative p-6 bg-white rounded-xl shadow-md transition-all duration-300 hover:shadow-lg hover:-translate-y-1">
    <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-brand-color to-brand-color-bold transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left rounded-t-xl"></div>
    <Icon className="w-12 h-12 mb-4 text-brand-color" />
    <h3 className="mb-2 text-xl font-semibold">{name}</h3>
    <p className="text-sm text-brand-color-bold font-medium">{description.directIntegration}</p>
    <p className="mt-2 text-gray-600">{description.description}</p>
  </div>
);

export default function IntegrateSectionV2({ t }) {
  return (
    <section className="py-20 bg-gradient-to-b from-green-50 to-gray-50">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center justify-center space-y-4 text-center mb-9">
          <h2 className="text-base text-center mb-2 font-semibold text-brand-color tracking-wide uppercase">
            {t?.integrateSection?.header?.powerfulIntegrations || 'POWERFUL INTEGRATIONS'}
          </h2>
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-gray-800 capitalize">
            {t?.integrateSection?.header?.title || 'Integrate with Your Favorite Apps'}
          </h2>
          <p className="mb-12 text-xl text-gray-600 text-center max-w-2xl mx-auto">
            {t?.integrateSection?.header?.description || 'Enhance your workflow by connecting our platform with the tools you use every day. Seamless integration, powerful results.'}
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {appIntegrations.map((app, index) => (
            <AppIntegration
              key={index}
              Icon={app.icon}
              name={app.name}
              description={{
                description: t?.integrateSection?.appIntegrations?.[app.descriptionKey] || app.descriptionKey,
                directIntegration: t?.integrateSection?.appIntegrations?.directIntegration || 'Direct Integration',
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
