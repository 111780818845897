import React from 'react';
import { FaFacebook, FaTwitter } from "react-icons/fa";
import { Link } from 'react-router-dom';
import FooterMenu from '../../components/landing-page/FooterMenu';
import HeaderMenu from '../../components/landing-page/HeaderMenu';


function AuthorScreen() {
  return (
    <>
    <HeaderMenu />
    <section className='latest-blog-section'>
      <div className="container">
        <div className="author-wrapper">
          <div className="author-profile">
            <img src="https://calnewport.com/wp-content/uploads/2022/09/maincalbanner.jpg" alt="blog thumbnail" />
          </div>
          <div className="author-content">
            <h1>Suja Krishnan</h1>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque temporibus facere assumenda, at expedita autem aperiam rerum voluptate enim sequi perspiciatis minima reiciendis hic itaque ex dolores laborum exercitationem quibusdam?</p>
            <div className="author-social">
              <li><FaFacebook /></li>
              <li><FaTwitter /></li>
            </div>
          </div>
        </div>
        <div className="blog-title">
          <h2>Latest Posts from - Suja Krishnan</h2>
        </div>
        <div className="latest-blog-container">
          <div className="featured-blog-wrapper latest-blog-wrapper">
            <div className="featured-blog-thumb latest-blog-thumb">
              <img src="https://assets-global.website-files.com/637e5037f3ef83b76dcfc8f9/660ad364f6191a257810fd33_Top%20Facebook%20Messenger%20Chatbots.webp" alt="blog thumbnail" />
            </div>
            <div className="featured-blog-content latest-blog-content">
              <Link className='category-badge' to="#">Chatbot</Link>
                <h2>
                  <Link to="/blog/:slug">
                    Top Facebook Messenger Chatbots
                  </Link>
                </h2>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium, assumenda! Laudantium, sint. Magnam expedita eaque vitae repellendus nostrum</p>
              <div className="featured-excerpt latest-excerpt">
                <Link className='author-badge' to="#">Suja Krishnan</Link>
                <span>Date: 29/12/2024</span>
              </div>
            </div>
          </div>
          <div className="featured-blog-wrapper latest-blog-wrapper">
            <div className="featured-blog-thumb latest-blog-thumb">
              <img src="https://assets-global.website-files.com/637e5037f3ef83b76dcfc8f9/660ac79df91396507f32641e_ConversationalAIforEcommerce.webp" alt="blog thumbnail" />
            </div>
            <div className="featured-blog-content latest-blog-content">
              <Link className='category-badge' to="#">Chatbot</Link>
                <h2>
                  <Link to="/blog/:slug">
                  Enhancing Customer Experiences with Next-Generation Chatbots
                  </Link>
                </h2>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium, assumenda! Laudantium, sint. Magnam expedita eaque vitae repellendus nostrum.</p>
              <div className="featured-excerpt latest-excerpt">
                <Link className='author-badge' to="#">Suja Krishnan</Link>
                <span>Date: 29/12/2024</span>
              </div>
            </div>
          </div>
          <div className="featured-blog-wrapper latest-blog-wrapper">
            <div className="featured-blog-thumb latest-blog-thumb">
              <img src="https://assets-global.website-files.com/637e5037f3ef83b76dcfc8f9/65e0bf22ffd875d43d53d6b2_Strategies%20to%20Grow%20Your%20Business%20with%20AI-p-500.webp" alt="blog thumbnail" />
            </div>
            <div className="featured-blog-content latest-blog-content">
              <Link className='category-badge' to="#">Chatbot</Link>
                <h2>
                  <Link to="/blog/:slug">
                  Maximizing Virtual Assistants: 7 Strategies to Grow Your Business with AI
                  </Link>
                </h2>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium, assumenda! Laudantium, sint. Magnam expedita eaque vitae repellendus nostrum.</p>
              <div className="featured-excerpt latest-excerpt">
                <Link className='author-badge' to="#">Suja Krishnan</Link>
                <span>Date: 29/12/2024</span>
              </div>
            </div>
          </div>
          <div className="featured-blog-wrapper latest-blog-wrapper">
            <div className="featured-blog-thumb latest-blog-thumb">
              <img src="https://assets-global.website-files.com/637e5037f3ef83b76dcfc8f9/65ce312aec949ce0eb32f0b6_Ecommerce%20checkout%20mistakes-p-500.webp" alt="blog thumbnail" />
            </div>
            <div className="featured-blog-content latest-blog-content">
              <Link className='category-badge' to="#">Chatbot</Link>
                <h2>
                  <Link to="/blog/:slug">
                  8 Most Common Ecommerce Checkout Mistakes and Strategies to Avoid Them
                  </Link>
                </h2>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium, assumenda! Laudantium, sint. Magnam expedita eaque vitae repellendus nostrum.</p>
              <div className="featured-excerpt latest-excerpt">
                <Link className='author-badge' to="#">Suja Krishnan</Link>
                <span>Date: 29/12/2024</span>
              </div>
            </div>
          </div>
          <div className="featured-blog-wrapper latest-blog-wrapper">
            <div className="featured-blog-thumb latest-blog-thumb">
              <img src="https://assets-global.website-files.com/637e5037f3ef83b76dcfc8f9/660ad364f6191a257810fd33_Top%20Facebook%20Messenger%20Chatbots.webp" alt="blog thumbnail" />
            </div>
            <div className="featured-blog-content latest-blog-content">
              <Link className='category-badge' to="#">Chatbot</Link>
                <h2>
                  <Link to="/blog/:slug">
                    Top Facebook Messenger Chatbots
                  </Link>
                </h2>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium, assumenda! Laudantium, sint. Magnam expedita eaque vitae repellendus nostrum</p>
              <div className="featured-excerpt latest-excerpt">
                <Link className='author-badge' to="#">Suja Krishnan</Link>
                <span>Date: 29/12/2024</span>
              </div>
            </div>
          </div>
          <div className="featured-blog-wrapper latest-blog-wrapper">
            <div className="featured-blog-thumb latest-blog-thumb">
              <img src="https://assets-global.website-files.com/637e5037f3ef83b76dcfc8f9/660ac79df91396507f32641e_ConversationalAIforEcommerce.webp" alt="blog thumbnail" />
            </div>
            <div className="featured-blog-content latest-blog-content">
              <Link className='category-badge' to="#">Chatbot</Link>
                <h2>
                  <Link to="/blog/:slug">
                  Enhancing Customer Experiences with Next-Generation Chatbots
                  </Link>
                </h2>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium, assumenda! Laudantium, sint. Magnam expedita eaque vitae repellendus nostrum.</p>
              <div className="featured-excerpt latest-excerpt">
                <Link className='author-badge' to="#">Suja Krishnan</Link>
                <span>Date: 29/12/2024</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FooterMenu />
    </>

  )
}

export default AuthorScreen