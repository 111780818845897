import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { apiUrl } from '../../Variables';
import PricingCardSkeleton from '../skeleton/PricingCardSkeleton';
import { useNavigate } from 'react-router-dom';

function PricingCardSection() {

  const navigate = useNavigate()
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')))
  const [planLists, setPlanLists] = useState([]);
  const [loading, setLoading] = useState(false)
  const [featchLoading, setFetchLoading] = useState(false)
  const [pid, setId] = useState('')

  useEffect(() => {
    setFetchLoading(true)
    axios.get(`${apiUrl}/plan`).then(res => {
      console.log(res)
      setPlanLists(res.data)
      setFetchLoading(false)
    }).catch(err => {
      console.log(err)
      setFetchLoading(false)
    })
  },[])

  console.log('planLists', planLists)


  return (
    <>
      {
        featchLoading ? (
          <PricingCardSkeleton />
        ) : (
          <div className="pricing-wrapper">
          {/* item */}
            {
              planLists?.map((item, index) => (
                <div key={index} className="pricing-item">
                  <div className="pricing-content">
                    <div className="pricing-title">
                      <h3 className="pricing-name">{item?.name}</h3>
                      <p>{item?.description}</p>
                    </div>
                    <ul className='pricing-lists'>
                      {
                        item?.features?.map((feature, fIndex) => (
                          <li key={fIndex}> <div className='pricing-dot'></div>  <span>{feature}</span></li>
                        ))
                      }
                    </ul>
                  </div>
                  <div className="pricing-action">
                    <div className="pricing-price">RM <span className='price-amount-badge'>{item?.price_yearly}/year</span></div>
                    <button onClick={() => {
                      localStorage.setItem('selectedPlan', item._id)
                      navigate('/register')
                    }} className='landing-hero-action-btn'>Get Started</button> 
                  </div>
                </div>
              ))
            }
            
          </div>
        )
      }
    </>
  )
}

export default PricingCardSection