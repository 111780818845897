import React, { useState } from 'react';
import ssOne from '../../images/bg/001.png';
import ssTwo from '../../images/bg/002.png';
import ssThree from '../../images/bg/003.png';
import rightArrow from '../../images/icons/right-arrow.png';
import FullScreenImageView from '../FullScreenImageView';

function LandingStepSection({t}) {

  const [isFullScreenOpen, setFullScreenOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setFullScreenOpen(true);
  };

  const handleCloseFullScreen = () => {
    setSelectedImage('');
    setFullScreenOpen(false);
  };

  return (
    <>
      <section id='how-it-works' className="our-process-steps-section">
        {/* landing title */}
        <div className="landing-title landing-full-title">
          <h2>{t?.stepSection?.title}</h2>
          <p>{t?.stepSection?.subTitle?.light} <br /> <span className="bold">{t?.stepSection?.subTitle?.bold}</span></p>
        </div>

        {/* steps */}
        <div className="landing-container step-container">
            <div className="landing-page-steps">

              {/* landing screenshots */}
              <div className="landing-step-ss">
                {/* item */}
                <div className="landing-ss-item">
                  <div className="ss-thumb">
                    <img onClick={() => handleImageClick(ssOne)} src={ssOne} alt="afadf" />
                    <p className='step-content-title'>
                      <span className="brand-big-title">{t?.stepSection?.steps[0]?.title}</span> 
                      <span>{t?.stepSection?.steps[0]?.subTitle}</span>
                    </p>
                  </div>
                  {/* <button className='step-button'>Step 1</button> */}
                </div>
                <img className='right-arr-icon' src={rightArrow} alt="afadf" />
                {/* item */}
                <div className="landing-ss-item">
                  <div className="ss-thumb">
                    <img onClick={() => handleImageClick(ssTwo)}  src={ssTwo} alt="afadf" />
                    {/* <p><span className="design-badge">Design</span> your chatbot</p> */}
                    <p className='step-content-title'>
                      <span className="brand-big-title">{t?.stepSection?.steps[1]?.title}</span> 
                      <span>{t?.stepSection?.steps[1]?.subTitle}</span>
                    </p>
                  </div>
                  {/* <button className='step-button'>Step 2</button> */}
                </div>
                <img className='right-arr-icon' src={rightArrow} alt="afadf" />
                {/* item */}
                <div className="landing-ss-item">
                  <div className="ss-thumb">
                    <img onClick={() => handleImageClick(ssThree)} src={ssThree} alt="afadf" />
                    {/* <p><span className="publish-badge">Publish</span> your chatbot</p> */}
                    <p className='step-content-title'>
                      <span className="brand-big-title">{t?.stepSection?.steps[2]?.title}</span> 
                      <span>{t?.stepSection?.steps[2]?.subTitle}</span>
                    </p>
                  </div>
                  {/* <button className='step-button'>Step 3</button> */}
                </div>
              </div>
            </div>
          </div>

        {/* Check if full screen view is open */}
        {isFullScreenOpen && (
          <FullScreenImageView imageUrl={selectedImage} onClose={handleCloseFullScreen} />
        )}
      </section>
    </>
  )
}

export default LandingStepSection