import { Check, ChevronDown, ChevronUp, X, Zap } from 'lucide-react'
import React, { useState } from 'react'

const plans = [
  { name: 'Trial', price: { monthly: 'Free', yearly: 'Free' }, color: 'bg-gray-100', textColor: 'text-gray-800' },
  { name: 'Pro', price: { monthly: '$99', yearly: '$79' }, color: 'bg-blue-100', textColor: 'text-blue-800' },
  { name: 'Business', price: { monthly: '$399', yearly: '$319' }, color: 'bg-green-100', textColor: 'text-green-800' },
  { name: 'Enterprise', price: { monthly: 'Custom', yearly: 'Custom' }, color: 'bg-purple-100', textColor: 'text-purple-800' },
]

const features = [
  { name: 'AI responses', trial: '50', pro: '6000', business: '40000', enterprise: 'Custom' },
  { name: 'Lead Capture and follow up email', trial: '5', pro: '40', business: '400', enterprise: 'Custom' },
  { name: 'Training materials (Files and Webpages)', trial: '10', pro: '500', business: '750', enterprise: 'Custom' },
  { name: 'Character count', trial: '520,000', pro: '10,000,000', business: '20,000,000', enterprise: 'Custom' },
  { name: 'Max File size', trial: '5mb', pro: '15mb', business: '40mb', enterprise: 'Custom' },
  { name: 'AI Agents', trial: '1', pro: '3', business: '10', enterprise: 'Custom' },
  { name: 'Manage team access', trial: '0', pro: '3', business: '10', enterprise: 'Custom' },
  { name: 'Add additional person to get email copies', trial: '0', pro: '3', business: '10', enterprise: 'Custom' },
  { name: 'Charges for additional AI response', trial: '$', pro: '$0.03', business: '$0.01', enterprise: 'Custom' },
  { name: 'Customize Contact Form (Contact, Work Email)', trial: true, pro: true, business: true, enterprise: true },
  { name: 'Email Verification', trial: true, pro: true, business: true, enterprise: true },
  { name: 'Daily scraping', trial: false, pro: true, business: true, enterprise: true },
  { name: 'Export conversations', trial: false, pro: false, business: false, enterprise: true },
]

const FeatureRow = ({ feature }) => (
  <tr className="border-b border-gray-200">
    <td className="py-4 px-6 font-medium">{feature.name}</td>
    {plans.map((plan) => (
      <td key={plan.name} className="py-4 px-6 text-center">
        {typeof feature[plan.name.toLowerCase()] === 'boolean' ? (
          feature[plan.name.toLowerCase()] ? (
            <Check className="inline-block text-green-500" />
          ) : (
            <X className="inline-block text-red-500" />
          )
        ) : (
          <span className={`px-2 py-1 rounded-full text-sm ${plan.textColor} ${plan.color}`}>
            {feature[plan.name.toLowerCase()]}
          </span>
        )}
      </td>
    ))}
  </tr>
)

export default function PricingCompareSection() {
  const [isYearly, setIsYearly] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <section className="">
      <div className="container border-t !mt-[60px]">
        {/* <h2 className="text-4xl font-bold text-center mb-4 mt-10">Choose Your Perfect Plan</h2> */}
        <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-gray-800 capitalize text-center mb-4 mt-6">
          Choose Your Perfect Plan
        </h2>
        
        {/* toggle */}
        <div className="flex justify-center mb-6">
          <div className="bg-brand-color p-1 rounded-full flex items-center">
            <button
              className={`px-4 py-2 rounded-full transition-all duration-300 ${!isYearly ? 'bg-white text-brand-color-bold' : 'text-gray-100'}`}
              onClick={() => setIsYearly(false)}
            >
              Monthly
            </button>
            <button
              className={`px-4 py-2 rounded-full transition-all duration-300 ${isYearly ? 'bg-white text-brand-color-bold' : 'text-gray-100'}`}
              onClick={() => setIsYearly(true)}
            >
              Yearly
            </button>
          </div>
        </div>

        {/* header */}
        <div className="bg-white text-gray-800 rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="py-4 px-6 text-left text-xl">Features</th>
                  {plans.map((plan) => (
                    <th key={plan.name} className="py-4 px-6">
                      <div className={`${plan.color} ${plan.textColor} rounded-lg p-4 min-w-[200px] min-h-[130px]`}>
                        <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
                        <p className="text-2xl font-extrabold mb-1">
                          {isYearly ? plan.price.yearly : plan.price.monthly}
                        </p>
                        {isYearly && plan.name !== 'Trial' && plan.name !== 'Enterprise' && (
                          <p className="text-sm">per month, billed annually</p>
                        )}
                        {plan.name === 'Business' && (
                          <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-brand-color-bold text-white">
                            <Zap size={12} className="mr-1 text-white" /> Recommended
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {features.slice(0, isOpen ? features.length : 5).map((feature) => (
                  <FeatureRow key={feature.name} feature={feature} />
                ))}
              </tbody>
            </table>
          </div>

          <div className="p-6 bg-gray-100">
            <button
              className="flex items-center justify-center w-full text-gray-600 font-semibold"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? (
                <>
                  Show Less <ChevronUp className="ml-2" />
                </>
              ) : (
                <>
                  Show All Features <ChevronDown className="ml-2" />
                </>
              )}
            </button>
          </div>
        </div>

        <p className="text-sm text-gray-400 mt-8 text-center">
          Pricing is exclusive of taxes. Additional local tax may be collected depending on your region.
        </p>
      </div>
    </section>
  )
}
