import {
  Collapse
} from 'antd';
import React from 'react';

const { Panel } = Collapse;

const PerformanceItem = ({ number, title, description }) => (
  <div className="text-center">
    <h3 className="text-4xl font-bold">{number}</h3>
    <p className="mt-2 font-semibold">{title}</p>
    <p className="mt-1 text-sm text-gray-600">{description}</p>
  </div>
);

export default function Landing() {
  return (
    <>

    </>

  );
}