import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BiMessage } from "react-icons/bi";
import { FaTelegramPlane } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import { MdOutlineSell } from "react-icons/md";
import { apiUrl } from '../Variables';
import Layout from '../components/Layout';
import PricingCardSkeleton from '../components/skeleton/PricingCardSkeleton';
import { formatDateToHumanReadable } from '../utils/helper';



function BillingScreen({toggleLoggedInState}) {

  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));
  const [planLists, setPlanLists] = useState([]);
  const [loading, setLoading] = useState(false)
  const [featchLoading, setFetchLoading] = useState(false)
  const [pid, setId] = useState('')
  const [billingData, setBillingData] = useState({})
  const publishableKey = "pk_test_51NhNPOFBC3QMoaI4tHdKuLwBgW1AZRt3HQCoGLNYgfqE8RhXeWe0XzahcYG7ir4S3V2CbhJxdLW1ewtDJjykMk6800rj3kK5Bj"

  useEffect(() => {
   const fetchApis = async () => {
     // Add headers to include JWT token
     const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    setFetchLoading(true)
    await axios.get(`${apiUrl}/plan`, {headers}).then(res => {
      console.log(res)
      setPlanLists(res.data)
    }).catch(err => {
      console.log(err)
      // setFetchLoading(false)
    })
    await axios.get(`${apiUrl}/billing/addonBillingList`, {headers}).then(res => {
      console.log('addonBillingList', res)
      setBillingData(res.data)
      setFetchLoading(false)
    }).catch(err => {
      console.log('addonBillingList', err.response)
      setFetchLoading(false)
    })
   }
   fetchApis()
  },[])
  const payNow = async (pid, value) => {
    setLoading(true)
    setId(pid)
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    {/*try {
      const response = await axios({
        url: `${apiUrl}/stripe/create-checkout-session`,
        method: 'post',
        data: {
          items: [
            {
              id: pid,
              quantity: 1
            }
          ],
          token: userInfo.token
        },
        headers
      });
      console.log(response)
      setLoading(false)
      // Clear the local storage
      window.location = response.data.url
      localStorage.clear();
    } catch (error) {
      setLoading(false)
      console.log(error);
    }*/}
    try {
      const response = await axios({
        url: `${apiUrl}/stripe/subscriptionCheckout`,
        method: 'post',
        data: {
          items: [ { priceId: value, quantity: 1 } ],
          token: userInfo?.token
        },
        headers
      });
      console.log(response)
      setLoading(false)
      // Clear the local storage
      window.location = response.data.url
      // localStorage.clear();
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };

  const addonPayNow = async (addonName, price, validity, extraMsg) => {
    setLoading(true)
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    try {
      const response = await axios({
        url: `${apiUrl}/stripe/addonCheckout`,
        method: 'post',
        data: {
          items: [
            {
              id: addonName,
              quantity: 1,
              price,
              validity,
              extraMsg
            }
          ],
          token: userInfo.token
        },
        headers
      });
      console.log(response)
      setLoading(false)
      // Clear the local storage
      window.location = response.data.url
      // localStorage.clear();
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };

  const [extra, setExtra] = useState("1000")
    // Define a mapping of message credits to prices
    const prices = {
      "1000": 14.90,
      "2000": 29.80,
      "3000": 44.70,
      "4000": 59.60,
      "5000": 74.50,
    };
  
    // Function to handle the select box change
    const handleSelectChange = (e) => {
      setExtra(e.target.value);
    };
  return (
    <>
      <Layout toggleLoggedInState={toggleLoggedInState}>
        <div className="billing-wrapper">

          {/* title */}
          <div className="form-title">
            <h3>Billing & Payment</h3>
            <p>You are logged in as <strong>{userInfo.email}</strong></p>
            <div className="underline"></div>
          </div>

           <div className="addon-billing-table-wrap">
              <h3>Subscription Plan Billing Details</h3>
              <div className="billing-kpi-wrapper">
              {
                featchLoading ? <p style={{fontSize:'14px', margin:'10px 0px'}}>Fetching billing details...</p> : billingData?.subscriptionBilling?.length > 0 ? billingData?.subscriptionBilling?.map((billing, index) => (
                  <React.Fragment key={index}>
                    <div className="billing-kpi-item">
                      <FaTelegramPlane />
                      <p>Subscription Plan</p>
                      <p>{billing?.planName}</p>
                    </div>
                    <div className="billing-kpi-item">
                      <MdOutlineSell />
                      <p>Purchased Date</p>
                      <p>{formatDateToHumanReadable(billing?.createdAt)}</p>
                    </div>
                    <div className="billing-kpi-item">
                      <GoVerified />
                      <p>Valid Till</p>
                      <p>{formatDateToHumanReadable(billing?.validityTill)}</p>
                    </div>
                    <div className="billing-kpi-item">
                      <BiMessage />
                      <p>Message Balance</p>
                      <p>{billing?.restChatVolume}</p>
                    </div>
                  </React.Fragment>
                )) : 'No valid billing information found.'
              }
                
              </div>
              {
                //   featchLoading ? <p style={{fontSize:'14px', margin:'10px'}}>Fetching billing details...</p> :   <table>
                //   <thead>
                //     <tr>
                //       <th>Subscription Plan</th>
                //       <th>Purchased Date</th>
                //       <th>Valid Till</th>
                //       <th>Message Balance</th>
                //     </tr>
                //   </thead>
                //   <tbody>
                //     {
                //       billingData?.subscriptionBilling?.map(item =>  <tr>
                //         <td>{item.planName}</td>
                //         <td>{formatDateToHumanReadable(item.createdAt)}</td>
                //         <td>{formatDateToHumanReadable(item.validityTill)}</td>
                //         <td>{item.restChatVolume}</td>
                //       </tr>)
                //     }
                //   </tbody>
                // </table>
              }
            </div>
            <div className="addon-billing-table-wrap">
              <h3>Addon Billing Details</h3>

              {/* not found error message */}
              {/* <div className="not-link-found not-found">
                <div className="not-found-image">
                  <img className='error-img' src={logo} alt="error image" />
                  <span>Currently, you do not have an add-on plan.</span>
                </div>
              </div> */}
              {
                featchLoading ? <p style={{fontSize:'14px', margin:'10px 0px'}}>Fetching billing details...</p> : billingData?.addOnBilling?.length === 0 ? 'No Addon information found.' : <table>
                     <thead>
                       <tr>
                         <th>Addon</th>
                         <th>Purchased Date</th>
                         <th>Valid Till</th>
                         <th>Extra Message Balance</th>
                       </tr>
                     </thead>
                     <tbody>
                       {
                        billingData?.addOnBilling?.length === 0 ? (
                          ''
                          ) : (
                            billingData?.addOnBilling?.map(item =>  <tr>
                              <td>{item?.addOn?.name}</td>
                              <td>{formatDateToHumanReadable(item.createdAt)}</td>
                              <td>{formatDateToHumanReadable(item.validityTill)}</td>
                              <td>{item.restChatVolume || 0}</td>
                            </tr>)
                          )
                       }
                     </tbody>
                   </table>
              }
            </div>
          
          {/* billing cards */}
          {/* <div className="billing-cards">
   
            <div className="billing-card">
              <BsFillCreditCard2BackFill />
              <div className="billing-content">
                <span>Billing & Payment</span>
                <h3>01/01</h3>
              </div>
            </div>
       
            <div className="billing-card">
              <PiCopyLight />
              <div className="billing-content">
                <span>Total Pages</span>
                <h3>11/12</h3>
              </div>
            </div>
          </div> */}

          {/* add new plan */}
          {/* <div className="underline"></div> */}
          <div className="form-title dashboard-pricing-plan-title">
            <h3>Add Plan</h3>
            <p>We offer a plans to fit your needs. Whether you're just getting started or are a large enterprise, we have a plan for you. </p>
          </div>

          {
            featchLoading ? (
              <PricingCardSkeleton />
            ) : (
              <div className="pricing-wrapper">
              {/* item */}
                {
                  planLists?.filter(i => i._id !== '6511e925469414f7e4262d88')?.map((item, index) => (
                    <div key={index} className="pricing-item">
                      <div className="pricing-title">
                      <h3 className="pricing-name">{item?.name}</h3>
                      <p>{item?.description}</p>
                      </div>
                      <ul className='pricing-lists'>
                        {
                          item?.features?.map((feature, fIndex) => (
                            <li key={fIndex}> <span className='pricing-dot'></span>  {feature}</li>
                          ))
                        }
                      </ul>
                      <div className="pricing-action">
                        <div className="pricing-price">RM <span className='price-amount-badge'>{item?.price}/mo</span></div>
                        {
                          userInfo.subscription_id === item?._id ? <button className='landing-hero-action-btn btn-disabled'>Subscribed</button>  : <button onClick={() => payNow(item?._id, item?.stripePriceId)} className='landing-hero-action-btn'>
                          {(loading && pid === item?._id) ? 'Loading...' : 'Subscribe'}</button> 
                        }
                      </div>
                    </div>
                  ))
                }
                
              </div>
            )
          }
        </div>
        {/* addon */}
        <>
          <div className="landing-container addon-container">
            <div className="landing-title">
              <h1>Add-ons</h1>
            </div>

            {/* pricing list */}
            <div className="pricing-wrapper addon-wrapper">
              {/* item */}
              <div className="pricing-item addon-item">
                {/* <div className="addon-badge">Add-ons</div> */}
                <div className="addon-body">
                  <div className="pricing-name">Extra message credits</div>
                  <div className="addon-details">
                    
                    <p> 
                      
                      14.90 MYR per 1000 msg credits/month
                    </p>
                    <p>
                      I want 
                      <select onChange={handleSelectChange} className='addon-select' value={extra}>
                        <option value="1000">1000</option>
                        <option value="2000">2000</option>
                        <option value="3000">3000</option>
                        <option value="4000">4000</option>
                        <option value="5000">5000</option>
                      </select>
                      extra msg credits every month 1000
                    </p>
                  </div>
                </div>
                <div className="pricing-action">
                <div className="pricing-price">
                  RM <span className='price-amount-badge'>{prices[extra].toFixed(2)}/mo</span>
                </div>
                  <button className='landing-hero-action-btn' onClick={() => addonPayNow("Extra-message-credits", prices[extra].toFixed(2), 30, extra)}>Get Add-ons</button> 
                </div>
              </div>
              {/* item */}
              <div className="pricing-item addon-item">
                {/* <div className="addon-badge">Add-ons</div> */}
                <div className="addon-body">
                  <div className="pricing-name">{"Remove ‘Powered by ACEVA GPT’"}</div>
                  <div className="addon-details">
                    <p>Remove the ACEVA GPT branding from iframe and widget</p>
                  </div>
                </div>
                <div className="pricing-action">
                  <div className="pricing-price">RM <span className='price-amount-badge'>10/mo</span></div>
                  <button className='landing-hero-action-btn' onClick={() => addonPayNow("Remove-Powered-by-ACEVA-GPT", 10, 30)}>Get Add-ons</button> 
                </div>
              </div>
              {/* item */}
              <div className="pricing-item addon-item">
                {/* <div className="addon-badge">Add-ons</div> */}
                <div className="addon-body">
                  <div className="pricing-name">Do-It-For-You</div>
                  <div className="addon-details">
                    <p>We develop the chatbot for you</p>
                  </div>
                </div>
                <div className="pricing-action">
                  <div className="pricing-price">RM <span className='price-amount-badge'>50/mo</span></div>
                  <button className='landing-hero-action-btn' onClick={() => addonPayNow('Do-It-For-You', 50, 30)}>Get Add-ons</button> 
                </div>
              </div>
            </div>
          </div>
        </>
      </Layout>
    </>
  )
}

export default BillingScreen