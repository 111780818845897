const Translations = {
  english: {
    menu: {
      howIsItWorks: 'How it works',
      demo: 'Demo',
      pricing: 'Pricing',
      signIn: 'Sign In',
      button: 'Create Chatbot'
    },
    performanceSection: {
      startBuilding: 'Start building high performing',
      title: 'Website & grow your business fast.',
      stats: {
        performance: { title: 'Impressive Performance', description: 'Another way to grow fast' },
        retention: { title: 'Customer Retention', description: 'On your website' },
        growth: { title: 'Extra Growth Revenue', description: 'From your sales' },
      }
    },
    newHeroSectionContent: {
      title: 'AI sales agent for your website',
      benefits: {
        increaseLead: 'Increase lead generation by 3X',
        driveMeetings: 'Drive 2X more qualified meetings',
        personalizedFollowUp: 'Personalized follow-up & closure'
      },
      smallText: 'No credit card required',
      imageAlt: 'AI Sales Agent Workflow',
      badges: {
        bestSupport: 'Best Support',
        usersLoveUs: 'Users Love Us',
        easiestToUse: 'Easiest To Use',
        highPerformer: 'High Performer'
      }
    },
    heroSection: {
      title: {
        custom: 'Custom',
        chatGPT: 'ChatGPT',
        forYour: 'For Your',
        business: 'Business',
      },
      subTitle: "Answer visitor questions instantly with a chatbot trained on your site's content. Just connect your data, add it as a widget, and chat through integrations or API.",
      button: 'Create your chatbot now',
      noCardRequired: 'No credit card required!'
    },
    marqueeSection: {
      trustedBusinesses: 'Trusted by +25,000 businesses',
      logoAlt: 'Logo'
    },
    stats: {
      yearsInnovatingChatbots: 'Years innovating chatbots',
      aiSpecialists: 'AI specialists',
      chatbotsDeployed: 'Chatbots deployed',
    },
    leftSideFlexSection: {
      satisfiedCustomer: 'SATISFIED CUSTOMER = HIGHER CONVERSION',
      transformWebsite: 'Transform your website into an intelligent sales agent',
      createChatbot: 'Create New Chatbot'
    },
    integrateSection: {
      header: {
        powerfulIntegrations: 'POWERFUL INTEGRATIONS',
        title: 'Integrate with Your Favorite Apps',
        description: 'Enhance your workflow by connecting our platform with the tools you use every day. Seamless integration, powerful results.',
      },
      appIntegrations: {
        gmailDescription: 'Seamlessly integrate your email workflow with our platform.',
        slackDescription: 'Connect your team communication directly to our services.',
        shopifyDescription: 'Streamline your e-commerce operations with our Shopify integration.',
        intercomDescription: 'Enhance customer support with our Intercom integration.',
        twitterDescription: 'Manage your social media presence directly from our platform.',
        sketchDescription: 'Bring your designs to life with our Sketch integration.',
        directIntegration: 'Direct Integration',
      },
    },
    testimonialSection: {
      header: {
        whatTheyAreSaying: 'WHAT THEY\'RE SAYING',
        title: 'Hear From Our Happy Clients',
        description: 'Discover what our clients love about ACEVA GPT.',
      },
      testimonials: {
        dainalMathew: {
          position: 'Apple Business Manager',
          review: 'ACEVA GPT has transformed the way we handle customer service. It’s like having a virtual team working around the clock!',
        },
        tristanEka: {
          position: 'Sales Manager at TechPlus',
          review: 'The accuracy and speed of ACEVA GPT are remarkable. It has helped us streamline our sales processes significantly.',
        },
        johanMular: {
          position: 'Head of Operations at InnovateNow',
          review: 'ACEVA GPT’s chatbot has not only improved our response times but also enhanced customer satisfaction across the board.',
        },
        lindaBrooks: {
          position: 'Marketing Director at SkyCorp',
          review: 'We’ve seen a significant increase in conversions after integrating ACEVA GPT into our website. It’s a game-changer!',
        },
      },
    },
    stepSection: {
      title: 'Everything you need for your AI chatbot',
      subTitle: {
        light: 'Creating the chatbot is now as easy as clicking a button. With',
        bold: '3 simple-steps'
      },
      steps: [
        {
          title: 'INSERT',
          subTitle: 'the link'
        },
        {
          title: 'DESIGN',
          subTitle: 'your chatbot'
        },
        {
          title: 'PUBLISH',
          subTitle: 'your chatbot'
        },
      ]
    },
    videoSection: {
      title:'Still think that its complicated?',
      subTitle: 'Watch the tutorial below to gain better understanding of Aceva.'
    },
    chatbotSection: {
      title: 'Try Aceva out!',
      subTitle: 'The chatbot below is one of the replicas that may be featured on your page. Go on, ask away!'
    },
    ctaSection: {
      title: 'Elevate your business to the next level',
      subTitle: 'Create your own AI chatbot with no code and watch it learn from your content in real-time.',
      button: 'Create your chatbot now'
    },
    faqSection: {
      title: 'Frequently asked questions',
      description: 'Get answers to common questions about ACEVA GPT.',
      faqs: [
        {
          question: 'What Is ACEVA GPT?',
          answer: 'ACEVA GPT is an AI chatbot enabler, it trains ChatGPT on your data and lets you add a chat widget to your website. Just upload a document or add a link to your website and get a chatbot that can answer any question about their content.'
        },
        {
          question: 'What Should My Data Look Like?',
          answer: 'Currently, you can upload one or multiple files (.txt), paste text, or add your website link and the data will be uploaded automatically.'
        },
        {
          question: 'Can I Give My Chatbot Instructions?',
          answer: 'Yes, you can edit the prompt and give your chatbot a name and instructions on how to answer questions.'
        },
        {
          question: 'Does It Use GPT-3.5 Or GPT-4?',
          answer: 'By default your chatbot uses gpt-3.5-turbo.'
        },
        {
          question: 'How Can I Add My Chatbot To My Website?',
          answer: "You can embed an iframe or add a chat bubble to the bottom right of your website. To do that, create a chatbot and click 'Embed on website'. You can also use the API to communicate with your chatbot anywhere!"
        },
        {
          question: 'Does It Support Other Languages?',
          answer: "Yes, You can have your sources in any language and ask it questions in any language."
        },
        {
          question: 'Can I Share A Chatbot I Created?',
          answer: "Yes, by default the chatbot you created is private but you can share it in public."
        },
      ]
    },
    footer: {
      menu: {
        pricing: 'Pricing',
        contactUs: 'Contact Us',
        termsConditions: 'Terms & Conditions',
        privacyPolicy: 'Privacy Policy',
        blog: 'Blog',
      }
    },
    // pricing
    pricing: {
      title: 'Pricing Plans',
      subTitle: 'Get One Month Free For Yearly Subscription',
      monthlyBilling: 'Monthly Billing',
      yearlyBilling: 'Yearly Billing',
    },
    // contact us
    contactUs: {
      title: "Contact Us",
      subTitle: "Feel free to reach out to us at your convenience, and we will respond to you as promptly as we can.",
    },
    // Terms and Conditions
    termsConditions: {
      title: "Terms and Conditions",
      subTitle: "By continuing to use our platform, you agree to these terms and conditions.",
      description: {
        billingRefunds: {
          title: "Billing & Refunds",
          accessCharge: "We charge you for access to the product in monthly and yearly plans",
          noRefunds: "Due to the nature of our product, we currently do not offer refunds, either partial or in full.",
          cancelSubscription: "You can easily cancel your subscription at any time you like. We will no longer charge you anything once you cancel your subscription.",
          changePricing: "We may change our pricing, pricing policies, features, and access restrictions at any time.",
        },
        emails: {
          title: "Emails",
          content: "We may utilize your email address to reach out to you regarding your account, provide you with product updates, and inform you about various marketing initiatives. You have the option to opt out of receiving these emails whenever you wish.",
        },
        conditions: {
          title: "Conditions",
          content1: "We reserve the right to change/amend the policy at any time.",
          content2: "By continuing to use our platform, you agree to these terms and conditions.",
        },
        privacyPolicy: "Privacy Policy",
      },
    },
    // Privacy Policy
    privacyPolicy: {
      title: "Privacy Policy",
      subTitle: "By continuing to use our platform, you agree to these privacy Policy",
      description: {
        introduction: {
          title: "Introduction",
          content: "Please review this Privacy Policy ('Policy') before using our Service, including the Website, Widget, and API (as defined below), referred to collectively as the 'Service.' This Policy governs the information and data we collect, how we use it, and how we share it. Your access to and use of the Service are conditioned on your agreement with the Terms of Service ('Terms of Service'), which includes the terms of this Policy. ACEVA GPT ('Company') operates the Service. We use your data to improve the Service. By using the Service, you agree to the collection and use of information as described in this Policy. Unless otherwise defined in this Policy, the terms used here have the same meanings as in our Terms of Service."
        },
        definitions: {
          title: "Definitions",
          content: {
            API: "Refers to ACEVA GPT's application programming interface integrated with the User's software.",
            Cookies: "Small files stored on your Device.",
            Device: "Means a computer or a mobile device.",
            DataController: "Refers to ACEVA GPT as the entity responsible for your data.",
            DataProcessors: "Those who process data on behalf of ACEVA GPT.",
            DataSubject: "Any living individual who is the subject of Personal Data.",
            PersonalData: "Data about an identifiable living individual.",
            Service: "The Website, Widget, and/or API. Usage Data includes data collected automatically during your use of the Service.",
            User: "The individual using our Service.",
          }
        },
        dataController: {
          title: "Data Controller",
          content: "The controller of your Personal Data is ACEVA GPT."
        },
        informationCollectionUse: {
          title: "Information Collection and Use",
          content: "We collect various information to improve our Service."
        },
        typesOfDataCollected: {
          title: "Types of Data Collected",
          personalData: {
            title: "Personal Data",
            content: ["Email address", "First and last name", "Cookies and Usage Data"]
          },
          usageData: {
            title: "Usage Data",
            content: "Includes browser information, IP address, and other technical data."
          }
        },
        useOfData: {
          title: "Use of Data",
          content: "ACEVA GPT uses collected Personal Data for various purposes, including providing the Service, customer support, and improving it."
        },
        retentionOfData: {
          title: "Retention of Data",
          content: "We retain your Personal Data as necessary to meet legal obligations and for the purposes described in this Policy."
        },
        transferOfData: {
          title: "Transfer of Data",
          content: "Your data may be transferred to other jurisdictions but will be protected as described in this Policy."
        },
        disclosureOfData: {
          title: "Disclosure of Data",
          content: "We may share Personal Data in specific circumstances as described in this Policy."
        },
        securityOfData: {
          title: "Security of Data",
          content: "The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. We use appropriate administrative, technical, and physical safeguards to protect your Personal Data."
        },
        yourDataProtectionRights: {
          title: "Your Data Protection Rights",
          content: "In Malaysia, individuals have data protection rights under the Personal Data Protection Act 2010 (PDPA), including the rights to access, correct, withdraw consent, request data portability, object, and seek compensation for violations."
        },
        serviceProviders: {
          title: "Service Providers",
          content: "Third-party companies help us provide the Service, but they are obligated not to disclose or use the information for any other purpose."
        },
        analytics: {
          title: "Analytics",
          content: "We use third-party services like Google Analytics for analysis."
        },
        payments: {
          title: "Payments",
          content: "Third-party payment processors handle payments securely."
        },
        linksToOtherSites: {
          title: "Links to Other Sites",
          content: "We are not responsible for the privacy practices of other websites."
        },
        childrensPrivacy: {
          title: "Children's Privacy",
          content: "Our Service is not for children under 16."
        },
        limitedUseRequirements: {
          title: "Limited Use Requirements",
          content: "ACEVA's use and transfer to any other app of information received from Google APIs will adhere to the",
          content2: "including the Limited Use requirements.",
          label: "Google API Services User Data Policy",
          link: "https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
        },
        limitedUseRequirementsDetails: [
          {
            key: "limitedDataSharing",
            title: "Limited Data Sharing",
            content: "ACEVA ensures that any data shared with third-party tools, including AI models, is limited to what is necessary for the specific functionality or service being provided. We do not share personally identifiable information with third parties without explicit user consent, unless required by law or in accordance with our privacy policy."
          },
          {
            key: "optOutOptions",
            title: "Opt-Out Options",
            content: "ACEVA provides users with the option to opt-out of certain data sharing practices within the app. Users can access and adjust their privacy settings to control the sharing of their data with third-party tools. We offer clear instructions and user-friendly interfaces to facilitate this process."
          },
          {
            key: "dataProtectionMeasures",
            title: "Data Protection Measures",
            content: "ACEVA implements stringent security measures to safeguard user data against unauthorized access, disclosure, or misuse. We adhere to industry best practices and regularly review and update our security protocols to ensure compliance with data protection standards."
          },
          {
            key: "informedConsentExplanation",
            title: "Informed Consent Explanation",
            content: "Before any data sharing occurs with third-party tools, users are presented with a clear and comprehensive explanation of why their data is being shared and how it will be used. This explanation is provided in plain language, avoiding technical jargon, to ensure users fully understand the purpose and implications of data sharing."
          },
          {
            key: "optInConsentMechanism",
            title: "Opt-In Consent Mechanism",
            content: "ACEVA's app implements an opt-in consent mechanism for data sharing with third-party tools. Users are presented with a checkbox or toggle switch explicitly asking for their consent to share data with the specified third-party tool or AI model. This opt-in mechanism ensures that data sharing only occurs with the explicit approval of the user."
          },
          {
            key: "clearConsentConfirmation",
            title: "Clear Consent Confirmation",
            content: "Once users provide their consent through the opt-in mechanism, ACEVA's app displays a clear confirmation message confirming that the user has explicitly agreed to share their data with the specified third-party tool or AI model. This confirmation message serves as a final acknowledgment of the user's consent before data sharing commences."
          }
        ],
        changesToPrivacyPolicy: {
          title: "Changes to This Privacy Policy",
          content: "We may update the Policy, so please check it periodically."
        },
        contactUs: {
          title: "Contact Us",
          content: "Questions? Contact us at",
          email: "info@wipdata.com"
        },
        termsAndConditionsLink: {
          text: "Terms and Conditions",
          link: "/terms-and-conditions"
        }
      }
    },
  },
  indonesia: {
    menu: {
      howIsItWorks: 'Cara Kerja',
      demo: 'Coba Gratis!',
      pricing: 'Berlangganan',
      signIn: 'Masuk',
      button: 'Buat Chatbot'
    },   
    performanceSection: {
      startBuilding: 'Mulai membangun kinerja tinggi',
      title: 'Situs web & kembangkan bisnis Anda dengan cepat.',
      stats: {
        performance: { title: 'Kinerja yang Mengesankan', description: 'Cara lain untuk berkembang dengan cepat' },
        retention: { title: 'Retensi Pelanggan', description: 'Di situs web Anda' },
        growth: { title: 'Pendapatan Pertumbuhan Ekstra', description: 'Dari penjualan Anda' }
      }
    }, 
    newHeroSectionContent: {
      title: 'Agen penjualan AI untuk situs web Anda',
      benefits: {
        increaseLead: 'Tingkatkan prospek hingga 3X',
        driveMeetings: 'Hasilkan 2X lebih banyak pertemuan berkualitas',
        personalizedFollowUp: 'Tindak lanjut & penutupan yang dipersonalisasi'
      },
      smallText: 'Tidak perlu kartu kredit',
      imageAlt: 'Alur Kerja Agen Penjualan AI',
      badges: {
        bestSupport: 'Dukungan Terbaik',
        usersLoveUs: 'Pengguna Menyukai Kami',
        easiestToUse: 'Paling Mudah Digunakan',
        highPerformer: 'Performa Tinggi'
      }
    },
    heroSection: {
      title: {
        custom: 'Kustomisasi',
        chatGPT: 'ChatGPT',
        forYour: 'Untuk',
        business: 'Bisnis Anda',
      },
      subTitle: "Jawab pertanyaan pengguna secara instan dengan chatbot canggih dari situs anda. Cukup hubungkan melalui data anda, tambahkan sebagai widget, dan chat melalui bot kami",
      button: 'Coba Chatbot Anda Sekarang',
      noCardRequired: 'Tanpa Kartu Kredit!'
    },
    marqueeSection: {
      trustedBusinesses: 'Dipercaya oleh lebih dari 25,000 bisnis',
      logoAlt: 'Logo'
    },
    stats: {
      yearsInnovatingChatbots: 'Tahun berinovasi chatbot',
      aiSpecialists: 'Spesialis AI',
      chatbotsDeployed: 'Chatbot yang diterapkan',
      clientSatisfaction: 'Kepuasan klien',
    },
    leftSideFlexSection: {
      satisfiedCustomer: 'PELANGGAN PUAS = KONVERSI LEBIH TINGGI',
      transformWebsite: 'Ubah situs web Anda menjadi agen penjualan cerdas',
      createChatbot: 'Buat Chatbot Baru'
    },
    integrateSection: {
      header: {
        powerfulIntegrations: 'INTEGRASI YANG KUAT',
        title: 'Integrasikan dengan Aplikasi Favorit Anda',
        description: 'Tingkatkan alur kerja Anda dengan menghubungkan platform kami dengan alat yang Anda gunakan setiap hari. Integrasi mulus, hasil yang kuat.',
      },
      appIntegrations: {
        gmailDescription: 'Integrasikan alur kerja email Anda dengan mudah di platform kami.',
        slackDescription: 'Hubungkan komunikasi tim Anda langsung ke layanan kami.',
        shopifyDescription: 'Sederhanakan operasi e-commerce Anda dengan integrasi Shopify kami.',
        intercomDescription: 'Tingkatkan dukungan pelanggan dengan integrasi Intercom kami.',
        twitterDescription: 'Kelola kehadiran media sosial Anda langsung dari platform kami.',
        sketchDescription: 'Wujudkan desain Anda dengan integrasi Sketch kami.',
        directIntegration: 'Integrasi Langsung',
      },
    },
    testimonialSection: {
      header: {
        whatTheyAreSaying: 'APA KATA MEREKA',
        title: 'Dengar dari Klien Kami yang Bahagia',
        description: 'Temukan apa yang disukai klien kami tentang ACEVA GPT.',
      },
      testimonials: {
        dainalMathew: {
          position: 'Manajer Bisnis Apple',
          review: 'ACEVA GPT telah mengubah cara kami menangani layanan pelanggan. Ini seperti memiliki tim virtual yang bekerja 24/7!',
        },
        tristanEka: {
          position: 'Manajer Penjualan di TechPlus',
          review: 'Keakuratan dan kecepatan ACEVA GPT luar biasa. Ini sangat membantu kami dalam mempercepat proses penjualan.',
        },
        johanMular: {
          position: 'Kepala Operasional di InnovateNow',
          review: 'Chatbot ACEVA GPT tidak hanya meningkatkan waktu respons kami, tetapi juga meningkatkan kepuasan pelanggan secara keseluruhan.',
        },
        lindaBrooks: {
          position: 'Direktur Pemasaran di SkyCorp',
          review: 'Kami melihat peningkatan konversi yang signifikan setelah mengintegrasikan ACEVA GPT ke situs web kami. Ini benar-benar mengubah permainan!',
        },
      },
    },
    stepSection: {
      title: 'Semua yang anda butuhkan untuk chatbot AI anda',
      subTitle: {
        light: 'Membuat chatbot sekarang semudah menekan tombol. Dengan',
        bold: '3 langkah sederhana'
      },
      steps: [
        {
          title: 'SISIPKAN',
          subTitle: 'link tersebut'
        },
        {
          title: 'DESAIN',
          subTitle: 'chatbot anda'
        },
        {
          title: 'GUNAKAN',
          subTitle: 'chatbot anda'
        },
      ]
    },    
    videoSection: {
      title: 'Masih bingung gimana caranya?',
      subTitle: 'Tonton tutorial dibawah ini untuk informasi lebih lanjut tentang Aceva.'
    },
    chatbotSection: {
      title: 'Coba Aceva sekarang!',
      subTitle: 'Chatbot dibawah ini adalah uji coba gratis yang akan ditampilkan di halaman anda. Cobalah bertanya!'
    },
    ctaSection: {
      title: 'Tingkatkan bisnis anda ke level berikutnya',
      subTitle: 'Coba chatbot AI anda tanpa kode dan saksikan AI anda belajar dari konten anda secara real-time.',
      button: 'Coba Chatbot Anda Sekarang'
    },
    faqSection: {
      title: 'Pertanyaan yang sering diajukan',
      description: 'Dapatkan jawaban untuk pertanyaan umum tentang ACEVA GPT.',
      faqs: [
        {
          question: 'Apa itu ACEVA GPT?',
          answer: 'ACEVA GPT adalah fasilitator chatbot AI, dilatih dengan data anda dan memungkinkan anda menambahkan widget chat ke situs web anda. Cukup unggah dokumen atau tambahkan link ke situs web anda dan dapatkan chatbot yang dapat menjawab pertanyaan apa pun tentang konten mereka.'
        },
        {
          question: 'Seperti apa data saya seharusnya?',
          answer: 'Saat ini, anda dapat mengunggah satu atau beberapa file (.txt), menempelkan teks, atau menambahkan link situs web anda dan data akan diunggah secara otomatis.'
        },
        {
          question: 'Bisakah Saya Memberi Instruksi Kepada Chatbot Saya?',
          answer: 'Ya, anda dapat mengedit prompt dan memberikan nama serta instruksi kepada chatbot anda tentang cara menjawab pertanyaan.'
        },
        {
          question: 'Apakah Menggunakan GPT-3.5 Atau GPT-4?',
          answer: 'Secara default chatbot anda menggunakan gpt-3.5-turbo.'
        },
        {
          question: 'Bagaimana Cara Menambahkan Chatbot Saya Ke Situs Web?',
          answer: 'anda dapat menyematkan iframe atau menambahkan gelembung chat di sudut kanan bawah situs web anda. Untuk melakukan itu, buat chatbot dan klik \'Sematkan di situs web\'. anda juga dapat menggunakan API untuk berkomunikasi dengan chatbot anda di mana saja!'
        },
        {
          question: 'Apakah Mendukung Bahasa Lain?',
          answer: 'Ya, anda dapat memiliki sumber dalam bahasa apa pun dan menanyakannya dalam bahasa apa pun.'
        },
        {
          question: 'Bisakah Saya Berbagi Chatbot yang Saya Buat?',
          answer: 'Ya, secara default chatbot yang anda buat bersifat pribadi tetapi anda dapat membagikannya secara publik.'
        },
      ]
    },
    footer: {
      menu: {
        pricing: 'Harga',
        contactUs: 'Hubungi Kami',
        termsConditions: 'Syarat & Ketentuan',
        privacyPolicy: 'Kebijakan Privasi',
        privacyPolicy: 'Blog',
      }
    },
    // pricing
    pricing: {
      title: 'Paket Harga',
      subTitle: 'Dapatkan Satu Bulan Gratis untuk Berlangganan Tahunan',
      monthlyBilling: 'Pembayaran Bulanan',
      yearlyBilling: 'Pembayaran Tahunan',
    },
    // Contact Us
    contactUs: {
      title: "Hubungi Kami",
      subTitle: "Jangan ragu untuk menghubungi kami kapan pun anda mau, dan kami akan merespon secepat mungkin.",
    },
    // Terms and Conditions
    termsConditions: {
      title: "Syarat dan Ketentuan",
      subTitle: "Dengan melanjutkan penggunaan platform kami, anda menyetujui syarat dan ketentuan ini.",
      description: {
        billingRefunds: {
          title: "Penagihan & Pengembalian Dana",
          accessCharge: "Kami menarik biaya untuk akses ke produk dengan rencana bulanan dan tahunan",
          noRefunds: "Karena sifat produk kami, kami saat ini tidak menawarkan pengembalian dana, baik sebagian atau penuh.",
          cancelSubscription: "anda dapat dengan mudah membatalkan langganan anda kapan saja. Kami tidak akan lagi menarik biaya apa pun setelah anda membatalkan langganan.",
          changePricing: "Kami dapat mengubah harga, kebijakan harga, fitur, dan pembatasan akses kapan saja.",
        },
        emails: {
          title: "Email",
          content: "Kami dapat menggunakan alamat email anda untuk menghubungi anda tentang akun anda, memberikan pembaruan produk, dan memberitahu anda tentang berbagai inisiatif pemasaran. anda memiliki opsi untuk berhenti menerima email ini kapan pun anda inginkan.",
        },
        conditions: {
          title: "Ketentuan",
          content1: "Kami berhak untuk mengubah/amendemen kebijakan kapan saja.",
          content2: "Dengan melanjutkan penggunaan platform kami, anda menyetujui syarat dan ketentuan ini.",
        },
        privacyPolicy: "Kebijakan Privasi",
      },
    },
    // Privacy Policy
    privacyPolicy: {
      title: "Kebijakan Privasi",
      subTitle: "Dengan melanjutkan penggunaan platform kami, anda menyetujui kebijakan privasi ini",
      description: {
        introduction: {
          title: "Pengantar",
          content: "Mohon tinjau Kebijakan Privasi ini ('Kebijakan') sebelum menggunakan Layanan kami, termasuk Situs Web, Widget, dan API (seperti yang didefinisikan di bawah), yang secara kolektif disebut sebagai 'Layanan.' Kebijakan ini mengatur informasi dan data yang kami kumpulkan, bagaimana kami menggunakannya, dan bagaimana kami membagikannya. Akses anda terhadap dan penggunaan Layanan dikondisikan pada persetujuan anda dengan Syarat Layanan ('Syarat Layanan'), yang mencakup ketentuan dari Kebijakan ini. ACEVA GPT ('Perusahaan') mengoperasikan Layanan. Kami menggunakan data anda untuk meningkatkan Layanan. Dengan menggunakan Layanan, anda setuju dengan pengumpulan dan penggunaan informasi seperti yang dijelaskan dalam Kebijakan ini. Kecuali didefinisikan lain dalam Kebijakan ini, istilah yang digunakan di sini memiliki arti yang sama seperti dalam Syarat Layanan kami."
        },
        definitions: {
          title: "Definisi",
          content: {
            API: "Merujuk pada antarmuka pemrograman aplikasi ACEVA GPT yang terintegrasi dengan perangkat lunak Pengguna.",
            Cookies: "Berkas kecil yang disimpan pada Perangkat anda.",
            Device: "Berarti komputer atau perangkat mobile.",
            DataController: "Merujuk pada ACEVA GPT sebagai entitas yang bertanggung jawab atas data anda.",
            DataProcessors: "Mereka yang memproses data atas nama ACEVA GPT.",
            DataSubject: "Setiap individu hidup yang menjadi subjek Data Pribadi.",
            PersonalData: "Data tentang individu hidup yang dapat diidentifikasi.",
            Service: "Situs Web, Widget, dan/atau API. Data Penggunaan mencakup data yang dikumpulkan secara otomatis selama anda menggunakan Layanan.",
            User: "Individu yang menggunakan Layanan kami."
          }
        },
        dataController: {
          title: "Pengendali Data",
          content: "Pengendali Data Pribadi anda adalah ACEVA GPT."
        },
        informationCollectionUse: {
          title: "Pengumpulan dan Penggunaan Informasi",
          content: "Kami mengumpulkan berbagai informasi untuk meningkatkan Layanan kami."
        },
        typesOfDataCollected: {
          title: "Jenis Data yang Dikumpulkan",
          personalData: {
            title: "Data Pribadi",
            content: ["Alamat email", "Nama depan dan belakang", "Cookies dan Data Penggunaan"]
          },
          usageData: {
            title: "Data Penggunaan",
            content: "Termasuk informasi peramban, alamat IP, dan data teknis lainnya."
          }
        },
        useOfData: {
          title: "Penggunaan Data",
          content: "ACEVA GPT menggunakan Data Pribadi yang dikumpulkan untuk berbagai tujuan, termasuk menyediakan Layanan, dukungan pelanggan, dan meningkatkannya."
        },
        retentionOfData: {
          title: "Penyimpanan Data",
          content: "Kami menyimpan Data Pribadi anda sesuai kebutuhan untuk memenuhi kewajiban hukum dan untuk tujuan yang dijelaskan dalam Kebijakan ini."
        },
        transferOfData: {
          title: "Transfer Data",
          content: "Data anda dapat ditransfer ke yurisdiksi lain namun akan dilindungi seperti yang dijelaskan dalam Kebijakan ini."
        },
        disclosureOfData: {
          title: "Pengungkapan Data",
          content: "Kami dapat membagikan Data Pribadi dalam situasi tertentu seperti yang dijelaskan dalam Kebijakan ini."
        },
        securityOfData: {
          title: "Keamanan Data",
          content: "Keamanan data anda penting bagi kami tetapi ingat bahwa tidak ada metode transmisi melalui Internet atau metode penyimpanan elektronik yang 100% aman. Kami menggunakan perlindungan administratif, teknis, dan fisik yang sesuai untuk melindungi Data Pribadi anda."
        },
        yourDataProtectionRights: {
          title: "Hak Perlindungan Data anda",
          content: "Di Malaysia, individu memiliki hak perlindungan data di bawah Undang-Undang Perlindungan Data Pribadi 2010 (PDPA), termasuk hak untuk mengakses, memperbaiki, menarik persetujuan, meminta portabilitas data, menolak, dan mencari kompensasi untuk pelanggaran."
        },
        serviceProviders: {
          title: "Penyedia Layanan",
          content: "Perusahaan pihak ketiga membantu kami menyediakan Layanan, tetapi mereka diwajibkan untuk tidak mengungkapkan atau menggunakan informasi untuk tujuan lain."
        },
        analytics: {
          title: "Analitik",
          content: "Kami menggunakan layanan pihak ketiga seperti Google Analytics untuk analisis."
        },
        payments: {
          title: "Pembayaran",
          content: "Prosesor pembayaran pihak ketiga menangani pembayaran secara aman."
        },
        linksToOtherSites: {
          title: "Tautan ke Situs Lain",
          content: "Kami tidak bertanggung jawab atas praktik privasi dari situs web lain."
        },
        childrensPrivacy: {
          title: "Privasi Anak",
          content: "Layanan kami tidak ditujukan untuk anak di bawah 16 tahun."
        },
        limitedUseRequirements: {
          title: "Persyaratan Penggunaan Terbatas",
          content: "Penggunaan dan transfer ACEVA ke aplikasi lain dari informasi yang diterima dari Google APIs akan mengikuti",
          content2: "termasuk persyaratan Penggunaan Terbatas.",
          label: "Kebijakan Data Pengguna Layanan API Google",
          link: "https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
        },
        limitedUseRequirementsDetails: [
          {
            key: "limitedDataSharing",
            title: "Pembagian Data Terbatas",
            content: "ACEVA memastikan bahwa setiap data yang dibagi dengan alat pihak ketiga, termasuk model AI, terbatas pada apa yang diperlukan untuk fungsi atau layanan spesifik yang disediakan. Kami tidak membagikan informasi identifikasi pribadi dengan pihak ketiga tanpa persetujuan pengguna yang eksplisit, kecuali jika diwajibkan oleh hukum atau sesuai dengan kebijakan privasi kami."
          },
          {
            key: "optOutOptions",
            title: "Opsi Opt-Out",
            content: "ACEVA menyediakan pengguna dengan opsi untuk memilih keluar dari beberapa praktik berbagi data dalam aplikasi. Pengguna dapat mengakses dan menyesuaikan pengaturan privasi mereka untuk mengontrol pembagian data mereka dengan alat pihak ketiga. Kami menyediakan instruksi yang jelas dan antarmuka yang ramah pengguna untuk memfasilitasi proses ini."
          },
          {
            key: "dataProtectionMeasures",
            title: "Langkah Perlindungan Data",
            content: "ACEVA menerapkan langkah keamanan yang ketat untuk melindungi data pengguna dari akses, pengungkapan, atau penyalahgunaan yang tidak sah. Kami mengikuti praktik terbaik industri dan secara berkala meninjau dan memperbarui protokol keamanan kami untuk memastikan kepatuhan terhadap standar perlindungan data."
          },
          {
            key: "informedConsentExplanation",
            title: "Penjelasan Persetujuan yang Terinformasi",
            content: "Sebelum pembagian data terjadi dengan alat pihak ketiga, pengguna disajikan dengan penjelasan yang jelas dan komprehensif mengapa data mereka dibagi dan bagaimana akan digunakan. Penjelasan ini disediakan dalam bahasa yang sederhana, menghindari jargon teknis, untuk memastikan pengguna sepenuhnya memahami tujuan dan implikasi dari pembagian data."
          },
          {
            key: "optInConsentMechanism",
            title: "Mekanisme Persetujuan Opt-In",
            content: "Aplikasi ACEVA menerapkan mekanisme persetujuan opt-in untuk berbagi data dengan alat pihak ketiga. Pengguna disajikan dengan kotak centang atau sakelar toggle yang secara eksplisit meminta persetujuan mereka untuk berbagi data dengan alat pihak ketiga atau model AI yang ditentukan. Mekanisme opt-in ini memastikan bahwa pembagian data hanya terjadi dengan persetujuan eksplisit dari pengguna."
          },
          {
            key: "clearConsentConfirmation",
            title: "Konfirmasi Persetujuan yang Jelas",
            content: "Setelah pengguna memberikan persetujuan mereka melalui mekanisme opt-in, aplikasi ACEVA menampilkan pesan konfirmasi yang jelas yang mengonfirmasi bahwa pengguna telah secara eksplisit setuju untuk berbagi data mereka dengan alat pihak ketiga atau model AI yang ditentukan. Pesan konfirmasi ini berfungsi sebagai pengakuan akhir dari persetujuan pengguna sebelum pembagian data dimulai."
          }
        ],
        changesToPrivacyPolicy: {
          title: "Perubahan pada Kebijakan Privasi Ini",
          content: "Kami dapat memperbarui Kebijakan ini, jadi mohon untuk memeriksanya secara berkala."
        },
        contactUs: {
          title: "Hubungi Kami",
          content: "Pertanyaan? Hubungi kami di",
          email: "info@wipdata.com"
        },
        termsAndConditionsLink: {
          text: "Syarat dan Ketentuan",
          link: "/terms-and-conditions"
        }
      }
    },
  },
  malay: {
    // Extending with full translations for Malay...
    menu: {
      howIsItWorks: 'Bagaimana Ia Berfungsi',
      demo: 'Demo',
      pricing: 'Berlangganan',
      signIn: 'Log Masuk',
      button: 'Buat Chatbot'
    },
    performanceSection: {
      startBuilding: 'Mulakan membina prestasi tinggi',
      title: 'Laman web & kembangkan perniagaan anda dengan cepat.',
      stats: {
        performance: { title: 'Prestasi Mengesankan', description: 'Cara lain untuk berkembang dengan cepat' },
        retention: { title: 'Pengekalan Pelanggan', description: 'Di laman web anda' },
        growth: { title: 'Pendapatan Pertumbuhan Ekstra', description: 'Daripada jualan anda' }
      }
    },
    newHeroSectionContent: {
      title: 'Ejen jualan AI untuk laman web anda',
      benefits: {
        increaseLead: 'Tingkatkan penjanaan petunjuk sebanyak 3X',
        driveMeetings: 'Hasilkan 2X lebih banyak mesyuarat berkualiti',
        personalizedFollowUp: 'Tindak lanjut & penutupan yang dipersonalisasikan'
      },
      smallText: 'Tidak memerlukan kad kredit',
      imageAlt: 'Aliran Kerja Ejen Jualan AI',
      badges: {
        bestSupport: 'Sokongan Terbaik',
        usersLoveUs: 'Pengguna Menyukai Kami',
        easiestToUse: 'Paling Mudah Digunakan',
        highPerformer: 'Prestasi Tinggi'
      }
    },
    heroSection: {
      title: {
        custom: 'Khusus',
        chatGPT: 'ChatGPT',
        forYour: 'Untuk Perniagaan',
        business: 'anda',
      },
      subTitle: "Jawab soalan pelawat serta-merta dengan chatbot yang dilatih berdasarkan kandungan laman web anda. Sambungkan data anda, tambahkan sebagai widget, dan berbual melalui integrasi atau API.",
      button: 'Buat Chatbot anda sekarang',
      noCardRequired: 'Tiada kad kredit diperlukan!'
    },
    marqueeSection: {
      trustedBusinesses: 'Dipercayai oleh lebih 25,000 perniagaan',
      logoAlt: 'Logo'
    },
    stats: {
      yearsInnovatingChatbots: 'Tahun berinovasi chatbot',
      aiSpecialists: 'Pakar AI',
      chatbotsDeployed: 'Chatbot yang dikerahkan',
      clientSatisfaction: 'Kepuasan pelanggan',
    },
    leftSideFlexSection: {
      satisfiedCustomer: 'PELANGGAN PUAS = KADAR PENUKARAN TINGGI',
      transformWebsite: 'Tukar laman web anda menjadi ejen jualan pintar',
      createChatbot: 'Cipta Chatbot Baru'
    },
    integrateSection: {
      header: {
        powerfulIntegrations: 'INTEGRASI YANG KUAT',
        title: 'Integrasikan dengan Aplikasi Kegemaran Anda',
        description: 'Tingkatkan aliran kerja anda dengan menghubungkan platform kami dengan alatan yang anda gunakan setiap hari. Integrasi lancar, hasil yang hebat.',
      },
      appIntegrations: {
        gmailDescription: 'Integrasi aliran kerja emel anda dengan lancar menggunakan platform kami.',
        slackDescription: 'Hubungkan komunikasi pasukan anda terus ke perkhidmatan kami.',
        shopifyDescription: 'Permudahkan operasi e-dagang anda dengan integrasi Shopify kami.',
        intercomDescription: 'Tingkatkan sokongan pelanggan dengan integrasi Intercom kami.',
        twitterDescription: 'Urus kehadiran media sosial anda secara langsung dari platform kami.',
        sketchDescription: 'Bawa reka bentuk anda menjadi nyata dengan integrasi Sketch kami.',
        directIntegration: 'Integrasi Langsung',
      },
    },
    testimonialSection: {
      header: {
        whatTheyAreSaying: 'APA YANG MEREKA KATAKAN',
        title: 'Dengar Dari Pelanggan Kami yang Gembira',
        description: 'Ketahui apa yang pelanggan kami suka tentang ACEVA GPT.',
      },
      testimonials: {
        dainalMathew: {
          position: 'Pengurus Perniagaan Apple',
          review: 'ACEVA GPT telah mengubah cara kami mengurus perkhidmatan pelanggan. Ia seperti mempunyai pasukan maya yang berfungsi sepanjang masa!',
        },
        tristanEka: {
          position: 'Pengurus Jualan di TechPlus',
          review: 'Ketepatan dan kelajuan ACEVA GPT sangat luar biasa. Ia telah membantu kami mempercepatkan proses jualan dengan ketara.',
        },
        johanMular: {
          position: 'Ketua Operasi di InnovateNow',
          review: 'Chatbot ACEVA GPT bukan sahaja meningkatkan masa tindak balas kami tetapi juga meningkatkan kepuasan pelanggan secara menyeluruh.',
        },
        lindaBrooks: {
          position: 'Pengarah Pemasaran di SkyCorp',
          review: 'Kami telah melihat peningkatan ketara dalam penukaran selepas mengintegrasikan ACEVA GPT ke dalam laman web kami. Ia adalah pengubah permainan!',
        },
      },
    },
    stepSection: {
      title: 'Semua yang anda perlukan untuk chatbot AI anda',
      subTitle: {
        light: 'Mencipta chatbot kini semudah menekan butang. Dengan',
        bold: '3 langkah mudah'
      },
      steps: [
        {
          title: 'MASUKKAN',
          subTitle: 'pautan itu'
        },
        {
          title: 'REKA',
          subTitle: 'chatbot anda'
        },
        {
          title: 'TERBITKAN',
          subTitle: 'chatbot anda'
        },
      ]
    },    
    videoSection: {
      title: 'Masih fikir ia rumit?',
      subTitle: 'Tonton tutorial di bawah untuk pemahaman yang lebih baik mengenai Aceva.'
    },
    chatbotSection: {
      title: 'Cuba Aceva sekarang!',
      subTitle: 'Chatbot di bawah adalah salah satu replika yang mungkin ditampilkan di halaman anda. Sila, bertanya apa saja!'
    },
    ctaSection: {
      title: 'Tingkatkan perniagaan anda ke tahap seterusnya',
      subTitle: 'Buat Chatbot AI anda sendiri tanpa kod dan lihat ia belajar dari kandungan anda secara real-time.',
      button: 'Buat Chatbot anda sekarang'
    },
    faqSection: {
      title: 'Soalan Lazim',
      description: 'Dapatkan jawapan kepada soalan biasa tentang ACEVA GPT.',
      faqs: [
        {
          question: 'Apakah itu ACEVA GPT?',
          answer: 'ACEVA GPT adalah pemboleh chatbot AI, ia melatih ChatGPT dengan data anda dan membolehkan anda menambah widget chat ke laman web anda. Hanya muat naik dokumen atau tambah pautan ke laman web anda dan dapatkan chatbot yang boleh menjawab sebarang soalan tentang kandungannya.'
        },
        {
          question: 'Bagaimanakah rupa data saya sepatutnya?',
          answer: 'Buat masa ini, anda boleh memuat naik satu atau beberapa fail (.txt), tampal teks, atau tambah pautan laman web anda dan data akan dimuat naik secara automatik.'
        },
        {
          question: 'Bolehkah Saya Memberi Arahan Kepada Chatbot Saya?',
          answer: 'Ya, anda boleh mengedit prompt dan memberi nama serta arahan kepada chatbot anda tentang cara menjawab soalan.'
        },
        {
          question: 'Adakah Ia Menggunakan GPT-3.5 Atau GPT-4?',
          answer: 'Secara lalai chatbot anda menggunakan gpt-3.5-turbo.'
        },
        {
          question: 'Bagaimanakah Saya Boleh Menambah Chatbot Saya Ke Laman Web Saya?',
          answer: 'anda boleh menyematkan iframe atau menambah gelembung chat di sudut kanan bawah laman web anda. Untuk melakukan itu, cipta chatbot dan klik \'Sematkan pada laman web\'. anda juga boleh menggunakan API untuk berkomunikasi dengan chatbot anda di mana-mana sahaja!'
        },
        {
          question: 'Adakah Ia Menyokong Bahasa Lain?',
          answer: 'Ya, anda boleh memiliki sumber dalam bahasa apa pun dan bertanya dalam bahasa apa pun.'
        },
        {
          question: 'Bolehkah Saya Berkongsi Chatbot yang Saya Cipta?',
          answer: 'Ya, secara lalai chatbot yang anda cipta adalah peribadi tetapi anda boleh berkongsi secara awam.'
        },
      ]
    },    
    footer: {
      menu: {
        pricing: 'Berlangganan',
        contactUs: 'Hubungi Kami',
        termsConditions: 'Terma & Syarat',
        privacyPolicy: 'Dasar Privasi',
        blog: 'Dasar Privasi',
      }
    },
    // pricing
    pricing: {
      title: 'Pelanggan Berlangganan',
      subTitle: 'Dapatkan Satu Bulan Percuma untuk Langganan Tahunan',
      monthlyBilling: 'Bil Bulanan',
      yearlyBilling: 'Bil Tahunan',
    },
    // Contact Us
    contactUs: {
      title: "Hubungi Kami",
      subTitle: "Jangan ragu untuk menghubungi kami mengikut keperluan anda, dan kami akan memberi respons secepat yang mungkin.",
    },
    // Terms and Conditions
    termsConditions: {
      title: "Terma dan Syarat",
      subTitle: "Dengan terus menggunakan platform kami, anda bersetuju dengan terma dan syarat ini.",
      description: {
        billingRefunds: {
          title: "Penagihan & Pengembalian Dana",
          accessCharge: "Kami mengenakan yuran untuk akses ke produk dalam pelan bulanan dan tahunan.",
          noRefunds: "Memandangkan sifat produk kami, kami pada masa ini tidak menawarkan pengembalian dana, sama ada sebahagian atau sepenuhnya.",
          cancelSubscription: "anda boleh membatalkan langganan anda pada bila-bila masa yang anda suka. Kami tidak akan lagi mengenakan sebarang bayaran selepas anda membatalkan langganan.",
          changePricing: "Kami boleh mengubah Berlangganan, dasar Berlangganan, ciri dan had akses pada bila-bila masa.",
        },
        emails: {
          title: "Email",
          content: "Kami boleh menggunakan alamat email anda untuk menghubungi anda berkenaan akaun anda, memberikan anda kemas kini produk, dan memberitahu anda tentang pelbagai inisiatif pemasaran. anda mempunyai pilihan untuk berhenti menerima email ini pada bila-bila masa yang anda mahu.",
        },
        conditions: {
          title: "Syarat",
          content1: "Kami berhak untuk mengubah / meminda dasar pada bila-bila masa.",
          content2: "Dengan terus menggunakan platform kami, anda bersetuju dengan terma dan syarat ini.",
        },
        privacyPolicy: "Dasar Privasi",
      },
    },
    // Privacy Policy
    privacyPolicy: {
      title: "Dasar Privasi",
      subTitle: "Dengan terus menggunakan platform kami, anda bersetuju dengan dasar privasi ini",
      description: {
        introduction: {
          title: "Pengenalan",
          content: "Sila semak Dasar Privasi ini ('Dasar') sebelum menggunakan Perkhidmatan kami, termasuk Laman Web, Widget, dan API (seperti yang didefinisikan di bawah), yang dirujuk secara kolektif sebagai 'Perkhidmatan.' Dasar ini mengawal maklumat dan data yang kami kumpul, bagaimana kami menggunakannya, dan bagaimana kami berkongsi. Akses dan penggunaan anda terhadap Perkhidmatan adalah bersyarat kepada persetujuan anda dengan Terma Perkhidmatan ('Terma Perkhidmatan'), yang termasuk terma Dasar ini. ACEVA GPT ('Syarikat') mengendalikan Perkhidmatan. Kami menggunakan data anda untuk meningkatkan Perkhidmatan. Dengan menggunakan Perkhidmatan, anda bersetuju dengan pengumpulan dan penggunaan maklumat sebagaimana yang diterangkan dalam Dasar ini. Kecuali dinyatakan lain dalam Dasar ini, terma yang digunakan di sini mempunyai makna yang sama seperti dalam Terma Perkhidmatan kami."
        },
        definitions: {
          title: "Definisi",
          content: {
            API: "Merujuk kepada antara muka pengaturcaraan aplikasi ACEVA GPT yang terintegrasi dengan perisian Pengguna.",
            Cookies: "Fail kecil yang disimpan pada Peranti anda.",
            Device: "Bermakna komputer atau peranti mudah alih.",
            DataController: "Merujuk kepada ACEVA GPT sebagai entiti yang bertanggungjawab atas data anda.",
            DataProcessors: "Orang yang memproses data bagi pihak ACEVA GPT.",
            DataSubject: "Mana-mana individu hidup yang merupakan subjek Data Peribadi.",
            PersonalData: "Data mengenai individu hidup yang boleh dikenal pasti.",
            Service: "Laman Web, Widget, dan/atau API. Data Penggunaan termasuk data yang dikumpul secara automatik semasa penggunaan Perkhidmatan anda.",
            User: "Individu yang menggunakan Perkhidmatan kami."
          }
        },
        dataController: {
          title: "Pengawal Data",
          content: "Pengawal Data Peribadi anda adalah ACEVA GPT."
        },
        informationCollectionUse: {
          title: "Pengumpulan dan Penggunaan Maklumat",
          content: "Kami mengumpul pelbagai maklumat untuk meningkatkan Perkhidmatan kami."
        },
        typesOfDataCollected: {
          title: "Jenis Data yang Dikumpul",
          personalData: {
            title: "Data Peribadi",
            content: ["Alamat emel", "Nama pertama dan terakhir", "Cookies dan Data Penggunaan"]
          },
          usageData: {
            title: "Data Penggunaan",
            content: "Termasuk maklumat penyemak imbas, alamat IP, dan data teknikal lain."
          }
        },
        useOfData: {
          title: "Penggunaan Data",
          content: "ACEVA GPT menggunakan Data Peribadi yang dikumpulkan untuk pelbagai tujuan, termasuk menyediakan Perkhidmatan, sokongan pelanggan, dan memperbaikinya."
        },
        retentionOfData: {
          title: "Penyimpanan Data",
          content: "Kami mengekalkan Data Peribadi anda sebagaimana yang diperlukan untuk memenuhi kewajipan undang-undang dan untuk tujuan yang diterangkan dalam Dasar ini."
        },
        transferOfData: {
          title: "Pemindahan Data",
          content: "Data anda mungkin dipindahkan ke yurisdiksi lain tetapi akan dilindungi seperti yang diterangkan dalam Dasar ini."
        },
        disclosureOfData: {
          title: "Pendedahan Data",
          content: "Kami mungkin berkongsi Data Peribadi dalam keadaan tertentu sebagaimana yang diterangkan dalam Dasar ini."
        },
        securityOfData: {
          title: "Keselamatan Data",
          content: "Keselamatan data anda penting bagi kami tetapi ingat bahawa tiada kaedah transmisi melalui Internet atau kaedah penyimpanan elektronik adalah 100% selamat. Kami menggunakan perlindungan pentadbiran, teknikal, dan fizikal yang sesuai untuk melindungi Data Peribadi anda."
        },
        yourDataProtectionRights: {
          title: "Hak Perlindungan Data anda",
          content: "Di Malaysia, individu mempunyai hak perlindungan data di bawah Akta Perlindungan Data Peribadi 2010 (PDPA), termasuk hak untuk mengakses, membetulkan, menarik balik persetujuan, meminta portabiliti data, membantah, dan mencari pampasan untuk pelanggaran."
        },
        serviceProviders: {
          title: "Penyedia Perkhidmatan",
          content: "Syarikat pihak ketiga membantu kami menyediakan Perkhidmatan, tetapi mereka diwajibkan untuk tidak mendedahkan atau menggunakan maklumat untuk sebarang tujuan lain."
        },
        analytics: {
          title: "Analitik",
          content: "Kami menggunakan perkhidmatan pihak ketiga seperti Google Analytics untuk analisis."
        },
        payments: {
          title: "Pembayaran",
          content: "Pemproses pembayaran pihak ketiga menangani pembayaran dengan selamat."
        },
        linksToOtherSites: {
          title: "Pautan ke Laman Web Lain",
          content: "Kami tidak bertanggungjawab atas amalan privasi laman web lain."
        },
        childrensPrivacy: {
          title: "Privasi Kanak-Kanak",
          content: "Perkhidmatan kami tidak untuk kanak-kanak di bawah umur 16 tahun."
        },
        limitedUseRequirements: {
          title: "Syarat Penggunaan Terhad",
          content: "Penggunaan dan pemindahan ACEVA ke aplikasi lain dari maklumat yang diterima dari Google APIs akan mengikut",
          content2: "termasuk keperluan Penggunaan Terhad.",
          label: "Dasar Data Pengguna Perkhidmatan API Google",
          link: "https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
        },
        limitedUseRequirementsDetails: [
          {
            key: "limitedDataSharing",
            title: "Pengongsian Data Terhad",
            content: "ACEVA memastikan bahawa sebarang data yang dikongsi dengan alat pihak ketiga, termasuk model AI, adalah terhad kepada apa yang diperlukan untuk fungsi atau perkhidmatan tertentu yang disediakan. Kami tidak berkongsi maklumat pengenalan peribadi dengan pihak ketiga tanpa kebenaran pengguna yang jelas, kecuali jika dikehendaki oleh undang-undang atau selaras dengan dasar privasi kami."
          },
          {
            key: "optOutOptions",
            title: "Pilihan Opt-Out",
            content: "ACEVA menyediakan pengguna dengan pilihan untuk menolak dari beberapa amalan perkongsian data dalam aplikasi. Pengguna boleh mengakses dan menyesuaikan tetapan privasi mereka untuk mengawal perkongsian data mereka dengan alat pihak ketiga. Kami menyediakan arahan yang jelas dan antara muka yang mesra pengguna untuk memudahkan proses ini."
          },
          {
            key: "dataProtectionMeasures",
            title: "Langkah Perlindungan Data",
            content: "ACEVA melaksanakan langkah keselamatan ketat untuk melindungi data pengguna daripada akses, pendedahan, atau penyalahgunaan yang tidak dibenarkan. Kami mematuhi amalan terbaik industri dan secara berkala mengkaji semula dan mengemas kini protokol keselamatan kami untuk memastikan pematuhan dengan piawaian perlindungan data."
          },
          {
            key: "informedConsentExplanation",
            title: "Penjelasan Persetujuan Terinformasi",
            content: "Sebelum sebarang perkongsian data berlaku dengan alat pihak ketiga, pengguna disajikan dengan penjelasan yang jelas dan menyeluruh mengapa data mereka dikongsi dan bagaimana ia akan digunakan. Penjelasan ini disediakan dalam bahasa yang mudah, mengelakkan jargon teknikal, untuk memastikan pengguna memahami sepenuhnya tujuan dan implikasi perkongsian data."
          },
          {
            key: "optInConsentMechanism",
            title: "Mekanisme Persetujuan Opt-In",
            content: "Aplikasi ACEVA melaksanakan mekanisme persetujuan opt-in untuk perkongsian data dengan alat pihak ketiga. Pengguna disajikan dengan kotak semak atau suis togol yang secara eksplisit meminta persetujuan mereka untuk berkongsi data dengan alat pihak ketiga atau model AI yang ditentukan. Mekanisme opt-in ini memastikan perkongsian data hanya berlaku dengan kelulusan eksplisit pengguna."
          },
          {
            key: "clearConsentConfirmation",
            title: "Pengesahan Persetujuan Jelas",
            content: "Setelah pengguna memberikan persetujuan mereka melalui mekanisme opt-in, aplikasi ACEVA memaparkan mesej pengesahan yang jelas mengesahkan bahawa pengguna telah bersetuju secara eksplisit untuk berkongsi data mereka dengan alat pihak ketiga atau model AI yang ditentukan. Mesej pengesahan ini berfungsi sebagai pengakuan akhir persetujuan pengguna sebelum perkongsian data bermula."
          }
        ],
        changesToPrivacyPolicy: {
          title: "Perubahan kepada Dasar Privasi Ini",
          content: "Kami mungkin mengemas kini Dasar ini, jadi sila semak secara berkala."
        },
        contactUs: {
          title: "Hubungi Kami",
          content: "Pertanyaan? Hubungi kami di",
          email: "info@wipdata.com"
        },
        termsAndConditionsLink: {
          text: "Terma dan Syarat",
          link: "/terms-and-conditions"
        }
      }  
    },

  }
};

export default Translations;
