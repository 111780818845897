import { ChevronDownIcon } from 'lucide-react';
import React, { useState } from 'react';

export default function FAQSection({ t }) {
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (key) => {
    setOpenItem((prevOpenItem) => (prevOpenItem === key ? null : key));
  };

  const faqItems = t?.faqSection.faqs;

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4 md:px-6">
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <h2 className="text-4xl font-bold tracking-tighter sm:text-5xl text-black">
            {t.faqSection.title}
          </h2>
          <p className="max-w-[700px] text-gray-600 text-xl">
            {t.faqSection.description}
          </p>
        </div>
        <div className="mx-auto max-w-3xl mt-12">
          {faqItems?.map((item, index) => (
            <div key={index} className="group border-b border-gray-200">
              <div
                className="flex justify-between items-center cursor-pointer py-5"
                onClick={() => toggleItem(index)}
              >
                <span className="text-lg font-medium text-gray-900">
                  {item.question}
                </span>
                <ChevronDownIcon
                  className={`w-5 h-5 text-gray-500 transition-transform duration-300 ${
                    openItem === index ? 'rotate-180' : ''
                  }`}
                />
              </div>
              <div
                className={`overflow-hidden transition-all duration-300 ${
                  openItem === index ? 'max-h-96' : 'max-h-0'
                }`}
              >
                <p className="mt-3 mb-5 text-gray-600">{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
