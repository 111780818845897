import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AiOutlineDelete, AiOutlineFileAdd } from 'react-icons/ai'
import { FiChevronRight, FiEdit } from 'react-icons/fi'
// import {MdRestartAlt} from 'react-icons/gr'
import Layout from '../components/Layout'
import moment from 'moment'
import { toast } from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { apiUrl } from '../Variables'
import CreateUserModel from '../components/CreateUserModel'
import Header from '../components/Header'
import MiniLoading from '../components/MiniLoading'
import UpdateUserModel from '../components/UpdateUserModel'
import { useAppContext } from '../context/AppContext'

import { BsEye } from 'react-icons/bs'
import ListSidebar from '../components/steps/ListSidebar'

function LinkScreen({toggleLoggedInState}) {

  const [createUser, setCreateUser] = useState(false);
  const [editUser, setEditUser] = useState(false)
  const [exportModel, setExportModel] = useState(false)
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [result ,setResult] = useState(false)
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const [scrapData, setScrapData] = useState([])
  const [editUserInfo, setEditUserInfo] = useState({})
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [filterRoleValue, setFilterRoleValue] = useState('all')
  const [singleData, setSingleData] = useState({})
  const [sidebar, setSidebar] = useState(false);

  const {userInfo} = useAppContext(); 
  const navigate = useNavigate();

  useEffect(() => {
    if(deleteSuccess) {
      navigate('/file-list')
    }

    // Retrieve userInfo from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (!userInfo || !userInfo.token) {
      console.error('User token not found.');
      return;
    }

    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    const featchData = async () => {
      try {
        setLoading(true)
        const {data} = await axios.get(`${apiUrl}/scrap/scrappedList`, {headers})
        setScrapData(data)
        localStorage.setItem('selectedLinks', JSON.stringify(data?.map((item) => item?._id)));
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log('scrap list fetching fail:', error)
        toast.error(`Error: ${error?.message || error?.response?.data}`)
      }
    }
    featchData()
    
  },[deleteSuccess, navigate])

  // edit handler
  const editHandler = (item) => {
    setEditUser(!editUser)
    setEditUserInfo(item)
  }

  // user delete handler
  const deleteHandler = async (id) => {
    // Show the confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this item!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      // User confirmed deletion
      try {
        // Retrieve userInfo from localStorage
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (!userInfo || !userInfo.token) {
          console.error('User token not found.');
          return;
        }

        // Add headers to include JWT token
        const headers = {
          Authorization: `Bearer ${userInfo.token}`,
        };

        setDeleteSuccess(false);
        await axios.delete(`${apiUrl}/scrap/${id}`, { headers });
        toast.success('Deleted successfully!');
        setDeleteSuccess(true);
      } catch (error) {
        console.error('Error deleting:', error);
        toast.error(`Failed to delete: ${error?.message || error?.response?.data}`);
        setDeleteSuccess(false);
      }
    }
  };

  const singleDataFunc = (item) => {
    setSingleData(item);
  };
  

  console.log('scrap Data:', scrapData)

  return (
    <>
      <Layout toggleLoggedInState={toggleLoggedInState}>
        {/* {loading && <Loading />} */}
        <CreateUserModel createUser={createUser} setResult={setResult} setCreateUser={setCreateUser} />
        <UpdateUserModel updateSuccess={updateSuccess} setUpdateSuccess={setUpdateSuccess} editUserInfo={editUserInfo} setEditUserInfo={setEditUserInfo} editUser={editUser} setEditUser={setEditUser} />

        <ListSidebar
          sidebar={sidebar}
          setSidebar={setSidebar} 
          singleData={singleData}
        />

      <div className="table-container">

        {loading && <MiniLoading />}

        {/* table header */}
        <div className="table-header">
          <div className="table-title">
            <h3>All Files ({scrapData?.length})</h3>
          </div>

          <div className="table-add-new">
            <Link to='/create-chatbot-file'>
              <button className='form-button'> <AiOutlineFileAdd /> <span>Add Links</span> </button>
            </Link>
          </div>

        </div>

        {/* table wrapper */}
        <div className="table-wrapper">
          {
            scrapData?.length > 0 ? (
              <table>
                  {/* header */}
                  <tr className='header-upper'>
                    <th>name</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Last trained</th>
                    <th>Action</th>
                  </tr>
                  <tbody>
                    {
                      scrapData?.map((item, index) => (
                        <tr key={index}>
                          <td><span className="table-name">{item?.sourceURL}</span></td>

                          <td>
                            <span className='badge department-badge'>Link</span> 
                          </td>

                          <td>
                            <span className='badge admin-badge'>Active</span>
                          </td>

                          <td>
                            {moment(item?.createdAt).format('MMMM Do, YYYY')}
                          </td>
                          <td>
                            <div className="action-wrapper">
                              <Link to='#'>
                                <BsEye 
                                  onClick={() => {
                                    singleDataFunc(item)
                                    setSidebar(!sidebar)
                                  }}
                                />
                              </Link>
                              <Link to={`/edit-chatbot-file/${item?._id}`}>
                                <FiEdit className='edit-icon' />
                              </Link>

                              <div onClick={() => deleteHandler(item?._id)}>
                                <AiOutlineDelete className='delete-icon' />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                  
              </table>
            ) : (
                scrapData?.length === 0 && <div className='table-data-not-found'>No file found, Please add new file.</div>
            )
          }

        </div>
      </div>
      </Layout>
    </>
  )
}

export default LinkScreen