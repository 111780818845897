import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import translations from '../context/Translations';

function CTASection() {

  const { language } = useLanguage();
  const t = translations[language];
  
  return (
    <div className="call-to-action-wrapper">
        <div className="landing-hero-content">
          <h1>{t?.ctaSection?.title}</h1>
          <p>{t?.ctaSection?.subTitle}</p>

          <div className="landing-hero-action">
            <Link to="/pricing">
              <button className='landing-hero-action-btn'>{t?.ctaSection?.button}</button>
            </Link>
          </div>
        </div>
    </div>
  )
}

export default CTASection