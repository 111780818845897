import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { apiUrl } from '../../Variables';
import { useNavigate } from 'react-router-dom';
import MiniLoading from '../../components/MiniLoading';
import uploadIcon from '../../images/icons/upload-icon.png';
  
function TrainFiles({selectedItems, setSelectedItems}) {
  const navigate = useNavigate();
  // Retrieve userInfo from localStorage
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [uploadLoading, setUploadLoading] = useState(false);
  const [file, setFile] = useState(null);

  // const [fileName, setFileName] = useState('');

  const handleFileUpload = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];  
    let fileName = ''
    if (file) {
      fileName = file?.name
      // setFileName(file?.name);
      try {
        let data = new FormData();
        data.append('file', file);
  
        const res = await axios.post(`${apiUrl}/scrap/extractFilesData`, data);
        setSelectedItems([...selectedItems, {upFileName: fileName, contentOfUp: res.data.text}])
        localStorage.setItem('selectedLinks', JSON.stringify([...selectedItems, {upFileName: fileName, contentOfUp: res.data.text}]))
        event.target.value = null;
      } catch (error) {
        console.error('Error fetching URL:', error);
      }
    }
  };

  return (
    <>
      <div className="chatbot-create-content-wrap">
        {/* ...other JSX code */}
        <div className="dashboard-header-left embadded-title">
          <h3 className='title-label'>Upload Files</h3>
          <p>Upload your file here</p>
        </div>
        <div className="chatbot-upload-image-wrap">
          <label>
            <div className="chatbot-upload-content-wrap chatbot-upload-file">
              {/* <FiUpload className='user-update-icon' /> */}
              <img className='img-icon' src={uploadIcon} alt="upload icon" />
              <h3>Upload files</h3>
              <p>Click here for uploading file. Supported file types are .pdf, .txt, .ppt, .pptx, .docs .</p>
              <input type="file" accept=".pdf, .txt, .ppt, .pptx, .docx" onChange={handleFileUpload} id='image-file' name="profilePic" custom="true" hidden />
              {/* <input type="file" id='image-file' name="profilePic" custom="true" onChange={uploadFileHandler} hidden /> */}
            </div>
          </label>
        </div>
        {
          uploadLoading && <MiniLoading />
        }
        {/* Display extracted text if a file is uploaded */}
        {/* {text && (
          <div className="pdf-text-preview">
            <h3>Extracted Text</h3>
            <p>{text}</p>
          </div>
        )} */}
      </div>
    </>
  );
}

export default TrainFiles;
