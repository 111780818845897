'use client';

import { motion } from 'framer-motion';
import { MessageSquare, ThumbsUp, Users, Zap } from 'lucide-react';
import { useEffect, useState } from 'react';

const AnimatedCounter = ({ value, duration = 5000 }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const steps = 20;
    const increment = value / steps;
    const stepDuration = duration / steps;

    let currentStep = 0;
    const timer = setInterval(() => {
      currentStep++;
      if (currentStep === steps) {
        setCount(value);
        clearInterval(timer);
      } else {
        setCount(Math.floor(increment * currentStep));
      }
    }, stepDuration);

    return () => clearInterval(timer);
  }, [value, duration]);

  return <span>{count}</span>;
};

const StatItem = ({ icon, number, text }) => (
  <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
    {icon}
    <h3 className="mt-4 text-4xl font-bold">{number}</h3>
    <p className="mt-2 text-gray-600">{text}</p>
  </div>
);

export default function Component({ t }) {
  const stats = [
    { icon: <Zap className="w-12 h-12 mx-auto mb-4 text-brand-color" />, value: 6, label: t?.stats?.yearsInnovatingChatbots, suffix: '+' },
    { icon: <Users className="w-12 h-12 mx-auto mb-4 text-brand-color" />, value: 37, label: t?.stats?.aiSpecialists, suffix: '+' },
    { icon: <MessageSquare className="w-12 h-12 mx-auto mb-4 text-brand-color" />, value: 3274, label: t?.stats?.chatbotsDeployed },
    { icon: <ThumbsUp className="w-12 h-12 mx-auto mb-4 text-brand-color" />, value: 98, label: t?.stats?.clientSatisfaction, suffix: '%' },
  ];

  return (
    <section className="py-16 bg-gradient-to-br text-white from-indigo-50 to-purple-100">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white backdrop-filter backdrop-blur-lg rounded-lg p-6 text-center transform hover:scale-105 transition-transform duration-300"
            >
              {stat.icon}
              <div className="text-4xl font-bold mb-2 text-gray-500">
                <AnimatedCounter value={stat.value} />
                {stat.suffix}
              </div>
              <div className="text-lg text-gray-600">
                {stat.label}
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}
