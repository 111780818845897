import { DollarSign, TrendingUp, Users } from 'lucide-react';
import { useEffect, useState } from 'react';

export default function PerformanceStats({ t }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <section className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-br from-purple-50 to-indigo-100 overflow-hidden">
      <div className="container px-4 md:px-6 mx-auto">
        <div className={`text-center mb-12 transition-all duration-1000 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
          <p className="mt-2 text-xl text-brand-color font-semibold pb-3">
            {t.performanceSection.startBuilding}
          </p>
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-gray-800 capitalize">
            {t.performanceSection.title}
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-start">
          {[
            {
              icon: TrendingUp,
              stat: '1.67x',
              title: t.performanceSection.stats.performance.title,
              description: t.performanceSection.stats.performance.description
            },
            {
              icon: Users,
              stat: '29%',
              title: t.performanceSection.stats.retention.title,
              description: t.performanceSection.stats.retention.description
            },
            {
              icon: DollarSign,
              stat: '19%',
              title: t.performanceSection.stats.growth.title,
              description: t.performanceSection.stats.growth.description
            }
          ].map((item, index) => (
            <div
              key={index}
              className={`bg-white p-6 rounded-lg shadow-lg transition-all duration-1000 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
              style={{ transitionDelay: `${index * 200}ms` }}
            >
              <div className="flex items-center justify-center w-12 h-12 bg-bg-color rounded-full mb-4">
                <item.icon className="w-6 h-6 text-brand-color" />
              </div>
              <h3 className="text-4xl font-bold text-brand-color-bold mb-2">{item.stat}</h3>
              <h4 className="text-xl font-semibold text-brand-color mb-2">{item.title}</h4>
              <p className="text-gray-600">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
